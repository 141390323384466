import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// BL
// UI
import Abreviaciones from '../../../atoms/text/Abreviaciones';
import B1Text from '../../../atoms/text/B1Text';
import ClickableChip from './ClickableChip';

export default function FilterChipStaticData({
	filterLetter,
	filterLabel,
	filterVariable,
	allElements,
}) {
	const { i18n } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);

	let variableToSort = i18n.language === 'es' ? 'name' : 'name_en';
	let sortedElements = [...allElements]
		.filter(
			(element) =>
				element[variableToSort] &&
				element[variableToSort] !== 'Ninguno' &&
				element[variableToSort] !== 'None'
		)
		.sort((a, b) => a[variableToSort]?.localeCompare(b[variableToSort]));

	return (
		<div className={`flex flex-col gap-y-2`}>
			<div
				id={filterLetter}
				className='flex flex-row cursor-pointer hover:text-azul-50  max-w-max'
				onClick={() => setShowDropdown(!showDropdown)}
			>
				<B1Text design='flex flex-row gap-1'>
					{
						<Abreviaciones design='font-medium italic'>
							{filterLetter}
						</Abreviaciones>
					}
					{filterLabel}
				</B1Text>
			</div>

			{showDropdown && (
				<div className='flex flex-col gap-1 overflow-y-auto  pl-2'>
					{sortedElements.map((material) => (
						<ClickableChip
							key={material.id}
							label={
								i18n.language === 'es' ? material.label : material.label_en
							}
							valueToFilter={filterVariable}
						/>
					))}
				</div>
			)}
		</div>
	);
}
