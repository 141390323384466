import React from 'react';

import { ReactComponent as EditImage } from '../../assets/icon/Edit.svg';
import { ReactComponent as LeftArrowSmall } from '../../assets/icon/LeftArrowSmall.svg';
import H1Principal from '../../atoms/text/H1Principal';

export default function NavbarSecondaryPerfil({
	navBarTitle,
	editView,
	setEditView,
}) {
	return (
		<div className='w-full flex flex-row justify-between mt-10 '>
			<div>
				{editView && (
					<LeftArrowSmall
						className='cursor-pointer'
						onClick={() => setEditView(!editView)}
					/>
				)}
			</div>
			<H1Principal design='self-center'>{navBarTitle}</H1Principal>
			<div>
				{!editView && (
					<EditImage
						className='cursor-pointer'
						onClick={() => setEditView(!editView)}
					/>
				)}
			</div>
		</div>
	);
}
