import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// BL
import {
	getDraftElements,
	getProposedElements,
	getPublishedElements,
	setCurrentElements,
} from '../../../business_logic/redux/reducers/elementsSlice';
import { AllFiltersInElements } from '../../../business_logic/helpers/filterHelpers';

// UI
import Gallery from '../../organisms/Gallery/Gallery';
import Loading from '../../organisms/Loading/Loading';
import GeneralTemplate from '../../templates/GeneralTemplate';

export default function HomePage({
	redirect,
	status = '',
	menuVisitor = true,
	menuAdmins,
}) {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const { publishedElements, proposedElements, draftElements, elements } =
		useSelector((state) => state.elements);

	const { showFilter, filterArray, nameFilter } = useSelector(
		(state) => state.filter
	);

	const initialArray = () => {
		if (status === 'PUBLISHED') return publishedElements;
		if (status === 'SUBMITTED') return proposedElements;
		if (status === 'DRAFT') return draftElements;
	};

	const [allElements, setAllElements] = useState(initialArray());

	useEffect(() => {
		if (status === 'PUBLISHED') {
			if (!publishedElements) {
				setIsLoading(true);
				dispatch(getPublishedElements()).then((res) => {
					setAllElements(res.payload);
					setIsLoading(false);
				});
			} else {
				setAllElements(publishedElements);
				dispatch(setCurrentElements(publishedElements));
			}
		}
		if (status === 'SUBMITTED') {
			if (!proposedElements) {
				setIsLoading(true);
				dispatch(getProposedElements()).then((res) => {
					setAllElements(res.payload);
					setIsLoading(false);
				});
			} else {
				setAllElements(proposedElements);
				dispatch(setCurrentElements(proposedElements));
			}
		}
		if (status === 'DRAFT') {
			if (!draftElements) {
				setIsLoading(true);
				dispatch(getDraftElements()).then((res) => {
					setAllElements(res.payload);
					setIsLoading(false);
				});
			} else {
				setAllElements(draftElements);
				dispatch(setCurrentElements(draftElements));
			}
		}
	}, [
		status,
		draftElements,
		proposedElements,
		publishedElements,
		dispatch,
		elements,
	]);

	return (
		<GeneralTemplate
			menuVisitor={menuVisitor}
			showFilter={true}
			menuAdmins={menuAdmins}
		>
			{isLoading ? (
				<Loading />
			) : (
				<Gallery
					filteredElements={
						allElements
							? AllFiltersInElements(allElements, filterArray, nameFilter)
							: []
					}
					pageTitle='home'
					redirect={redirect}
					showFilter={showFilter}
				/>
			)}
		</GeneralTemplate>
	);
}
