import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// BL
import { setNewCollectionName } from '../../../business_logic/redux/reducers/collection';
// UI
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import ButtonSecondary from '../../atoms/button/ButtonSecondary';
import B1Text from '../../atoms/text/B1Text';
import H1Principal from '../../atoms/text/H1Principal';

export default function NewCollection() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [collectionName, setCollectionName] = useState('Sin Nombre');

	const handleChange = (event) => {
		event.preventDefault();
		setCollectionName(event.target.value);
	};

	const handlePushName = (cancel = false, e) => {
		e.preventDefault();
		if (cancel) navigate(-1);
		if (collectionName === '') {
			setCollectionName('Sin Nombre');
		}
		dispatch(setNewCollectionName(collectionName));
	};

	return (
		<div className='w-full h-screen flex justify-center items-center'>
			<div className='w-11/12 sm:w-88 h-64 rounded border flex flex-col justify-between text-center py-9 shadow-lg'>
				<H1Principal design='font-semibold'>
					{t('NuevaColecionModal.nuevaColeccion')}
				</H1Principal>
				<B1Text design='mx-auto'>
					{t('NuevaColecionModal.ingresaUnNombre')}
				</B1Text>
				<form>
					<input
						className='w-11/12  B1Text rounded bg-white border border-azul-50 active:bg-white focus:border-azul-50 px-1'
						type='text'
						onChange={handleChange}
					/>
					<div className='w-full px-4 flex flex-row justify-between'>
						<Link to='/my-collection'>
							<ButtonPrincipal
								type='Outline'
								text={t('NuevaColecionModal.cancelar')}
								action={(e) => handlePushName(true, e)}
							/>
						</Link>
						<Link to='/new-collection/edit'>
							<ButtonSecondary
								text={t('NuevaColecionModal.crearAlbum')}
								action={(e) => handlePushName(false, e)}
							/>
						</Link>
					</div>
				</form>
			</div>
		</div>
	);
}
