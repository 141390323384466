import React from 'react';
import B1Text from '../../atoms/text/B1Text';
import H1Principal from '../../atoms/text/H1Principal';

export default function InputTextArea({ title, textInfo, design = '' }) {
	return (
		<div className={`w-full flex flex-col pb-4 ${design}`}>
			<H1Principal design='pb-4'>{title}</H1Principal>
			<B1Text design='font-normal w-full'>{textInfo}</B1Text>
		</div>
	);
}
