import { useTranslation } from 'react-i18next';
// UI
import TextDeepValues from '../../molecules/Text/TextDeepValues';
import TextMultipleVal from '../../molecules/Text/TextMultipleVal';
import TextStaticValue from '../../molecules/Text/TextStaticValue';
import TextValues from '../../molecules/Text/TextValues';

export default function CategoryName({
	data = false,
	categoryData = ['negro'],
	atributesOne = false,
	atributesTwo = false,
	levelData = false,
	spaceLevelData = false,
	keyConceptsAttribute,
	OccupationAttribute,
}) {
	const { t, i18n } = useTranslation();

	console.log(data);

	return (
		<div>
			<div className='w-full flex flex-col md:hidden '>
				{data && (
					<TextValues
						label={t('Ficha.año')}
						information={data.year}
						letter={categoryData[0].año}
						labelFilter={'year'}
					/>
				)}
				{data && (
					<TextValues
						label={t('Ficha.pais')}
						information={
							i18n.language === 'es' ? data.country_name : data.country_name_en
						}
						letter={categoryData[1].pais}
						labelFilter={'country_name'}
					/>
				)}
				{data && (
					<TextValues
						label={t('Ficha.ubicacion')}
						information={
							i18n.language === 'es' ? data.location : data.location_en
						}
						letter={categoryData[2].ubicacion}
						labelFilter={'location'}
					/>
				)}
				{spaceLevelData && (
					<TextDeepValues
						label={t('Ficha.espacio')}
						information={spaceLevelData}
						labelFilter={
							i18n.language === 'es' ? 'space_relation' : 'space_relation_en'
						}
					/>
				)}
				{data && (
					<TextValues
						label={t('Ficha.tipoDeOcupacion')}
						information={
							i18n.language === 'es'
								? data.occupation_type
								: data.occupation_type_en
						}
						letter={categoryData[4].ocupacion}
						labelFilter={
							i18n.language === 'es' ? 'occupation_type' : 'occupation_type_en'
						}
					/>
				)}
				{data && (
					<TextValues
						label={t('Ficha.temporalidad')}
						information={
							i18n.language === 'es' ? data.temporality : data.temporality_en
						}
						letter={categoryData[11].temporalidad}
						labelFilter={
							i18n.language === 'es' ? 'temporality' : 'temporality_en'
						}
					/>
				)}
				{data && (
					<TextValues
						label={t('Ficha.permanencia')}
						information={
							i18n.language === 'es' ? data.permanence : data.permanence_en
						}
						letter={categoryData[5].permanencia}
						labelFilter={
							i18n.language === 'es' ? 'permanence' : 'permanence_en'
						}
					/>
				)}
				{data && (
					<TextValues
						label={t('Ficha.agentes')}
						information={i18n.language === 'es' ? data.agent : data.agent_en}
						letter={categoryData[6].agentes}
						labelFilter={i18n.language === 'es' ? 'agent' : 'agent_en'}
					/>
				)}
				{levelData && (
					<TextDeepValues
						label={t('Ficha.actividad')}
						information={levelData}
						letter={categoryData[7].actividad}
						labelFilter={
							i18n.language === 'es' ? 'categories' : 'categories_en'
						}
					/>
				)}
				{OccupationAttribute && OccupationAttribute[0] && (
					<TextStaticValue
						label={t('Ficha.objetosDeOcupacion')}
						elements={OccupationAttribute}
						labelFilter={
							i18n.language === 'es'
								? 'occupation_objects'
								: 'occupation_objects_en'
						}
					/>
				)}
				{keyConceptsAttribute && keyConceptsAttribute[0] && (
					<TextStaticValue
						label={t('Ficha.conceptosClave')}
						elements={keyConceptsAttribute}
						labelFilter={
							i18n.language === 'es' ? 'key_concepts' : 'key_concepts_en'
						}
					/>
				)}
			</div>

			<div className='hidden w-full md:flex flex-row justify-between pl-11 3xl:pl-0'>
				<div className='flex flex-col w-2/4 '>
					{data && (
						<TextValues
							label={t('Ficha.año')}
							information={data.year}
							letter={categoryData[0].año}
							labelFilter={'year'}
						/>
					)}
					{data && (
						<TextValues
							label={t('Ficha.pais')}
							information={
								i18n.language === 'es'
									? data.country_name
									: data.country_name_en
							}
							letter={categoryData[1].pais}
							labelFilter={'country_name'}
						/>
					)}
					{data && (
						<TextValues
							label={t('Ficha.ubicacion')}
							information={
								i18n.language === 'es' ? data.location : data.location_en
							}
							letter={categoryData[2].ubicacion}
							labelFilter={'location'}
						/>
					)}
					{spaceLevelData && (
						<TextDeepValues
							label={t('Ficha.espacio')}
							information={spaceLevelData}
							labelFilter={
								i18n.language === 'es' ? 'space_relation' : 'space_relation_en'
							}
						/>
					)}
					{data && (
						<TextValues
							label={t('Ficha.tipoDeOcupacion')}
							information={
								i18n.language === 'es'
									? data.occupation_type
									: data.occupation_type_en
							}
							letter={categoryData[4].ocupacion}
							labelFilter={
								i18n.language === 'es'
									? 'occupation_type'
									: 'occupation_type_en'
							}
						/>
					)}
					{data && (
						<TextValues
							label={t('Ficha.temporalidad')}
							information={
								i18n.language === 'es' ? data.temporality : data.temporality_en
							}
							letter={categoryData[11].temporalidad}
							labelFilter={
								i18n.language === 'es' ? 'temporality' : 'temporality_en'
							}
						/>
					)}
					{data && (
						<TextValues
							label={t('Ficha.permanencia')}
							information={
								i18n.language === 'es' ? data.permanence : data.permanence_en
							}
							letter={categoryData[5].permanencia}
							labelFilter={
								i18n.language === 'es' ? 'permanence' : 'permanence_en'
							}
						/>
					)}
				</div>
				<div className='flex flex-col w-2/4'>
					{data && (
						<TextValues
							label={t('Ficha.agentes')}
							information={i18n.language === 'es' ? data.agent : data.agent_en}
							letter={categoryData[6].agentes}
							labelFilter={i18n.language === 'es' ? 'agent' : 'agent_en'}
						/>
					)}
					{levelData && (
						<TextDeepValues
							label={t('Ficha.actividad')}
							information={levelData}
							letter={categoryData[7].actividad}
							labelFilter={
								i18n.language === 'es' ? 'categories' : 'categories_en'
							}
						/>
					)}
					{atributesOne && atributesTwo && (
						<TextMultipleVal
							label={t('Ficha.atributosFisicos')}
							colors={atributesOne}
							materials={atributesTwo}
							letter={categoryData[8].atributos_fisicos}
						/>
					)}
					{OccupationAttribute && OccupationAttribute[0] && (
						<TextStaticValue
							label={t('Ficha.objetosDeOcupacion')}
							elements={OccupationAttribute}
							labelFilter={
								i18n.language === 'es'
									? 'occupation_objects'
									: 'occupation_objects_en'
							}
						/>
					)}
					{keyConceptsAttribute && keyConceptsAttribute[0] && (
						<TextStaticValue
							label={t('Ficha.conceptosClave')}
							elements={keyConceptsAttribute}
							labelFilter={
								i18n.language === 'es' ? 'key_concepts' : 'key_concepts_en'
							}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
