import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// UI
import H1Principal from '../../atoms/text/H1Principal';
import B1Text from '../../atoms/text/B1Text';
import ImageUpload from '../../molecules/Image/ImageUpload';
import H2Secondary from '../../atoms/text/H2Secondary';

export default function TituloFotoText({
	url,
	formProps,
	setLoading,
	title1,
	title2,
	buttonText,
	dbNameMedia,
	acceptedFormats,
}) {
	let hasMultimedia =
		url && url !== 'No_sound_url' && url !== 'No_url' && url !== 'No_video_url';

	const [toggleEdit, setToggleEdit] = useState(false);
	const [labelTextInFileInput, setLabelTextInFileInput] = useState(buttonText);

	return (
		<>
			<div className={`flex flex-col items-center w-full`}>
				{(title1 || title2) && (
					<div className='flex flex-row justify-between w-full md:px-12'>
						{title1 && <H1Principal>{title1}</H1Principal>}
						{title2 && <B1Text>{title2}</B1Text>}
					</div>
				)}
				{dbNameMedia === 'sound' || dbNameMedia === 'video' ? (
					<div className='flex p-4 w-full sm:w-4/5 lg:w-full justify-center border-dotted'>
						<label
							className='w-full flex justify-center items-center underline text-azul-50 border-dotted border-2 border-black h-100 '
							htmlFor={dbNameMedia}
						>
							{hasMultimedia && !toggleEdit ? (
								<a target={'_blank'} href={url} rel='noreferrer'>
									Abril el archivo de {title1}
								</a>
							) : (
								labelTextInFileInput
							)}
						</label>
						<input
							id={dbNameMedia}
							name={dbNameMedia}
							type='file'
							className='hidden'
							accept={acceptedFormats}
							onChange={(event) => {
								setLabelTextInFileInput(
									event.currentTarget.files[0].name ?? buttonText
								);
								formProps.setFieldValue(
									dbNameMedia,
									event.currentTarget.files[0]
								);
							}}
						/>
					</div>
				) : (
					<div
						className={`flex w-full sm:w-4/5 lg:w-full justify-center my-1 h-120`}
					>
						{hasMultimedia && !toggleEdit ? (
							<LazyLoadImage
								src={url}
								className={'w-full'}
								style={{ objectFit: 'scale-down', height: '420px' }}
								effect='blur'
							/>
						) : (
							<ImageUpload
								formProps={formProps}
								setLoading={setLoading}
								buttonText={buttonText}
								dbName={dbNameMedia}
							/>
						)}
					</div>
				)}
			</div>
			{dbNameMedia && hasMultimedia ? (
				<div className='flex flex-row justify-end'>
					<div onClick={() => setToggleEdit(!toggleEdit)}>
						<H2Secondary className={'w-1/2 p-1'} style={{ cursor: 'pointer' }}>
							{toggleEdit ? 'Volver' : `Cambiar ${title1.toLowerCase()}`}
						</H2Secondary>
					</div>
				</div>
			) : (
				<div className='mb-5' />
			)}
		</>
	);
}
