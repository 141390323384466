import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputBox from '../Input/InputBox';
import B1Text from '../../atoms/text/B1Text';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';

import { useSelector } from 'react-redux';

//component

export default function CategoriasLineEditForm({
	design,
	title,
	setAction,
	values,
	submitAction,
}) {
	const { attributeName } = useSelector((state) => state.singleAttribute);

	return (
		<div className={`md:w-2/3 md:mx-auto ${design}`}>
			<Formik
				enableReinitialize
				initialValues={{
					id: values.id,
					name: values.name || values.label || '',
					name_en: values.name_en || values.label_en || '',
					attribute: attributeName,
					parentId: values.parent_id,
				}}
				validationSchema={Yup.object({
					name: Yup.string().required('Por favor agrega un nombre en español.'),
					name_en: Yup.string().required(
						'Por favor agrega un nombre en Inglés.'
					),
				})}
				onSubmit={submitAction}
			>
				<Form>
					<div className='flex flex-col gap-4'>
						<B1Text design={'pt-3 font-bold text-azul-50'}>{title}</B1Text>
						<InputBox label='Español' dbName='name' />
						<InputBox label='Inglés' dbName='name_en' />
						<div className='flex justify-center py-4'>
							<div className='flex flex-row justify-around w-full sm:w-1/2 mb-4'>
								<ButtonPrincipal
									text='Cancelar'
									typeButton=''
									type='Outline'
									design='mt-1'
									action={() => setAction('read')}
								/>
								<ButtonPrincipal
									text='Guardar'
									typeButton='submit'
									type='Filled'
									design='mt-1'
								/>
							</div>
						</div>
					</div>
				</Form>
			</Formik>
		</div>
	);
}
