//3rd party
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

//UI
import B1Text from '../../atoms/text/B1Text';
import GeneralTemplate from '../../templates/GeneralTemplate';
import { ReactComponent as CreativeCommons } from '../../assets/icon/CreativeCommons.svg';

export default function Legal() {
	const { t } = useTranslation();

	return (
		<GeneralTemplate
			menuVisitor={true}
			addImageIcon={false}
			showSecondNavbar={true}
			showFilter={false}
			section='legal'
		>
			<div className='w-full px-8 lg:pl-16 lg:pr-8 flex flex-col gap-8'>
				<div className='flex flex-col gap-6'>
					<B1Text design='text-azul-50'>{t('TérminosCondiciones.t1')}</B1Text>
					<div className='flex flex-col gap-4 lg:px-8'>
						<B1Text design='font-light break-words'>
							{t('TérminosCondiciones.p1')}
						</B1Text>
						<B1Text design='font-light'>{t('TérminosCondiciones.p2')}</B1Text>
						<B1Text design='font-light'>{t('TérminosCondiciones.p3')}</B1Text>
						<B1Text design='font-light'>{t('TérminosCondiciones.p4')}</B1Text>
						<B1Text design='font-light'>{t('TérminosCondiciones.p5')}</B1Text>
					</div>
				</div>
				<div className='flex flex-col gap-6'>
					<B1Text design='text-azul-50'>{t('PolíticaDePrivacidad.t1')}</B1Text>
					<div className='flex flex-col gap-4 lg:px-8'>
						<B1Text design='font-light break-words'>
							{t('PolíticaDePrivacidad.p1')}
						</B1Text>
					</div>
				</div>
				<div className='flex flex-col gap-6'>
					<B1Text design='text-azul-50'>{t('PropiedadIntelectual.t1')}</B1Text>
					<div className='flex flex-col gap-4 lg:px-8'>
						<div className='relative'>
							<CreativeCommons className='absolute left-0 top-[4px]' />
							<B1Text design='font-light break-words'>
								<Trans i18nKey='PropiedadIntelectual.p1'>
									&emsp;
									<a
										className='text-azul-50 underline'
										href='https://otrasmanerasdeocuparelespaciopublico.com'
										target='_blank'
										rel='noopener noreferrer'
									>
										link
									</a>
								</Trans>
							</B1Text>
						</div>
						<B1Text design='font-light break-words'>
							{t('PropiedadIntelectual.p2')}
						</B1Text>
					</div>
				</div>
			</div>
		</GeneralTemplate>
	);
}
