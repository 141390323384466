import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// BL
import {
	getAgents,
	getColors,
	getKeyConcepts,
	getMaterials,
	getOccupationObjects,
	getOccupationTypes,
	getPermanence,
	getTemporalities,
} from '../../../../business_logic/redux/reducers/staticData';

// UI
import CategoriasEditar from '../../../organisms/Categorias/CategoriasEditar';
import AdminTemplate from '../../../templates/AdminTemplate';
import CategoriasAgregar from '../../../organisms/Categorias/CategoriasAgregar';
import Footer from '../../../organisms/Footer/Footer';
import B2LightText from '../../../atoms/text/B2LightText';
import { setAttributeName } from '../../../../business_logic/redux/reducers/singleAttributeSlice';

export default function ConfigurationSingle(props) {
	const dispatch = useDispatch();
	const { attributeName } = useParams();
	const [singleAttributesArray, setSingleAttributesArray] = useState(null);

	const {
		occupationTypes,
		temporalities,
		permanence,
		agents,
		colors,
		materials,
		keyConcepts,
		occupationObjects,
	} = useSelector((state) => state.staticData);

	useEffect(() => {
		dispatch(setAttributeName(attributeName));
		if (attributeName === 'occupation_type') {
			if (!occupationTypes) {
				dispatch(getOccupationTypes());
			}
			setSingleAttributesArray(occupationTypes);
		}
		if (attributeName === 'temporality') {
			if (!temporalities) {
				dispatch(getTemporalities());
			}
			setSingleAttributesArray(temporalities);
		}
		if (attributeName === 'permanence') {
			if (!permanence) {
				dispatch(getPermanence());
			}
			setSingleAttributesArray(permanence);
		}
		if (attributeName === 'agent') {
			if (!agents) {
				dispatch(getAgents());
			}
			setSingleAttributesArray(agents);
		}
		if (attributeName === 'color') {
			if (!colors) {
				dispatch(getColors());
			}
			setSingleAttributesArray(colors);
		}
		if (attributeName === 'material') {
			if (!materials) {
				dispatch(getMaterials());
			}
			setSingleAttributesArray(materials);
		}
		if (attributeName === 'key_concepts') {
			if (!keyConcepts) {
				dispatch(getKeyConcepts());
			}
			setSingleAttributesArray(keyConcepts);
			dispatch(setAttributeName('key_concept'));
		}
		if (attributeName === 'occupation_object') {
			if (!occupationObjects) {
				dispatch(getOccupationObjects());
			}
			setSingleAttributesArray(occupationObjects);
		}
	}, [
		dispatch,
		occupationTypes,
		attributeName,
		temporalities,
		permanence,
		agents,
		colors,
		materials,
		keyConcepts,
		occupationObjects,
	]);

	return (
		<AdminTemplate navBarTitle={`Editar ${attributeName}`} addImageIcon={false}>
			{singleAttributesArray && (
				<div className='flex flex-col gap-4 mb-8 '>
					{singleAttributesArray.length > 0 ? (
						singleAttributesArray.map((at, i) => (
							<CategoriasEditar
								key={i}
								title={at.name}
								values={at}
								type={'Edit'}
							/>
						))
					) : (
						<div className='w-full text-center mb-5'>
							<B2LightText>No hay elementos</B2LightText>
						</div>
					)}
				</div>
			)}

			<CategoriasAgregar />
			<div className='flex flex-col items-center'>
				<Footer />
			</div>
		</AdminTemplate>
	);
}
