import { Field } from 'formik';

import B1Text from '../../atoms/text/B1Text';

export default function TextAreaBox({ dbName, design, label }) {
	return (
		<div className='w-full'>
			<B1Text design={`w-full`}>{label}</B1Text>
			<Field
				as='textarea'
				name={dbName}
				className={`w-full border border-azul-50 h-36 rounded px-2 py-1 ${design}`}
			/>
		</div>
	);
}
