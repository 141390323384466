import React from 'react';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import ButtonSecondary from '../../atoms/button/ButtonSecondary';
import B1Text from '../../atoms/text/B1Text';
import H1Principal from '../../atoms/text/H1Principal';

export default function PopUp({
	setShowPopUp,
	title,
	text,
	botonTextOne,
	botonTextTwo,
	action,
	customIcon = false,
	defaultIcon = true,
}) {
	return (
		<div className='fixed bg-white z-20 top-0 left-0  w-full h-screen flex justify-center items-center'>
			<div className='w-11/12 sm:w-88 h-64 rounded border flex flex-col justify-between py-9 box_shadowcs'>
				<H1Principal design='font-semibold self-center'>{title}</H1Principal>
				<B1Text design='font-normal self-center text-center'>{text}</B1Text>
				<div className='w-full px-4 flex flex-row justify-between'>
					<ButtonPrincipal
						type='Outline'
						text={botonTextOne}
						action={() => setShowPopUp(false)}
					/>
					<ButtonSecondary
						text={botonTextTwo}
						defaultIcon={defaultIcon}
						customIcon={customIcon}
						action={action}
					/>
				</div>
			</div>
		</div>
	);
}
