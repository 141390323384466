import { useState } from 'react';
import { useTranslation } from 'react-i18next';
// BL
// UI
import Abreviaciones from '../../../atoms/text/Abreviaciones';
import B1Text from '../../../atoms/text/B1Text';
import ClickableChip from './ClickableChip';

export default function FilterChipStaticTiles({
	filterLetter,
	filterLabel,
	filterVariableOne,
	filterVariableTwo,
	labelOne,
	labelTwo,
	allColors,
	allMaterials,
}) {
	const { i18n } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);
	const [showDropdownColors, setShowDropdownColors] = useState(false);
	const [showDropdownMaterials, setShowDropdownMaterial] = useState(false);

	let variableToSort = i18n.language === 'es' ? 'name' : 'name_en';

	let sortedColors = [...allColors]
		.filter((element) => element[variableToSort])
		.sort((a, b) => a[variableToSort].localeCompare(b[variableToSort]));
	let sortedMaterial = [...allMaterials]
		.filter((element) => element[variableToSort])
		.sort((a, b) => a[variableToSort].localeCompare(b[variableToSort]));

	return (
		<div className={`flex flex-col gap-y-2`}>
			<div
				id={filterLetter}
				className='flex flex-row cursor-pointer hover:text-azul-50  max-w-max'
				onClick={() => setShowDropdown(!showDropdown)}
			>
				<B1Text design='flex flex-row gap-1'>
					{
						<Abreviaciones design='font-medium italic'>
							{filterLetter}
						</Abreviaciones>
					}
					{filterLabel}
				</B1Text>
			</div>

			{showDropdown && (
				<div className='flex flex-col gap-1 overflow-y-auto'>
					<div
						className={`flex flex-row gap-1 items-center cursor-pointer max-w-max hover:text-azul-50 ${
							showDropdown && 'text-azul-50'
						}`}
						onClick={() => setShowDropdownColors(!showDropdownColors)}
					>
						<div
							className={`w-3 h-3 rounded-full ${
								showDropdownColors && 'bg-azul-50'
							}`}
						/>
						<B1Text
							design={`font-normal hover:text-azul-50 cursor-pointer ${
								showDropdownColors && 'text-azul-50'
							}`}
						>
							{labelOne}
						</B1Text>
					</div>
					{showDropdownColors && (
						<div className='flex flex-col gap-1 overflow-y-auto pl-2'>
							{sortedColors.map(({ id, label, label_en }) => (
								<ClickableChip
									key={id}
									label={i18n.language === 'es' ? label : label_en}
									valueToFilter={filterVariableOne}
								/>
							))}
						</div>
					)}

					<div
						className={`flex flex-row gap-1 items-center cursor-pointer max-w-max hover:text-azul-50 ${
							showDropdownMaterials && 'text-azul-50'
						}`}
						onClick={() => setShowDropdownMaterial(!showDropdownMaterials)}
					>
						<div
							className={`w-3 h-3 rounded-full ${
								showDropdownMaterials && 'bg-azul-50'
							}`}
						/>
						<B1Text
							design={`font-normal hover:text-azul-50 cursor-pointer ${
								showDropdownMaterials && 'text-azul-50'
							}`}
						>
							{labelTwo}
						</B1Text>
					</div>
					{showDropdownMaterials && (
						<div className='flex flex-col gap-1 overflow-y-auto  pl-2'>
							{sortedMaterial.map((material) => (
								<ClickableChip
									key={material.id}
									label={
										i18n.language === 'es' ? material.label : material.label_en
									}
									valueToFilter={filterVariableTwo}
								/>
							))}
						</div>
					)}
				</div>
			)}
		</div>
	);
}
