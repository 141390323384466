import React, { useEffect } from 'react';
import { ReactComponent as AddImageBlue } from '../../assets/icon/AddImageBlue.svg';
import { ReactComponent as Download } from '../../assets/icon/Download.svg';
import { ReactComponent as BackArrow } from '../../assets/icon/BackArrow.svg';
import { ReactComponent as NextArrow } from '../../assets/icon/NextArrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { EditElementsIds } from '../../../business_logic/redux/reducers/elementsSlice';
import { useNavigate } from 'react-router-dom';
import { positions } from '../../../business_logic/helpers/positions';
import ButtonSecondary from './ButtonSecondary';
import LinkText from '../specialText/LinkText';
import {
	changeCollectionState,
	selectionFiles,
} from '../../../business_logic/redux/reducers/collection';
import { useTranslation } from 'react-i18next';

export default function ButtonAction({
	sketchOn = false,
	stickyClass,
	typeImage,
	singleImage,
	setSection,
	setSectionIndex,
	sketchToDownload,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { singleElement, elements, elementsIds } = useSelector(
		(state) => state.elements
	);
	const { collectionSection, singleCollection } = useSelector(
		(state) => state.mycollection
	);
	const collectionId =
		singleCollection.length > 1
			? singleCollection.map(({ id }) => id)
			: singleCollection;

	const arrayId = elements ? elements.map(({ id }) => id) : [];

	useEffect(() => {
		if (elementsIds.length === 0 || elementsIds[0] === undefined) {
			dispatch(EditElementsIds(arrayId));
		}
	}, [dispatch]);

	const idsList =
		collectionSection === 'collectionImages' ? collectionId : arrayId;

	const collectionImageId =
		collectionSection === 'collectionImages'
			? singleImage.id
			: singleElement.id;

	const { beforeElement, nextElement } = positions(idsList, collectionImageId);

	const goToAnotherUrl = (id) => {
		switch (typeImage) {
			case 'PUBLISHED':
				navigate(`/image/${id}`);
				break;
			case 'PROPOSED':
				navigate(`/admin/proposed/${id}`);
				break;
			default:
				break;
		}
	};
	const download = () => {
		const name = {
			name: sketchToDownload.split('/').slice(-1)[0],
		};
		const values = {
			url: sketchToDownload,
		};
		fetch('/api/get_image/', {
			method: 'POST',
			headers: {
				content_type: 'application/json',
			},
			body: JSON.stringify(values),
		}).then((response) => {
			response.arrayBuffer().then(function (buffer) {
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', name.name + '.jpg'); //or any other extension
				document.body.appendChild(link);
				link.click();

				setTimeout(() => {
					deleteImage(name);
				}, 2000);
			});
		});
	};

	const deleteImage = (name) => {
		fetch('/api/delete_image/', {
			method: 'POST',
			headers: {
				content_type: 'application/json',
			},
			body: JSON.stringify(name),
		})
			.then((res) => res.json())
			.then((data) => data);
	};

	return (
		<div className={stickyClass}>
			<div className={`w-full flex flex-row justify-between pb-4 `}>
				{typeImage === 'PUBLISHED' && (
					<div className='flex flex-row bg-white'>
						<div
							onClick={() => {
								dispatch(changeCollectionState('PROPOSED'));
								dispatch(
									selectionFiles({
										id: singleImage.id,
										list: 'Images add',
										imagen: singleImage.image_url,
										name: singleImage.name,
									})
								);
							}}
							className={`tooltipParent relative w-10 h-10 mr-1 flex justify-center items-center rounded-md border box_shadow_buttons cursor-pointer`}
						>
							<LinkText path='/new-collection'>
								<AddImageBlue />
								<p
									className='tooltipChildren invisible absolute z-10 text-azul-50 bg-white p-1 px-2 drop-shadow-md w-max rounded-md
									top-12 left-0'
								>
									{t('NuevaColeccionOtro.crearColección')}
								</p>
							</LinkText>
						</div>
						<div
							onClick={download}
							className={`tooltipParent relative w-10 h-10 mr-1 ${
								sketchOn ? 'flex' : 'invisible'
							} justify-center items-center rounded-md border box_shadow_buttons cursor-pointer`}
						>
							<Download />
							<p
								className='tooltipChildren absolute z-10 invisible text-azul-50 bg-white p-1 px-2 drop-shadow-md w-max rounded-md
									top-12 left-0'
							>
								{t('FichaHover.descargarDibujo')}
							</p>
						</div>
					</div>
				)}

				<div className='flex flex-row bg-white'>
					<div className={`${!beforeElement && 'invisible'}`}>
						<ButtonSecondary
							icon={<BackArrow />}
							design={'shadow-lg ml-2'}
							onClickAction={() => {
								setSection('Photo');
								setSectionIndex(0);
								goToAnotherUrl(beforeElement);
							}}
						/>
					</div>
					<div className={`${!nextElement && 'invisible'}`}>
						<ButtonSecondary
							icon={<NextArrow />}
							design={'shadow-lg ml-2'}
							onClickAction={() => {
								setSection('Photo');
								setSectionIndex(0);
								goToAnotherUrl(nextElement);
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
