import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
// BL
import { getProposedElements } from '../../../business_logic/redux/reducers/elementsSlice';
import {
	fetchPostData,
	fetchPostImage,
} from '../../../business_logic/helpers/fetchHooks';
import { ENDPOINTS_PROPOSED_IMAGE } from '../../../business_logic/helpers/routes';

//UI
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import InputBox from '../../molecules/Input/InputBox';
import TitleH1 from '../../molecules/Title/TitleH1';
import TituloFotoText from '../../organisms/formUpload/TituloFotoText';
import B1Text from '../../atoms/text/B1Text';
import { useState } from 'react';

export default function SendPhotoForm({ className, setShowConfirmationModal }) {
	const { t } = useTranslation();
	const dispatch = useDispatch;
	const [isSubmitting, setIsSubmitting] = useState(false);

	const initialValues = {
		name: '',
		email: '',
		name_image: '',
		description: '',
		creator_name: '',
		location: '',
		large_file: {},
	};

	return (
		<div className={`${className}`}>
			<Formik
				enableReinitialize
				initialValues={initialValues}
				validationSchema={Yup.object({
					email: Yup.string()
						.required('ProponerFoto.agregaEmail')
						.email('ProponerFoto.emailCorrecto'),
					name_image: Yup.string().required('ProponerFoto.agregaImagen'),
					location: Yup.string()
						.min(2, 'ProponerFoto.MínimoCaracteres')
						.required('ProponerFoto.UbicaciónRequerida'),
					7: Yup.boolean()
						.required('ProponerFoto.TérminosCondicionesUso')
						.oneOf([true], 'ProponerFoto.TérminosCondicionesUso'),
				})}
				onSubmit={async (values) => {
					setIsSubmitting(true);
					await fetchPostImage(values)
						.then(async (data) => {
							values = {
								...values,
								image_id: data.image_id,
							};

							await fetchPostData(
								ENDPOINTS_PROPOSED_IMAGE.POST_PROPOSED_IMAGE,
								values
							);
						})
						.finally(() => {
							setShowConfirmationModal(true);
							setIsSubmitting(false);
						});
				}}
			>
				{(formProps) => (
					<Form>
						<div className='flex flex-col items-center gap-4 md:gap-10'>
							<div className='w-full flex flex-col lg:flex-row gap-10'>
								<div className='w-full lg:w-1/2 flex flex-col items-center '>
									<TituloFotoText
										buttonText={t('ProponerFoto.agregarFoto')}
										formProps={formProps}
									/>
									{formProps.errors.name_image && (
										<B1Text design={`text-azul-50 max-w-max `}>
											{t(formProps.errors?.name_image)}
										</B1Text>
									)}
								</div>

								<div className='w-11/12 mx-auto lg:w-1/2 flex flex-col gap-2 md:gap-4 my-auto  '>
									<InputBox
										label={t('ProponerFoto.nombreDeOcupacion')}
										type='horizontal_small'
										dbName='name'
									/>
									<InputBox
										label={
											<Trans i18nKey='ProponerFoto.ubicacion'>
												text<span className='text-azul-50'></span>
											</Trans>
										}
										type='horizontal_small'
										dbName='location'
									/>
									<InputBox
										label={t('ProponerFoto.descripción')}
										type='horizontal_small'
										dbName='description'
									/>

									<TitleH1 design='my-10 w-max mx-auto lg:mx-0  '>
										{t('ProponerFoto.datosPersonales')}
									</TitleH1>

									<InputBox
										label={t('ProponerFoto.nombreOSeudonimo')}
										type='horizontal_small'
										dbName='creator_name'
									/>
									<InputBox
										label={
											<Trans i18nKey='ProponerFoto.email'>
												text<span className='text-azul-50'>text</span>
											</Trans>
										}
										dbName='email'
										type='horizontal_small'
										fieldType='email'
									/>
									<InputBox
										label={
											<Trans i18nKey='ProponerFoto.autorización'>
												text
												<Link className=' text-azul-50' to={`/legal`}>
													text
												</Link>
											</Trans>
										}
										type='checkbox_left'
										fieldType='checkbox'
										dbName='7'
									/>
									{(formProps.errors?.email ||
										formProps.errors?.location ||
										(formProps.errors && formProps.errors['7'])) && (
										<B1Text design={`text-azul-50`}>
											{t('ProponerFoto.CamposObligatorios')}
										</B1Text>
									)}
								</div>
							</div>
							<div>
								<ButtonPrincipal
									text={t('ProponerFoto.enviar')}
									type='Filled'
									disabled={isSubmitting}
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
