// third party
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// BL
import {
	addValueToFilters,
	removeValueFromFilter,
} from '../../../../business_logic/helpers/filterHelpers';
// UI
import B1Text from '../../../atoms/text/B1Text';

export default function ClickableChip({ label, valueToFilter }) {
	const dispatch = useDispatch();
	const { filterArray, arrayVariables } = useSelector((state) => state.filter);
	const [isClicked, setIsClicked] = useState(false);

	useEffect(() => {
		let valueIsFiltered = arrayVariables.find((element) => element === label);
		if (valueIsFiltered) {
			setIsClicked(true);
		} else {
			setIsClicked(false);
		}
	}, [arrayVariables, label]);

	return (
		<div
			className={`flex flex-row gap-1 items-center cursor-pointer max-w-max hover:text-azul-50 ${
				isClicked && 'text-azul-50'
			}`}
			onClick={() => {
				isClicked
					? removeValueFromFilter(
							filterArray,
							arrayVariables,
							valueToFilter,
							label,
							dispatch
					  )
					: addValueToFilters(
							filterArray,
							arrayVariables,
							valueToFilter,
							label,
							dispatch
					  );
				setIsClicked(!isClicked);
			}}
		>
			<div className={`w-3 h-3 rounded-full ${isClicked && 'bg-azul-50'}`} />
			<B1Text>{label}</B1Text>
		</div>
	);
}
