import React from 'react';
import { Navigate } from 'react-router-dom';
//import { fetchGetData } from '../../../../hooks/fetchGetData';
import { getToken } from '../../../../business_logic/authentication/servicesAuthentication';

function PrivateRoute({ children }) {
	const auth = getToken();
	return auth ? children : <Navigate to='/admin/login' />;
}

function PublicRoute({ children }) {
	const auth = !getToken();
	return auth ? children : <Navigate to='/admin' />;
}

export { PrivateRoute, PublicRoute };
