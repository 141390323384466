import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_CATEGORIES } from '../../helpers/routes';
import { fetchGetData } from '../../helpers/fetchHooks';

export const getMainActivities = createAsyncThunk(
	'acitivity/fetchMainCategories',
	async () => {
		return await fetchGetData(ENDPOINTS_CATEGORIES.GET_MAIN_CATEGORIES);
	}
);

export const getSubActivities = createAsyncThunk(
	'acitivity/fetchSubCategories',
	async (id) => {
		return await fetchGetData(ENDPOINTS_CATEGORIES.GET_SUB_CATEGORIES + id);
	}
);

const initialState = {
	parentId: '',
	activities: [],
	subActivities: [],
	activitiesChange: 1,
	subTitle: '',
};

export const activitySlice = createSlice({
	name: 'activity',
	initialState,
	reducers: {
		setParentId: (state, { payload }) => {
			state.attributeName = payload;
		},
		putSubTitle: (state, { payload }) => {
			state.subTitle = payload;
		},
	},
	extraReducers: {
		/* get main attributes */
		[getMainActivities.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getMainActivities.fulfilled]: (state, { payload }) => {
			state.activities = payload;
			state.status = 'success';
		},
		[getMainActivities.rejected]: (state, action) => {
			state.status = 'failed';
		},
		/* get sub attributes */
		[getSubActivities.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getSubActivities.fulfilled]: (state, { payload }) => {
			state.subActivities = payload;
			state.status = 'success';
		},
		[getSubActivities.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});
export const { setParentId, putSubTitle } = activitySlice.actions;

export default activitySlice.reducer;
