import React from 'react';
import B1Text from '../text/B1Text';
import { ReactComponent as Vector } from '../../assets/icon/Vector.svg';
import { ReactComponent as Trash } from '../../assets/icon/Trash.svg';

export default function ButtonSecondary({
	text,
	typeButon = 'submit',
	type = 'Full Medium',
	action,
	style,
	defaultIcon = false,
	customIcon = false,
}) {
	let buttonClasses = '';
	let textClasses = '';
	if (type === 'Full Medium') {
		buttonClasses = 'bg-azul-50';
		textClasses = 'text-white';
	} else if (type === 'Outline Medium') {
		buttonClasses = 'bg-white border border-azul-50 active:bg-blue-200';
		textClasses = 'text-azul-50';
	} else if (type === 'Full Small') {
		buttonClasses = 'bg-azul-50';
	}
	return (
		<button
			className={`${buttonClasses} rounded shadow_box w-36 h-10 mt-5`}
			type={typeButon}
			onClick={action}
			style={style}
		>
			<div className={'flex flex-row justify-center p-1 items-center'}>
				{defaultIcon && (
					<Vector className='mr-2' fill='bg-white' stroke='bg-white' />
				)}
				{customIcon && <Trash className='mr-2' stroke='white' />}
				<B1Text design={textClasses}>{text}</B1Text>
			</div>
		</button>
	);
}
