import AuthenticationTemplate from '../../../templates/AuthenticationTemplate';
import LoginForm from './LoginForm';

export default function Login(props) {
	return (
		<AuthenticationTemplate>
			<LoginForm />
		</AuthenticationTemplate>
	);
}
