import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { app_user_login } from '../../../../business_logic/authentication/servicesAuthentication';

//component
import ButtonPrincipal from '../../../atoms/button/ButtonPrincipal';
import InputBox from '../../../molecules/Input/InputBox';

export default function LoginForm({ className }) {
	let navigate = useNavigate();
	return (
		<div className={`${className} w-full`}>
			<Formik
				initialValues={{ email: '', password: '' }}
				validationSchema={Yup.object({
					email: Yup.string()
						.required('Porfavor agrega un email.')
						.email('Porfavor agregar un email correcto.'),
					password: Yup.string().required('Porfavor agrega una contraseña.'),
				})}
				onSubmit={(values) => {
					app_user_login(values).then(() => {
						navigate('/admin/published');
						window.location.reload();
					});
				}}
			>
				<Form>
					<InputBox label='Usuario' dbName='email' design='mb-5' />
					<InputBox
						label='Contraseña'
						dbName='password'
						fieldType='password'
						design='mb-5'
					/>

					<div className='flex justify-center mt-4'>
						<ButtonPrincipal text='Ingresar' type='Filled' />
					</div>
					<div className='flex justify-center w-9/12 m-auto mt-4'></div>
				</Form>
			</Formik>
		</div>
	);
}
