// third party
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// BL

// UI
import Abreviaciones from '../../../atoms/text/Abreviaciones';
import B1Text from '../../../atoms/text/B1Text';
import ClickableChipLevels from './ClickableChipLevels';

export default function FilterChipLevels({
	filterLetter,
	filterLabel,
	filterVariable,
	chipValues,
}) {
	const { i18n } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);

	const ones = [
		'',
		'one',
		'two',
		'three',
		'four',
		'five',
		'six',
		'seven',
		'eight',
		'nine',
	];

	//array of levels
	let levelsArray = {};
	// fill the object of Levels with keys that represents the levels.
	chipValues.map((element) => {
		if (!element.nivel) {
			return null;
		}
		element = {
			...element,
			nivel: ones.indexOf(element.nivel),
		};

		if (
			Object.keys(levelsArray).find(
				(level) => `${level}` === `${element.nivel}`
			)
		) {
			levelsArray[element.nivel].push(element);
			return null;
		}

		levelsArray = { ...levelsArray, [element.nivel]: [] };
		levelsArray[element.nivel].push(element);
		return null;
	});

	const valueToSort = i18n.language === 'es' ? 'label' : 'label_en';
	levelsArray[1]?.sort((a, b) => a[valueToSort].localeCompare(b[valueToSort]));

	return (
		<div className={`flex flex-col gap-y-2`}>
			<div
				className='flex flex-row cursor-pointer hover:text-azul-50  max-w-max'
				onClick={() => {
					setShowDropdown(!showDropdown);
				}}
			>
				<B1Text design='flex flex-row gap-1'>
					{
						<Abreviaciones design='font-medium italic'>
							{filterLetter}
						</Abreviaciones>
					}
					{filterLabel}
				</B1Text>
			</div>
			{showDropdown && (
				<div className='flex flex-col gap-1 overflow-y-auto'>
					{levelsArray[1]?.map((element, index) => (
						<ClickableChipLevels
							key={index}
							label={i18n.language === 'es' ? element.label : element.label_en}
							valueToFilter={filterVariable}
							allLevelsChips={levelsArray}
							currentLevel={element.nivel + 1}
							parentId={element.id}
						/>
					))}
				</div>
			)}
		</div>
	);
}
