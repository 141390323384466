//UI
import { useEffect } from 'react';
// UI
import NavbarPrimary from '../organisms/Navbar/NavbarPrimary';
import NavbarSecondary from '../organisms/Navbar/NavbarSecondary';
import NavbarSelectImage from '../organisms/Navbar/NavbarSelectImage';
import Footer from '../organisms/Footer/Footer';

export default function GeneralTemplate({
	children,
	section,
	addImageIcon,
	navBarTitle,
	showSecondNavbar = false,
	redirectIcon = '',
	showThirdNavbar = false,
	navBarFiles,
	menuVisitor,
	showSecondNavbarProposed,
	editImageIcon,
	showFilter,
	showLeftArrow,
	menuAdmins,
	designNavbarExtContainer = 'pt-32',
}) {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'auto',
		});
	}, []);
	return (
		<div className='w-full min-h-screen md:relative'>
			<div className='fixed top-0 z-10 bg-white w-full'>
				<NavbarPrimary
					section={section}
					menuVisitor={menuVisitor}
					hasFilter={showFilter}
					menuAdmins={menuAdmins}
				/>
			</div>
			<div className={designNavbarExtContainer}>
				{showSecondNavbarProposed && (
					<NavbarSecondary
						navBarTitle={navBarTitle}
						addImageIcon={addImageIcon}
						redirectIcon={redirectIcon}
						editImageIcon={editImageIcon}
					/>
				)}
				{showSecondNavbar && (
					<NavbarSecondary
						navBarTitle={navBarTitle}
						addImageIcon={addImageIcon}
						redirectIcon={redirectIcon}
						showLeftArrow={showLeftArrow}
						section={section}
					/>
				)}
				{showThirdNavbar && (
					<NavbarSelectImage section={section} navBarFiles={navBarFiles} />
				)}
			</div>

			<main className='pt-4' style={{ minHeight: 'calc(100vh - 240px)' }}>
				{children}
			</main>
			<Footer />
		</div>
	);
}
