import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_SPACES } from '../../helpers/routes';
import { fetchGetData } from '../../helpers/fetchHooks';

export const getMainSpaces = createAsyncThunk(
	'acitivity/fetchMainCategories',
	async () => {
		return await fetchGetData(ENDPOINTS_SPACES.GET_ALL_SPACES);
	}
);

const initialState = {
	parentId: '',
	spaces: [],
	spacesChange: 1,
	subTitle: '',
};

export const spaceSlice = createSlice({
	name: 'space',
	initialState,
	reducers: {
		setParentId: (state, { payload }) => {
			state.attributeName = payload;
		},
		putSubTitle: (state, { payload }) => {
			state.subTitle = payload;
		},
	},
	extraReducers: {
		/* get main attributes */
		[getMainSpaces.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getMainSpaces.fulfilled]: (state, { payload }) => {
			state.spaces = payload;
			state.status = 'success';
		},
		[getMainSpaces.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});
export const { setParentId, putSubTitle } = spaceSlice.actions;

export default spaceSlice.reducer;
