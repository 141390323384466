export function positions(elementsIds, id) {
	let position = elementsIds.indexOf(Number(id));
	let positionNextElement = elementsIds.indexOf(elementsIds[position + 1]);
	let positionBeforeElement = elementsIds.indexOf(elementsIds[position - 1]);

	let nextElement = 0;
	let beforeElement = 0;

	nextElement =
		elementsIds[positionNextElement] && elementsIds[positionNextElement];
	beforeElement =
		elementsIds[positionBeforeElement] && elementsIds[positionBeforeElement];

	return {
		beforeElement,
		nextElement,
	};
}
