import InputView from '../../../molecules/Input/InputView';
import TitleH1 from '../../../molecules/Title/TitleH1';

export default function ProfileView({ profile }) {
	return (
		<div className='p-2 md:px-14 flex flex-col justify-center lg:px-20'>
			<div className='flex flex-col gap-4 md:flex-row md:gap-24 py-8 lg:gap-48'>
				<InputView title={'Nombre'} textInfo={profile.first_name} />
				<InputView title={'Apellido'} textInfo={profile.last_name} />
			</div>

			<TitleH1>Información de la cuenta</TitleH1>
			<div className='flex flex-col gap-4 py-8 md:flex-row md:gap-12 lg:gap-48'>
				<InputView title={'Correo'} textInfo={profile.email} design='gap-4' />
				<InputView title={'Contraseña'} textInfo={'*******'} />
			</div>
		</div>
	);
}
