const ENDPOINTS_SINGLEATTRIBUTE = {
	GET_SINGLE_ATTRIBUTES: '/api/single_attributes/',
	POST_SINGLE_ATTRIBUTE: '/api/single_attribute/',
	PUT_SINGLE_ATTRIBUTE: '/api/single_attribute/',
	DELETE_SINGLE_ATTRIBUTE: '/api/single_attribute/delete/',
};

const ENDPOINTS_PROPOSED_IMAGE = {
	POST_PROPOSED_IMAGE: '/api/proposed_image/upload_image/',
	GET_PROPOSED_IMAGES: '/api/image/element_by_status/',
	GET_PROPOSED_IMAGE: '/api/image/details_image/',
	REDIRECT_PROPOSED_IMAGES: '/admin/proposed/',
	REJECT_IMAGE: '/api/proposed_image/reject/',
	ACCEPT_IMAGE: '/api/element/create_element/',
	GET_IMAGES_BY_STATUS: '/api/element_fast/element_by_single_status/',
	REDIRECT_DRAFT_IMAGES: '/admin/draft/',
};
const ENDPOINTS_ELEMENT = {
	GET_IMAGES_BY_STATUS: '/api/element_fast/element_by_single_status/',
	REDIRECT_ELEMENT: '/image/',
	GET_ELEMENT: '/api/element/element_details/',
	PUT_ELEMENT: '/api/element/edit_publish_element/',
};

const ENDPOINTS_PROFILE = {
	GET_PROFILE: '/api/user/',
	EDIT_PROFILE: '/api/user/edit/',
};

const ENDPOINTS_CATEGORIES = {
	GET_ALL_CATEGORIES: '/api/category/categories_sub_categories_info',
	GET_MAIN_CATEGORIES: '/api/categories/main/',
	GET_SUB_CATEGORIES: '/api/categories/sub_categories/',
};

const ENDPOINTS_SPACES = {
	GET_ALL_SPACES: '/api/space/space_sub_space_info',
};

const ENDPOINTS_COLORS = {
	GET_ALL_COLORS: '/api/element/element_by_status/colors',
	GET_COLORS: '/api/color/',
};

const ENDPOINTS_MATERIALS = {
	GET_ALL_MATERIALS: '/api/element/element_by_status/materials',
	GET_MATERIAL: '/api/material/',
};

const ENDPOINTS_COLLECTIONS = {
	GET_ALL_COLLECTIONS: '/api/collection/',
	GET_SINGLE_COLLECTION: '/api/collection/',
	GET_SINGLE_COLLECTION_INFO: '/api/collection_info/',
	GET_CREATED_SINGLE_COLLECTION: '/api/collection_info/last_collection',
	POST_COLLECTION: '/api/collection/create/',
};

const ENDPOINTS_COUNTRIES = {
	GET_COUNTRIES: '/api/countries/',
};

const ENDPOINTS_LOCATIONS = {
	GET_LOCATIONS: '/api/locations/',
};

const ENDPOINTS_OCCUPATIONS = {
	GET_OCCUPATIONS: '/api/occupation_type/',
};

const ENDPOINTS_KEY_CONCEPTS = {
	GET_KEY_CONCEPTS: '/api/key_concepts/',
};

const ENDPOINTS_TEMPORALITIES = {
	GET_TEMPORALITIES: '/api/temporality/',
};

const ENDPOINTS_PERMANENCES = {
	GET_PERMANENCES: '/api/permanence/',
};

const ENDPOINTS_AGENTS = {
	GET_AGENTS: '/api/agent/',
};
const ENDPOINTS_OCCUPATION_OBJECTS = {
	GET_OCCUPATION_OBJECTS: '/api/occupation_object/',
};

export {
	ENDPOINTS_SINGLEATTRIBUTE,
	ENDPOINTS_PROPOSED_IMAGE,
	ENDPOINTS_ELEMENT,
	ENDPOINTS_CATEGORIES,
	ENDPOINTS_PROFILE,
	ENDPOINTS_SPACES,
	ENDPOINTS_COLORS,
	ENDPOINTS_MATERIALS,
	ENDPOINTS_COLLECTIONS,
	ENDPOINTS_COUNTRIES,
	ENDPOINTS_LOCATIONS,
	ENDPOINTS_KEY_CONCEPTS,
	ENDPOINTS_OCCUPATIONS,
	ENDPOINTS_OCCUPATION_OBJECTS,
	ENDPOINTS_TEMPORALITIES,
	ENDPOINTS_PERMANENCES,
	ENDPOINTS_AGENTS,
};
