import React from 'react';
import { Helmet } from 'react-helmet-async';

/**
 * @description component that renders custom SEO meta tags
 */
const SEO = ({ title, description, ogTitle, ogDescription, ogImage }) => {
	return (
		<Helmet>
			<title>{title}</title>
			<meta property='og:title' content={ogTitle} />
			<meta name='description' content={description} />
			<meta name='og:description' content={ogDescription} />
			{ogImage && <meta property='og:image' content={ogImage} />}
		</Helmet>
	);
};

SEO.defaultProps = {
	title: '[otras] maneras de ocupar el espacio',
	description:
		'El proyecto [otras] maneras de ocupar el espacio público es un catálogo de usos, objetos, elementos y acciones de ocupación y aprovechamiento del espacio público; una relación detallada, casi un inventario, de usos actuales que incluyen aquellos no intencionados, planeados o diseñados. El objetivo es analizar, rescatar y reivindicar, de manera crítica, las formas y las acciones cotidianas que dan vida a nuestras ciudades y configuran la esfera de lo público.',
	ogTitle: '[otras] maneras de ocupar el espacio',
	ogDescription:
		'El proyecto [otras] maneras de ocupar el espacio público es un catálogo de usos, objetos, elementos y acciones de ocupación y aprovechamiento del espacio público; una relación detallada, casi un inventario, de usos actuales que incluyen aquellos no intencionados, planeados o diseñados. El objetivo es analizar, rescatar y reivindicar, de manera crítica, las formas y las acciones cotidianas que dan vida a nuestras ciudades y configuran la esfera de lo público.',
};

export default SEO;
