import React from 'react';
import B1Text from '../../atoms/text/B1Text';
import { useTranslation } from 'react-i18next';
import Abreviaciones from '../../atoms/text/Abreviaciones';

export default function TextDeepValues({
	label,
	information = false,
	labelFilter,
}) {
	const { t, i18n } = useTranslation();

	return (
		<div className='w-full flex flex-row my-2 md:my-4 3xl:mb-6 justify-between md:justify-start'>
			<div className='w-2/5 flex flex-row mr-4 3xl:mr-10 md:mr-0 '>
				<div className='mr-2 flex flex-row'>
					<B1Text design='font-medium '>
						{
							<Abreviaciones design='font-medium '>
								{t(`Abreviaciones.${label}`)}
							</Abreviaciones>
						}
					</B1Text>
				</div>
				<B1Text design='font-normal text-black text-opacity-100 '>
					{label}
				</B1Text>
			</div>
			<div className='w-3/6 md:w-3/5 flex flex-col'>
				{information.map((item, index) => {
					if (item.nivel === 'one')
						return (
							<B1Text
								key={index}
								design='w-full cursor-pointer text-azul-50 font-normal mb-2'
								labelFilter={labelFilter}
								valueToFilter={
									i18n.language === 'es' ? item.label : item.label_en
								}
								needsRedirection={true}
							>
								{i18n.language === 'es' ? item.label : item.label_en}
							</B1Text>
						);
					else if (item.nivel === 'two')
						return (
							<B1Text
								key={index}
								labelFilter={labelFilter}
								design='w-full cursor-pointer text-azul-50 font-normal mb-2 pl-4'
								valueToFilter={
									i18n.language === 'es' ? item.label : item.label_en
								}
								needsRedirection={true}
							>
								{i18n.language === 'es' ? item.label : item.label_en}
							</B1Text>
						);
					else if (item.nivel === 'three')
						return (
							<B1Text
								key={index}
								labelFilter={labelFilter}
								design='w-full cursor-pointer text-azul-50 font-normal mb-2 pl-6'
								valueToFilter={
									i18n.language === 'es' ? item.label : item.label_en
								}
								needsRedirection={true}
							>
								{i18n.language === 'es' ? item.label : item.label_en}
							</B1Text>
						);
					else if (item.nivel === 'four')
						return (
							<B1Text
								key={index}
								labelFilter={labelFilter}
								design='w-full cursor-pointer text-azul-50 font-normal mb-2 pl-8'
								valueToFilter={
									i18n.language === 'es' ? item.label : item.label_en
								}
								needsRedirection={true}
							>
								{i18n.language === 'es' ? item.label : item.label_en}
							</B1Text>
						);
					else if (item.nivel === 'five')
						return (
							<B1Text
								key={index}
								labelFilter={labelFilter}
								design='w-full cursor-pointer text-azul-50 font-normal mb-2 pl-10'
								valueToFilter={
									i18n.language === 'es' ? item.label : item.label_en
								}
								needsRedirection={true}
							>
								{i18n.language === 'es' ? item.label : item.label_en}
							</B1Text>
						);
					return null;
				})}
			</div>
		</div>
	);
}
