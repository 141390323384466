import React from 'react';
import H2Secondary from '../../atoms/text/H2Secondary';

export default function TitleNumberCollection({
	title,
	number,
	mainDescription,
}) {
	return (
		<div>
			<div className='flex flex-row justify-between py-4'>
				<H2Secondary design='font-normal'>{title}</H2Secondary>
				<H2Secondary design='font-normal'>{number}</H2Secondary>
			</div>
			<div className='pb-4'>
				<H2Secondary design='font-normal'>{mainDescription}</H2Secondary>
			</div>
		</div>
	);
}
