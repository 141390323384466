import Logo from '../atoms/menu/Logo';

export default function AuthenticationTemplate({ children }) {
	return (
		<div className='flex h-screen'>
			<div className='w-full flex flex-col justify-center h-full bg-white'>
				<Logo type='mediano' design='m-auto mb-12 mt-20' />
				<div className='w-full md:w-2/3 lg:w-1/2 m-auto mt-0 p-4'>
					{children}
				</div>
			</div>
		</div>
	);
}
