import React from 'react';
import B1Text from '../../atoms/text/B1Text';
import { useTranslation } from 'react-i18next';
import Abreviaciones from '../../atoms/text/Abreviaciones';

export default function TextMultipleVal({ label, colors, materials }) {
	const { t, i18n } = useTranslation();

	return (
		<div className='w-full flex flex-row my-2 md:my-4 3xl:mb-6 justify-between md:justify-start'>
			<div className='w-2/5 3xl:w-2/4 flex flex-row mr-4 md:mr-0 '>
				<div className='mr-2 flex flex-row'>
					<B1Text design='font-medium '>
						{
							<Abreviaciones design='font-medium '>
								{t(`Abreviaciones.${label}`)}
							</Abreviaciones>
						}
					</B1Text>
				</div>
				<B1Text design='font-normal text-black text-opacity-100 '>
					{label}
				</B1Text>
			</div>
			<div className='w-3/6 md:w-3/5 flex flex-col'>
				<B1Text design='w-full text-azul-50 font-normal mb-2'>Color</B1Text>
				{colors.map((item, index) => (
					<B1Text
						key={index}
						design='w-full text-azul-50 font-normal mb-2 pl-4 cursor-pointer'
						labelFilter={i18n.language === 'es' ? 'color' : 'color_en'}
						valueToFilter={item}
						needsRedirection={true}
					>
						{item}
					</B1Text>
				))}
				<B1Text design='w-full text-azul-50 font-normal mb-2'>Material</B1Text>
				{materials.map((item, index) => (
					<B1Text
						key={index}
						design='w-full text-azul-50 font-normal mb-2 pl-4 cursor-pointer'
						labelFilter={i18n.language === 'es' ? 'material' : 'material_en'}
						valueToFilter={item}
						needsRedirection={true}
					>
						{item}
					</B1Text>
				))}
			</div>
		</div>
	);
}
