import { useTranslation } from 'react-i18next';

// UI
import B2LightText from '../../../atoms/text/B2LightText';
import AdminTemplate from '../../../templates/AdminTemplate';
import CategoriasEditar from '../../../organisms/Categorias/CategoriasEditar';

export default function Configuration() {
	const { t } = useTranslation();

	const allCategoriesArray = [
		{
			title: 'Espacio',
			url: '/admin/categories/space/0',
		},
		{
			title: 'Ocupación',
			url: '/admin/categories/occupation_type',
		},
		{
			title: 'Temporalidad',
			url: '/admin/categories/temporality',
		},
		{
			title: 'Permanencia',
			url: '/admin/categories/permanence',
		},
		{
			title: 'Agente',
			url: '/admin/categories/agent',
		},
		{
			title: 'Actividad',
			url: '/admin/categories/activity/0',
		},
		{
			title: 'Color',
			url: '/admin/categories/color',
		},
		{
			title: 'Material',
			url: '/admin/categories/material',
		},
		{
			title: 'Conceptos clave',
			url: '/admin/categories/key_concepts',
		},
		{
			title: 'Objetos de ocupación',
			url: '/admin/categories/occupation_object',
		},
	];

	return (
		<AdminTemplate navBarTitle={'Categorías'} addImageIcon={false}>
			<div className='mx-auto mt-10'>
				<div className='grid grid-cols-1 md:grid-cols-2 gap-14 mb-10'>
					{allCategoriesArray.map((category, index) => (
						<CategoriasEditar
							key={index}
							title={category.title}
							url={category.url}
						/>
					))}
				</div>
			</div>
			<div className='mb-5'>
				<a
					href='https://www.itlookssimple.com/'
					target='_blank'
					rel='noopener noreferrer'
				>
					<B2LightText design='font-normal'>
						{t('Footer.DesarrolladoPorIT')}
					</B2LightText>
				</a>
			</div>
		</AdminTemplate>
	);
}
