import React, { useEffect } from 'react';
import { ReactComponent as Back } from '../../assets/icon/LeftWhite.svg';

export default function ImageModal({
	imgrender,
	name,
	actionClose,
	modalFullImage,
}) {
	useEffect(() => {
		if (modalFullImage) document.body.style.overflow = 'hidden';
	}, [modalFullImage]);

	return (
		<div
			className={`fixed bg-white z-20 top-0 left-0 w-full h-screen ${
				modalFullImage === true ? 'overflow-hidden' : ''
			}`}
		>
			<div className='bg-gris-50 w-full h-full flex flex-col justify-center items-center'>
				<div className='mt-4 md:mt-8 px-2 md:px-4 w-full h-12 md:h-24'>
					<Back className='cursor-pointer' onClick={actionClose} />
				</div>
				<div className='w-full h-full flex px-2 pb-2 md:px-8 md:pb-24 md:h-7/8 xl:h-full lg:pb-12'>
					<img className='mx-auto object-contain' src={imgrender} alt={name} />
				</div>
			</div>
		</div>
	);
}
