import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

// BL
import {
	editProfile,
	getProfile,
} from '../../../../business_logic/redux/reducers/profileSlice';

//UI
import ButtonPrincipal from '../../../atoms/button/ButtonPrincipal';
import InputBox from '../../../molecules/Input/InputBox';
import TitleH1 from '../../../molecules/Title/TitleH1';
import B1Text from '../../../atoms/text/B1Text';

export default function ProfileEditForm({ initialValues, setEditView }) {
	const dispatch = useDispatch();
	const [isEditingPassword, setIsEditingPassword] = useState(false);

	return (
		<div>
			<Formik
				initialValues={{ ...initialValues, new_password: '' }}
				validationSchema={Yup.object({
					email: Yup.string()
						.required('Por favor agrega un email.')
						.email('Por favor agregar un email correcto.'),
				})}
				onSubmit={(values) => {
					dispatch(editProfile(values)).then((res) => {
						dispatch(getProfile());
						setEditView(false);
					});
				}}
			>
				<Form className='mt-12'>
					<div className='grid grid-cols-1 md:grid-cols-2 w-full gap-4 '>
						<InputBox label='Nombre' dbName='first_name' />
						<InputBox label='Apellido' dbName='last_name' />
					</div>

					<TitleH1>Información de la cuenta</TitleH1>

					<div className={`grid grid-cols-1 md:grid-cols-2 gap-4`}>
						<InputBox label='Correo' dbName='email' />

						<div>
							{isEditingPassword && (
								<InputBox
									label='Nueva Contraseña'
									dbName='new_password'
									fieldType='password'
								/>
							)}
						</div>

						<div className={`order-3 md:order-4`}>
							{isEditingPassword && (
								<InputBox
									label='Confirmar nueva contraseña'
									dbName='confirm_password'
									fieldType='password'
								/>
							)}
						</div>
						<div
							className={`pl-4 pt-2 order-4 md:order-3 my-auto`}
							onClick={() => setIsEditingPassword(!isEditingPassword)}
						>
							<B1Text design=' underline text-azul-50 cursor-pointer'>
								{isEditingPassword ? 'Quitar' : 'Agregar'} nueva contraseña
							</B1Text>
						</div>
					</div>

					<div className='flex flex-row mt-10 justify-around'>
						<ButtonPrincipal
							action={() => setEditView(false)}
							text='Cancelar'
							type='Outline'
						/>
						<ButtonPrincipal text='Guardar' type='Filled' />
					</div>
				</Form>
			</Formik>
		</div>
	);
}
