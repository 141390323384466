import { Formik, Form } from 'formik';
import InputBox from '../Input/InputBox';
import B1Text from '../../atoms/text/B1Text';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';

import { useSelector } from 'react-redux';

//component

export default function CategoriasDelete({
	title,
	values,
	setAction,
	submitAction,
}) {
	const { attributeName } = useSelector((state) => state.singleAttribute);

	return (
		<div className={`md:w-2/3 md:mx-auto`}>
			<Formik
				initialValues={{ id: values.id, attribute: attributeName }}
				onSubmit={submitAction}
			>
				<Form>
					<B1Text design={' font-bold text-red-500'}>Borrar {title}?</B1Text>
					<InputBox
						label='id'
						dbName='id'
						type='horizontal_small'
						design='hidden'
					/>
					<InputBox
						label='attribute'
						dbName='attribute'
						type='horizontal_small'
						design='hidden'
					/>
					<div className='flex flex-row justify-between'>
						<ButtonPrincipal
							text='Cancelar'
							type='Link'
							action={() => setAction('read')}
						/>
						<ButtonPrincipal text='Borrar' type='Delete' />
					</div>
				</Form>
			</Formik>
		</div>
	);
}
