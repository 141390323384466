import ReactLoading from 'react-loading';

import { useTranslation } from 'react-i18next';
import H1Principal from '../../atoms/text/H1Principal';

export default function Loading() {
	const { t } = useTranslation();
	return (
		<div
			className='w-20 m-auto flex items-center justify-center'
			style={{ minHeight: 'calc(100vh - 240px)' }}
		>
			<div>
				<div className='m-auto' style={{ width: '60px' }}>
					<ReactLoading
						type={'spokes'}
						color={'#1288FF'}
						height={'60px'}
						width={'60px'}
					/>
				</div>
				<H1Principal design='text-azul-50 mt-2'>
					{t('Cargando.Cargando')}
				</H1Principal>
			</div>
		</div>
	);
}
