import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import CategoriasEditar from '../../../organisms/Categorias/CategoriasEditar';
import AdminTemplate from '../../../templates/AdminTemplate';
import CategoriasAgregar from '../../../organisms/Categorias/CategoriasAgregar';
import B2LightText from '../../../atoms/text/B2LightText';
import { getCategories } from '../../../../business_logic/redux/reducers/staticData';
import { useTranslation } from 'react-i18next';
import { setAttributeName } from '../../../../business_logic/redux/reducers/singleAttributeSlice';

export default function ConfigurationActivity() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { parentId } = useParams();
	const { categories } = useSelector((state) => state.staticData);
	const [categoriesToRender, setCategoriesToRender] = useState(null);

	useEffect(() => {
		if (!categories) {
			dispatch(getCategories());
		}
		setCategoriesToRender(
			categories?.filter((space) => space.parent_id === parseInt(parentId))
		);
		dispatch(setAttributeName('category'));
	}, [dispatch, categories, parentId]);

	return (
		<AdminTemplate
			navBarTitle={`Editar ${
				parentId === '0' ? 'categorías' : 'subcategorías'
			}`}
			addImageIcon={false}
		>
			{categoriesToRender && (
				<div className='flex flex-col gap-4 mb-8'>
					{categoriesToRender.length > 0 ? (
						categoriesToRender.map((values, i) => {
							return (
								<CategoriasEditar
									key={i}
									title={values.label}
									values={values}
									type={values.nivel === 'three' ? 'Edit' : 'All'}
									url={'/admin/categories/activity/' + values.id}
								/>
							);
						})
					) : (
						<div className='w-full text-center mb-5'>
							<B2LightText>No hay elementos</B2LightText>
						</div>
					)}
				</div>
			)}
			<CategoriasAgregar parentId={parentId} />
			<div className='my-5'>
				<a
					href='https://www.itlookssimple.com/'
					target='_blank'
					rel='noopener noreferrer'
				>
					<B2LightText design='font-normal'>
						{t('Footer.DesarrolladoPorIT')}
					</B2LightText>
				</a>
			</div>
		</AdminTemplate>
	);
}
