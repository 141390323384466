import React from 'react';
import { Link } from 'react-router-dom';
import ButtonSecondary from '../../atoms/button/ButtonSecondary';
import B1Text from '../../atoms/text/B1Text';
import GeneralTemplate from '../../templates/GeneralTemplate';

export default function ErrorPage() {
	return (
		<GeneralTemplate>
			<div className='flex flex-col items-center justify-center px-4'>
				<B1Text design='mt-12 mb-8'>404</B1Text>
				<B1Text design='mb-12'>la pagina que estas buscando no existe.</B1Text>
				<Link to='/'>
					<ButtonSecondary text='Homepage' />
				</Link>
			</div>
		</GeneralTemplate>
	);
}
