import React from 'react';
import InputChip from '../Input/InputChip';

export default function TagBar({
	sections,
	section,
	setSection,
	design,
	photo,
	sketch,
	sectionIndex,
	setSectionIndex,
	typeImage,
}) {
	return (
		<div className={design}>
			{sections.map((sectionName, index) => {
				if (typeImage === 'PROPOSED' && sectionName === 'Dibujo') return null;
				return (
					<InputChip
						key={index}
						index={index}
						sectionName={sectionName}
						section={section}
						setSection={setSection}
						photo={photo}
						sketch={sketch}
						sectionIndex={sectionIndex}
						setSectionIndex={setSectionIndex}
					/>
				);
			})}
		</div>
	);
}
