import {
	getAgents,
	getCategories,
	getColors,
	getKeyConcepts,
	getMaterials,
	getOccupationObjects,
	getOccupationTypes,
	getPermanence,
	getSpaces,
	getTemporalities,
} from '../redux/reducers/staticData';

const fetchGetData = (apiURL) => {
	const { token } = localStorage;
	return fetch(apiURL, {
		method: 'GET',
		cache: 'no-cache',
		headers: {
			content_type: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	}).then((res) => res.json());
};

const fetchPostData = (apiURL, status) => {
	const { token } = localStorage;
	return fetch(apiURL, {
		method: 'POST',
		cache: 'no-cache',
		body: JSON.stringify(status),
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}).then((res) => res.json());
};

const fetchPutData = async (apiURL, values) => {
	const { token } = localStorage;
	const resp = await fetch(apiURL, {
		method: 'PUT',
		cache: 'no-cache',
		body: JSON.stringify(values),
		headers: {
			content_type: 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	return await resp.json();
};

const fetchPostImage = async (values) => {
	const { token } = localStorage;
	let form = new FormData();
	form.append('small_file', values.small_file);
	form.append('medium_file', values.medium_file);
	form.append('large_file', values.large_file);
	form.append('organization_user_id', values.organization_user_id);
	form.append('name_image', values.name_image);
	form.append('description', values.name_image);
	form.append('type_id', values.type_id);
	form.append('type', values.type);
	const resp = await fetch('/api/proposed_image/upload_image_id/', {
		method: 'POST',
		body: form,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return await resp.json();
};
const fetchPostMultiMedia = async (values, fileType) => {
	const { token } = localStorage;
	let form = new FormData();
	form.append('large_file', values[fileType]);
	form.append('name_file', values[fileType].name);
	form.append(`${fileType}_id`, values.id);

	let url = '';
	if (fileType === 'architectural_image')
		url = `/api/architectural_image/upload_image/${values.id}`;
	if (fileType === 'video') url = `/api/video/upload_video/${values.id}`;
	if (fileType === 'sound') url = `/api/sound/upload_sound/${values.id}`;

	const resp = await fetch(url, {
		method: 'POST',
		body: form,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return await resp.json();
};

const fetchAttribute = (attribute, dispatch) => {
	if (attribute === 'space') {
		dispatch(getSpaces());
	}
	if (attribute === 'category') {
		dispatch(getCategories());
	}
	if (attribute === 'occupation_type') {
		dispatch(getOccupationTypes());
	}
	if (attribute === 'temporality') {
		dispatch(getTemporalities());
	}
	if (attribute === 'permanence') {
		dispatch(getPermanence());
	}
	if (attribute === 'agent') {
		dispatch(getAgents());
	}
	if (attribute === 'color') {
		dispatch(getColors());
	}
	if (attribute === 'material') {
		dispatch(getMaterials());
	}
	if (attribute === 'key_concept') {
		dispatch(getKeyConcepts());
	}
	if (attribute === 'occupation_object') {
		dispatch(getOccupationObjects());
	}
};

export {
	fetchGetData,
	fetchPostData,
	fetchPutData,
	fetchPostImage,
	fetchPostMultiMedia,
	fetchAttribute,
};
