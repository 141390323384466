import {
	FacebookShareButton,
	TwitterShareButton,
	LinkedinShareButton,
	WhatsappShareButton,
	EmailShareButton,
} from 'react-share';
import {
	FacebookIcon,
	TwitterIcon,
	EmailIcon,
	LinkedinIcon,
	WhatsappIcon,
} from 'react-share';

export function ShareSection({ title, source, summary, url, hashtags, quote }) {
	return (
		<div className='mb-4'>
			<div className='flex justify-center'>
				<div className='mr-4'>
					<FacebookShareButton
						url={url}
						quote={quote}
						hashtag={hashtags}
						description={summary}
						className='flex flex-row'
					>
						<FacebookIcon size={28} round />
					</FacebookShareButton>
				</div>
				<div className='mr-4'>
					<WhatsappShareButton
						title={title}
						source={source}
						summary={summary}
						url={url}
						className='flex flex-row'
					>
						<WhatsappIcon size={28} round />
					</WhatsappShareButton>
				</div>
				<div className='mr-4'>
					<EmailShareButton
						title={title}
						source={source}
						summary={summary}
						url={url}
						className='flex flex-row'
					>
						<EmailIcon size={28} round />
					</EmailShareButton>
				</div>
				<div className='mr-4'>
					<TwitterShareButton
						title={title}
						source={source}
						summary={summary}
						url={url}
						className='flex flex-row'
					>
						<TwitterIcon size={28} round />
					</TwitterShareButton>
				</div>
				<div className='mr-4'>
					<LinkedinShareButton
						title={title}
						source={source}
						summary={summary}
						url={url}
						className='flex flex-row'
					>
						<LinkedinIcon size={28} round />
					</LinkedinShareButton>
				</div>
			</div>
		</div>
	);
}
