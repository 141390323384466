import { Link } from 'react-router-dom';
import B1Text from '../../atoms/text/B1Text';
import { ReactComponent as Edit } from '../../assets/icon/Edit.svg';
import { ReactComponent as RightArrow } from '../../assets/icon/RightArrow.svg';
import { ReactComponent as Trash } from '../../assets/icon/Trash.svg';

export default function CategoriasLabel({
	title,
	url = '',
	type = 'Arrow',
	setAction,
}) {
	let showEdit = false;
	let showDelete = false;
	let showRightArrow = false;
	if (type === 'Arrow') {
		showEdit = false;
		showDelete = false;
		showRightArrow = true;
	} else if (type === 'Edit') {
		showEdit = true;
		showDelete = true;
		showRightArrow = false;
	} else if (type === 'All') {
		showEdit = true;
		showDelete = true;
		showRightArrow = true;
	}

	return (
		<div className='flex flex-row justify-between md:w-2/3 md:mx-auto'>
			<B1Text>{title}</B1Text>

			<div className='flex flex-row gap-4 md:gap-8'>
				{showEdit && (
					<Edit className='cursor-pointer' onClick={() => setAction('edit')} />
				)}
				{showDelete && (
					<Trash
						className='cursor-pointer'
						stroke='black'
						onClick={() => setAction('delete')}
					/>
				)}
				{showRightArrow && (
					<Link to={url}>
						<RightArrow className='cursor-pointer' />
					</Link>
				)}
			</div>
		</div>
	);
}
