import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// BL
import {
	tempEditImage,
	changeSection,
} from '../../../business_logic/redux/reducers/collection';

// UI
import { ReactComponent as Erase } from '../../assets/icon/Erase.svg';
import B1Text from '../../atoms/text/B1Text';

export default function ImageDescription({
	id,
	imagen,
	name,
	redirect,
	section,
	newCollectionState,
	showDescription = false,
	setShowPopUpEdit,
}) {
	const dispatch = useDispatch();
	return (
		<div
			id={id}
			className='flex flex-col relative'
			onClick={() => {
				if (section === 'collection') {
					dispatch(changeSection('collectionImages'));
				} else if (section === 'home') {
					dispatch(changeSection('homeimages'));
				}
			}}
		>
			<Link
				to={redirect ? `${redirect}${id}` : ''}
				className={`h-60 md:h-28 xl:h-36 ${!redirect && 'cursor-default'} ${
					section !== 'home' && 'sm:h-72'
				}`}
			>
				{newCollectionState === 'EDIT' && (
					<Erase
						className='absolute right-0 top-0 z-10'
						onClick={() => {
							dispatch(tempEditImage({ id: id, list: 'Images delete' }));
							setShowPopUpEdit(true);
						}}
					/>
				)}
				<LazyLoadImage
					alt={name}
					src={`${imagen}_medium_compressed`}
					effect='blur'
					width='100%'
					height='100%'
					className='h-full w-full bg-center'
					wrapperClassName={`flex items-center`}
					style={{ objectFit: 'cover', aspectRatio: '1/1' }}
				/>
			</Link>
			{showDescription && (
				<div className='h-8'>
					<B1Text design='font-normal'>{name}</B1Text>
				</div>
			)}
		</div>
	);
}
