import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

// BL

// UI
import B1Text from '../../atoms/text/B1Text';
import H1Principal from '../../atoms/text/H1Principal';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import GeneralTemplate from '../../templates/GeneralTemplate';

export default function About() {
	const { t } = useTranslation();

	return (
		<GeneralTemplate
			section='about'
			menuVisitor={true}
			addImageIcon={false}
			showSecondNavbar={true}
			showFilter={false}
		>
			<div className='w-full px-8'>
				<B1Text design='lg:pl-8 text-azul-50'>{t('AcercaDe.acercaDe')}</B1Text>
				<B1Text design='font-light mt-4 mb-4 lg:px-20'>
					{t('AcercaDe.s1p1')}
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20'>{t('AcercaDe.s1p2')}</B1Text>
				<B1Text design='font-light mb-4 lg:px-20'>
					<Trans i18nKey='AcercaDe.s1p3'>
						texto<sup>1</sup>texto
					</Trans>
				</B1Text>

				<H1Principal design='mt-8 mb-4 text-azul-50 lg:pl-8'>
					{t('AcercaDe.t2')}
				</H1Principal>
				<B1Text design='font-light mb-2 lg:px-20'>{t('AcercaDe.s2p1')}</B1Text>
				<B1Text design='font-light mb-2 lg:px-20'>{t('AcercaDe.s2p1a')}</B1Text>
				<B1Text design='font-light mb-2 lg:px-20'>{t('AcercaDe.s2p1b')}</B1Text>
				<B1Text design='font-light mb-2 lg:px-20'>{t('AcercaDe.s2p1c')}</B1Text>
				<B1Text design='font-light mb-2 lg:px-20'>{t('AcercaDe.s2p1d')}</B1Text>

				<div className='flex flex-col lg:grid lg:grid-cols-3 lg:gap-4 lg:px-20 lg:mt-12'>
					<div className='flex flex-col'>
						<div className='flex flex-col '>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t3')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s3p1')}</B1Text>
						</div>
						<div className='flex flex-col'>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t4')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s4p1')}</B1Text>
						</div>
						<div className='flex flex-col'>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t5')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s5p1')}</B1Text>
						</div>
					</div>

					<div className='flex flex-col'>
						<div className='flex flex-col'>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t6')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s6p1')}</B1Text>
						</div>
						<div className='flex flex-col '>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t7')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s7p1')}</B1Text>
						</div>
						<div className='flex flex-col'>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t8')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s8p1')}</B1Text>
						</div>
					</div>

					<div className='flex flex-col'>
						<div className='flex flex-col'>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t9')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s9p1')}</B1Text>
						</div>

						<div className='flex flex-col'>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t10')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s10p1')}</B1Text>
						</div>

						<div className='flex flex-col'>
							<B1Text design='font-semibold mt-2 mb-6'>
								{t('AcercaDe.t11')}
							</B1Text>
							<B1Text design='font-light mb-4'>{t('AcercaDe.s11p1')}</B1Text>
						</div>
					</div>
				</div>

				<B1Text design='font-semibold mt-8 mb-8 lg:px-20 lg:mt-12 text-azul-50 text-center'>
					{t('AcercaDe.t12')}
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20 lg:mt-12'>
					<Trans i18nKey='AcercaDe.s12p1'>
						text<sup>1</sup>text<sup>text</sup>
					</Trans>
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20 lg:mt-12'>
					{t('AcercaDe.s12p2')}
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20 lg:mt-12'>
					<Trans i18nKey='AcercaDe.s12p3'>
						text<sup>1</sup>
					</Trans>
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20 lg:mt-12'>
					{t('AcercaDe.s12p4')}
				</B1Text>

				<B1Text design='font-semibold my-8 lg:px-20 lg:mt-12 text-azul-50 text-center'>
					{t('AcercaDe.t13')}
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20 lg:mt-12'>
					{t('AcercaDe.s13p1')}
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20 lg:mt-12'>
					<Trans i18nKey='AcercaDe.s13p2'>
						texto
						<span className='italic'>link</span>
						texto
						<a
							className='text-azul-50 underline'
							href='https://derivelab.org/'
							target='_blank'
							rel='noopener noreferrer'
						>
							Instagram
						</a>
					</Trans>
				</B1Text>
				<B1Text design='font-light mb-4 lg:px-20 lg:mt-12'>
					<Trans i18nKey='AcercaDe.s13p3'>
						texto
						<a
							className='text-azul-50 underline'
							href='mailto:ximena@derivelab.org'
							target={'_blank'}
							rel='noreferrer'
						>
							link
						</a>
						texto
						<a
							className='text-azul-50 underline'
							href='https://www.instagram.com/otrasmanerasdeocuparelespacio/'
							target='_blank'
							rel='noopener noreferrer'
						>
							Instagram
						</a>
					</Trans>
				</B1Text>
				{/* Download all sketches in DWG - below */}
				{/* <div className='flex w-full h-8 lg:px-20 items-center mt-8 mb-4'>
					<B1Text design='w-4/5 font-normal text-left'>
						{t('AcercaDe.descargaTodos')}
					</B1Text>
				</div> */}
				{/* <div className='w-full flex justify-center'>
					<ButtonPrincipal text={t('AcercaDe.descargar')} />
				</div> */}
				<B1Text design='font-semibold my-8 lg:px-20 lg:mt-12 text-azul-50 text-center'>
					{t('AcercaDe.titleFooter')}
				</B1Text>
				<B1Text design='font-light lg:px-20 mb-4'>
					<Trans i18nKey='AcercaDe.footert1'>
						<sup className='text-azul-50'>link</sup>texto
						<span className='italic'>link</span>texto
					</Trans>
				</B1Text>
				<B1Text design='font-light lg:px-20 mb-4'>
					<Trans i18nKey='AcercaDe.footert2'>
						<sup className='text-azul-50'>link</sup>texto
						<span className='italic'>link</span>texto
					</Trans>
				</B1Text>
				<B1Text design='font-light lg:px-20 mb-4'>
					<Trans i18nKey='AcercaDe.footert3'>
						<sup className='text-azul-50'>link</sup>texto
						<span className='italic'>link</span>texto
					</Trans>
				</B1Text>
				<B1Text design='font-light lg:px-20 mb-4'>
					<Trans i18nKey='AcercaDe.footert4'>
						<sup className='text-azul-50'>link</sup>texto
						<span className='italic'>link</span>texto
					</Trans>
				</B1Text>
			</div>
		</GeneralTemplate>
	);
}
