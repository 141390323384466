import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

// BL
import {
	fetchPostMultiMedia,
	fetchPostData,
} from '../../../../business_logic/helpers/fetchHooks';
import { ENDPOINTS_ELEMENT } from '../../../../business_logic/helpers/routes';
import { DivideByLevels } from '../../../../business_logic/helpers/CategoriesDividedByLevels';
import {
	getDraftElements,
	getPublishedElements,
} from '../../../../business_logic/redux/reducers/elementsSlice';
//UI
import ButtonPrincipal from '../../../atoms/button/ButtonPrincipal';
import InputBox from '../../../molecules/Input/InputBox';
import InputBoxDropdown from '../../../molecules/Input/InputBoxDropdown';
import TitleH1 from '../../../molecules/Title/TitleH1';
import TituloFotoText from '../../../organisms/formUpload/TituloFotoText';
import InputBoxCheckbox from '../../../molecules/Input/InputBoxCheckbox';
import B1Text from '../../../atoms/text/B1Text';
import TextAreaBox from '../../../molecules/Input/TextAreaBox';

export default function FichaEditForm({
	typeImage,
	singleElement,
	countries,
	locations,
	spaces,
	occupationTypes,
	temporalities,
	permanence,
	agents,
	categories,
	colors,
	material,
	keyConcepts,
	occupationObjects,
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const spacesLevelsObject = DivideByLevels(spaces);
	const activitiesLevelsObject = DivideByLevels(categories);

	const returnExactElementByLevel = (elements, level) => {
		if (!elements || elements.length < 1) return '';
		let result = '';
		elements.find((element) => {
			if (element['nivel'] === level) {
				result = element['label'];
			}
			return null;
		});
		return result;
	};

	const initialValuesFormik = {
		name: singleElement.name ?? '',
		description: singleElement.description ?? '',
		country_name: singleElement.country_name ?? '',
		location: singleElement.location ?? '',
		code: singleElement.code ?? '',
		year: singleElement.year ?? '',
		agent: singleElement.agent ?? '',
		temporality: singleElement.temporality ?? '',
		occupation_type: singleElement.occupation_type ?? '',
		permanence: singleElement.permanence ?? '',
		id: singleElement.id,

		image: {},
		architectural_image: {},
		video: {},
		sound: {},
		image_id: singleElement.image_id ?? '',
		architectural_image_id: singleElement.architectural_image_id ?? '',
		sound_id: singleElement.sound_id ?? '',
		video_id: singleElement.video_id ?? '',

		material:
			singleElement.material && singleElement.material[0] !== false
				? singleElement.material
				: [''],
		color:
			singleElement.color && singleElement.color[0] !== false
				? singleElement.color
				: [''],
		key_concepts:
			singleElement.key_concepts && singleElement.key_concepts[0] !== false
				? singleElement.key_concepts
				: [''],
		occupation_objects:
			singleElement.occupation_objects &&
			singleElement.occupation_objects[0] !== false
				? singleElement.occupation_objects
				: [''],

		spaceOne: returnExactElementByLevel(singleElement.spaces_by_level, 'one'),
		spaceTwo: returnExactElementByLevel(singleElement.spaces_by_level, 'two'),
		spaceThree: returnExactElementByLevel(
			singleElement.spaces_by_level,
			'three'
		),
		activityOne: returnExactElementByLevel(
			singleElement.categories_by_level,
			'one'
		),
		activityTwo: returnExactElementByLevel(
			singleElement.categories_by_level,
			'two'
		),
		activityThree: returnExactElementByLevel(
			singleElement.categories_by_level,
			'three'
		),

		checkBoxStatusIsPublished: singleElement.status === 'PUBLISHED',
		architectural_imageCheckBoxToDownload: true,
		videoCheckBoxToDownload: true,
		sonidoCheckBoxToDownload: true,
	};

	const postMedia = async (values) => {
		if (values.architectural_image.name) {
			await fetchPostMultiMedia(values, 'architectural_image').then((res) => {
				values = {
					...values,
					architectural_image_id: res.id,
				};
			});
		}
		if (values.video.name) {
			await fetchPostMultiMedia(values, 'video').then((res) => {
				values = {
					...values,
					video_id: res.id,
				};
			});
		}
		if (values.sound.name) {
			await fetchPostMultiMedia(values, 'sound').then((res) => {
				values = {
					...values,
					sound_id: res.id,
				};
			});
		}
		return values;
	};

	return (
		<div>
			<Formik
				enableReinitialize={true}
				initialValues={initialValuesFormik}
				validationSchema={Yup.object({})}
				onSubmit={async (values) => {
					setIsSubmitting(true);
					values = {
						...values,
						status: values.checkBoxStatusIsPublished ? 'PUBLISHED' : 'DRAFT',
						spaces_by_level: [
							{ label: values.spaceOne, nivel: 'one' },
							{ label: values.spaceTwo, nivel: 'two' },
							{ label: values.spaceThree, nivel: 'three' },
						],
						categories_by_level: [
							{ label: values.activityOne, nivel: 'one' },
							{ label: values.activityTwo, nivel: 'two' },
							{ label: values.activityThree, nivel: 'three' },
						],
					};

					try {
						await postMedia(values)
							.then((finalValues) =>
								fetchPostData(
									`${ENDPOINTS_ELEMENT.PUT_ELEMENT}${singleElement.id}`,
									finalValues
								)
							)
							.then(async (res) => {
								await dispatch(getPublishedElements());
								await dispatch(getDraftElements());
							})
							.finally(() => navigate(`/admin/${typeImage.toLowerCase()}`));
					} catch (error) {
						console.error(error);
					}
					setIsSubmitting(false);
				}}
			>
				{(formProps) => (
					<Form>
						<TitleH1>Datos de la ocupación</TitleH1>
						<div className='flex flex-col gap-4 md:flex-row md:gap-12 md:mb-16'>
							<div className='flex flex-col gap-1 w-full md:w-1/2'>
								<B1Text>Nombre</B1Text>
								<InputBox dbName='name' />
							</div>
							<div className='flex flex-col gap-1 w-full md:w-1/2'>
								<B1Text>Código</B1Text>
								<InputBox dbName='code' />
							</div>
						</div>
						<TitleH1>Elemento de ocupación</TitleH1>
						<div className='lg:flex lg:flex-col lg:gap-10 lg:justify-center w-full items-center'>
							<div className='lg:flex lg:flex-row lg:w-full lg:gap-10'>
								<div className='lg:flex lg:flex-col lg:w-full'>
									<TituloFotoText
										action='edit'
										title1={'Fotografía'}
										buttonText='Agregar Imagen'
										url={`${singleElement.image_url}_medium_compressed`}
									/>
									<hr className='mt-8 w-full border-black mb-10'></hr>
								</div>

								<div className='lg:flex lg:flex-col lg:w-full'>
									<TituloFotoText
										dbNameMedia='architectural_image'
										title1={'Dibujo arquitectónico'}
										title2={'Requerido'}
										buttonText='Agregar Dibujo'
										url={`${singleElement.architectural_image_url}`}
										action='edit'
										formProps={formProps}
									/>
									<hr className='mt-8 w-full border-black mb-10'></hr>
								</div>
							</div>

							<div className='lg:flex lg:flex-row lg:w-full lg:gap-10'>
								{/* <div className='lg:flex lg:flex-col lg:w-full'>
									<TituloFotoText
										dbNameMedia={'video'}
										title1={'Video'}
										title2={'Opcional'}
										buttonText='Agregar Video'
										url={singleElement.video_url}
										formProps={formProps}
										acceptedFormats={'.mp4'}
									/>
									<hr className='mt-8 w-full border-black mb-10'></hr>
								</div> */}
								{/* <div className='lg:flex lg:flex-col lg:w-1/2 mx-auto lg:mb-6'>
									<TituloFotoText
										dbNameMedia={'sound'}
										title1={'Sonido'}
										title2={'Opcional'}
										buttonText='Agregar Sonido'
										url={singleElement.sound_url}
										formProps={formProps}
										acceptedFormats={'.mp3'}
									/>
									<hr className='mt-8 w-full border-black mb-10'></hr>
								</div> */}
							</div>
						</div>
						<TitleH1>Descripción</TitleH1>
						<div className='flex flex-col gap-1'>
							<B1Text>Descripción</B1Text>
							<TextAreaBox
								dbName='description'
								design='font-normal text-base md:text-lg'
							/>
						</div>
						<TitleH1>Categorización</TitleH1>
						<div className='flex flex-col md:flex-row md:gap-10 justify-center w-full'>
							<div className='flex flex-col md:grid md:grid-cols-2 md:gap-8 w-full gap-4'>
								<div className='flex flex-col gap-1'>
									<B1Text>Año</B1Text>
									<InputBox dbName='year' fieldType={'number'} />
								</div>
								<InputBoxDropdown
									label='País'
									dbName='country_name'
									selectValues={countries}
								/>
								<InputBoxDropdown
									label='Ubicación geográfica'
									dbName='location'
									selectValues={locations}
								/>
								<InputBoxDropdown
									label='Espacio'
									dbName='spaceOne'
									selectValues={spacesLevelsObject['1']?.sort((a, b) =>
										a.label.localeCompare(b.label)
									)}
									hasLevels={true}
								/>
								{spacesLevelsObject['2'] && formProps.values.spaceOne && (
									<InputBoxDropdown
										label='Sub Espacio'
										dbName='spaceTwo'
										parentLevel={'1'}
										parentValue={'spaceOne'}
										selectValues={spacesLevelsObject['2']}
										formProps={formProps}
										allLevelsObject={spacesLevelsObject}
									/>
								)}
								{spacesLevelsObject['3'] &&
									formProps.values.spaceOne &&
									formProps.values.spaceTwo && (
										<InputBoxDropdown
											label='Sub Espacio 2'
											dbName='spaceThree'
											parentLevel={'2'}
											parentValue={'spaceTwo'}
											selectValues={spacesLevelsObject['3']}
											formProps={formProps}
											allLevelsObject={spacesLevelsObject}
										/>
									)}

								<InputBoxDropdown
									label='Ocupación'
									dbName='occupation_type'
									selectValues={occupationTypes}
								/>

								<InputBoxDropdown
									label='Temporalidad'
									dbName='temporality'
									selectValues={temporalities}
								/>

								<InputBoxDropdown
									label='Permanencia'
									dbName='permanence'
									selectValues={permanence}
								/>

								<InputBoxDropdown
									label='Agentes'
									dbName='agent'
									selectValues={agents}
								/>

								<InputBoxDropdown
									label='Actividad'
									dbName='activityOne'
									selectValues={activitiesLevelsObject['1']?.sort((a, b) =>
										a.label.localeCompare(b.label)
									)}
								/>
								{activitiesLevelsObject['2'] &&
									formProps.values.activityOne && (
										<InputBoxDropdown
											label='Actividad 2'
											dbName='activityTwo'
											selectValues={activitiesLevelsObject['2']}
											formProps={formProps}
											parentLevel={'1'}
											parentValue={'activityOne'}
											allLevelsObject={activitiesLevelsObject}
										/>
									)}
								{activitiesLevelsObject['3'] &&
									formProps.values.activityOne &&
									formProps.values.activityTwo && (
										<InputBoxDropdown
											label='Actividad 3'
											dbName='activityThree'
											selectValues={activitiesLevelsObject['3']}
											formProps={formProps}
											parentLevel={'2'}
											parentValue={'activityTwo'}
											allLevelsObject={activitiesLevelsObject}
										/>
									)}
							</div>
						</div>
						<div className='mt-4 flex flex-col md:grid md:grid-cols-2 gap-4 md:gap-8'>
							<InputBoxDropdown
								label='Color'
								dbName='color'
								selectValues={colors}
								multiple={true}
							/>

							<InputBoxDropdown
								label='Material'
								dbName='material'
								selectValues={material}
								multiple={true}
							/>
							<InputBoxDropdown
								label='Objetos de ocupación'
								dbName='occupation_objects'
								selectValues={occupationObjects}
								multiple={true}
							/>

							<InputBoxDropdown
								label='Conceptos clave'
								dbName='key_concepts'
								selectValues={keyConcepts}
								multiple={true}
							/>
						</div>

						<InputBoxCheckbox
							label='Habilitar publicación'
							dbName='checkBoxStatusIsPublished'
							design={'pt-8 my-auto w-full'}
						/>
						<div className='flex flex-row w-full justify-center'>
							<div className='flex flex-row w-full justify-between md:justify-around md:w-1/2 mt-4'>
								<ButtonPrincipal
									text='Cancelar'
									type='Outline'
									typeButton='button'
									action={() => {
										navigate(`/admin/${typeImage.toLowerCase()}`);
									}}
								/>
								<ButtonPrincipal
									text='Guardar'
									type='Filled'
									typeButton='submit'
									disabled={isSubmitting}
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
}
