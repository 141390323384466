import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';

// BL
import { store } from './business_logic/redux/store';
import {
	PrivateRoute,
	PublicRoute,
} from './UI/pages/admin/authentication/LoginUtils';
import {
	ENDPOINTS_ELEMENT,
	ENDPOINTS_PROPOSED_IMAGE,
	ENDPOINTS_COLLECTIONS,
} from './business_logic/helpers/routes';

//UI
import Login from './UI/pages/admin/authentication/Login';
import HomePage from './UI/pages/Homepage/HomePage';
import MyCollection from './UI/pages/collection/MyCollection';
import FichaEdit from './UI/pages/admin/ficha/FichaEdit';
import Configuration from './UI/pages/admin/configuration/Configuration';
import SendPhoto from './UI/pages/SendPhoto/SendPhoto';
import SingleImage from './UI/pages/collection/SingleImage';
import About from './UI/pages/Menu/About';
import NewCollection from './UI/pages/collection/NewCollection';
import ConfigurationSingle from './UI/pages/admin/configuration/ConfigurationSingle';
import NewCollectionEdit from './UI/pages/collection/NewCollectionEdit';
import SelectionImages from './UI/pages/collection/SelectionImages';
import Profile from './UI/pages/admin/profile/Profile';
import ConfigurationActivity from './UI/pages/admin/configuration/ConfigurationActivity';
import Legal from './UI/pages/Menu/Legal';
import SingleCollectionView from './UI/pages/collection/SingleCollectionView';
import ConfigurationSpace from './UI/pages/admin/configuration/ConfigurationSpace';
import ErrorPage from './UI/pages/404/ErrorPage';

export default function App() {
	return (
		<div>
			<Provider store={store}>
				<BrowserRouter>
					<Routes>
						<Route
							path='/'
							element={
								<HomePage
									redirect={ENDPOINTS_ELEMENT.REDIRECT_ELEMENT}
									status='PUBLISHED'
								/>
							}
						/>
						<Route
							path='/image/:id'
							element={
								<SingleImage
									url={ENDPOINTS_ELEMENT.GET_ELEMENT}
									typeImage='PUBLISHED'
								/>
							}
						/>
						<Route path='/about' element={<About />} />
						<Route path='/legal' element={<Legal />} />

						<Route path='/my-collection' element={<MyCollection />} />
						<Route path='/new-collection' element={<NewCollection />} />
						<Route
							path='/new-collection/edit'
							element={<NewCollectionEdit />}
						/>
						<Route
							path='/new-collection/edit/selection_images'
							element={
								<SelectionImages
									url={ENDPOINTS_ELEMENT.GET_IMAGES_BY_STATUS}
									redirect={ENDPOINTS_PROPOSED_IMAGE.REDIRECT_DRAFT_IMAGES}
									status='PUBLISHED'
								/>
							}
						/>
						<Route
							path='/collection/:id'
							element={
								<SingleCollectionView
									url={ENDPOINTS_COLLECTIONS.GET_SINGLE_COLLECTION}
									redirect={ENDPOINTS_ELEMENT.REDIRECT_ELEMENT}
									status='PUBLISHED'
									menuVisitor={true}
								/>
							}
						/>

						<Route
							exact
							path='/send-photo'
							element={<SendPhoto menuVisitor={true} />}
						/>
						<Route path='/404' element={<ErrorPage />} />
						<Route
							exact
							path='/admin'
							element={
								<PrivateRoute>
									<Profile />
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/login'
							element={
								<PublicRoute>
									<Login />
								</PublicRoute>
							}
						/>
						<Route
							exact
							path='/admin/profile'
							element={
								<PrivateRoute>
									<Profile />
								</PrivateRoute>
							}
						/>

						<Route
							path='/admin/categories'
							element={
								<PrivateRoute>
									<Configuration />
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/categories/:attributeName'
							element={
								<PrivateRoute>
									<ConfigurationSingle />
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/categories/activity/:parentId'
							element={
								<PrivateRoute>
									<ConfigurationActivity />
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/categories/space/:parentId'
							element={
								<PrivateRoute>
									<ConfigurationSpace />
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/published'
							element={
								<PrivateRoute>
									<HomePage
										redirect={'/admin/published/'}
										status='PUBLISHED'
										menuVisitor={false}
										menuAdmins={true}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/proposed'
							element={
								<PrivateRoute>
									<HomePage
										redirect={ENDPOINTS_PROPOSED_IMAGE.REDIRECT_PROPOSED_IMAGES}
										status='SUBMITTED'
										menuVisitor={false}
										menuAdmins={true}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/draft'
							element={
								<PrivateRoute>
									<HomePage
										url={ENDPOINTS_ELEMENT.GET_IMAGES_BY_STATUS}
										redirect={ENDPOINTS_PROPOSED_IMAGE.REDIRECT_DRAFT_IMAGES}
										status='DRAFT'
										menuVisitor={false}
										menuAdmins={true}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/proposed/:id'
							element={
								<PrivateRoute>
									<SingleImage
										url={ENDPOINTS_PROPOSED_IMAGE.GET_PROPOSED_IMAGE}
										typeImage='PROPOSED'
										menuVisitor={false}
										menuAdmins={true}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/published/:id'
							element={
								<PrivateRoute>
									<FichaEdit
										url={ENDPOINTS_ELEMENT.GET_ELEMENT}
										typeImage='PUBLISHED'
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path='/admin/draft/:id'
							element={
								<PrivateRoute>
									<FichaEdit
										url={ENDPOINTS_ELEMENT.GET_ELEMENT}
										typeImage='DRAFT'
									/>
								</PrivateRoute>
							}
						/>
						<Route path='*' element={<Navigate to='/' />} />
					</Routes>
				</BrowserRouter>
			</Provider>
		</div>
	);
}
