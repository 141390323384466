import React from 'react';

export default function ButtonSecondary({ icon, design, onClickAction }) {
	return (
		<button
			onClick={onClickAction}
			className={`rounded bg-white  w-10 h-10 active:bg-blue-200 flex justify-center items-center ${design}`}
		>
			{icon}
		</button>
	);
}
