// third party
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// BL
import {
	addValueToFilters,
	removeValueFromFilter,
} from '../../../../business_logic/helpers/filterHelpers';

// UI
import B1Text from '../../../atoms/text/B1Text';

export default function ClickableChipLevels({
	label,
	valueToFilter,
	currentLevel,
	allLevelsChips,
	parentId,
}) {
	const { i18n } = useTranslation();
	const dispatch = useDispatch();

	const { filterArray, arrayVariables } = useSelector((state) => state.filter);

	const [isClicked, setIsClicked] = useState(false);

	const [value, setValue] = useState('');

	let levelData = allLevelsChips[currentLevel]?.filter(
		(element) => element.parent_id === parentId
	);

	useEffect(() => {
		let valueIsFiltered = arrayVariables.find((element) => element === label);
		if (valueIsFiltered) {
			setIsClicked(true);
		} else {
			setIsClicked(false);
		}
	}, [label, arrayVariables]);

	return (
		<>
			<div
				className={`flex flex-row gap-1 items-center cursor-pointer max-w-max hover:text-azul-50 ${
					isClicked && 'text-azul-50'
				}`}
				onClick={() => {
					setValue(label);
					isClicked
						? removeValueFromFilter(
								filterArray,
								arrayVariables,
								valueToFilter,
								label,
								dispatch
						  )
						: addValueToFilters(
								filterArray,
								arrayVariables,
								valueToFilter,
								label,
								dispatch
						  );
					setIsClicked(!isClicked);
				}}
			>
				<div className={`w-3 h-3 rounded-full ${isClicked && 'bg-azul-50'}`} />
				<B1Text>{label}</B1Text>
			</div>

			{levelData?.length > 0 && (
				<div className='flex flex-col gap-1 pl-3'>
					{value !== '' &&
						isClicked &&
						levelData.map((val, index) => (
							<ClickableChipLevels
								key={index}
								label={i18n.language === 'es' ? val.label : val.label_en}
								valueToFilter={valueToFilter}
								allLevelsChips={allLevelsChips}
								currentLevel={currentLevel + 1}
								parentId={val.id}
							/>
						))}
				</div>
			)}
		</>
	);
}
