import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_PROFILE } from '../../helpers/routes';
import { fetchGetData, fetchPutData } from '../../helpers/fetchHooks';

export const getProfile = createAsyncThunk('profile/fetchProfile', async () => {
	return await fetchGetData(`${ENDPOINTS_PROFILE.GET_PROFILE}1/`);
});

export const editProfile = createAsyncThunk(
	'profile/editProfile',
	async (values) => {
		return await fetchPutData(ENDPOINTS_PROFILE.EDIT_PROFILE, values);
	}
);

const initialState = {
	profile: null,
	app_user: null,
	profileChange: 1,
};

export const ProfileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setAppUser: (state, { payload }) => {
			state.app_user = payload;
		},
	},
	extraReducers: {
		/* get profile */
		[getProfile.pending]: (state, action) => {
			state.status = 'loading';
		},
		[getProfile.fulfilled]: (state, { payload }) => {
			state.profile = payload;
			state.status = 'success';
		},
		[getProfile.rejected]: (state, action) => {
			state.status = 'failed';
		},

		/*Edit Profile*/
		[editProfile.pending]: (state, action) => {
			state.status = 'loading';
		},
		[editProfile.fulfilled]: (state, { payload }) => {
			state.status = 'success';
			state.profileChange += 1;
		},
		[editProfile.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});
export const { setAppUser } = ProfileSlice.actions;

export default ProfileSlice.reducer;
