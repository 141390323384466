import temp from '../../UI/assets/temp.png';
import temphd from '../../UI/assets/temphd.png';

const galerydata = [
	{
		url: temp,
		urlhd: temphd,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 1,
		imageDescription:
			'Una sombrilla con base y una banca metálica colocadas en la plaza frente a un local comercial.',
		filterTags: [
			{ año: 2021 },
			{ pais: 'México' },
			{ ubicacion: 'Ciudad de México' },
			{ espacio: 'Via urbana, Carretera' },
			{ ocupacion: 'Solo cuerpo, Ocasional' },
			{ permanencia: 'Movil' },
			{ agentes: 'Grupal o colectivo' },
			{
				actividad: [
					{
						levelOne: 'Ocio',
						levelTwo: 'Descansar o dormir',
						levelThree: 'Protegerse del clima',
					},
				],
			},
			{
				atributos_fisicos: [
					{ Color: ['Naranja'] },
					{ Material: ['Solo cuerpo'] },
				],
			},
			{ objetos_de_ocupacion: 'Varios' },
			{
				conceptos_clave:
					'Descanso, bajo la sombra, carretera, puente vehicular, trabajadores',
			},
			{ temporalidad: 'Ocasional' },
		],
		actividadLogic: [
			{ level: 'one', label: 'Ocio' },
			{ level: 'two', label: 'Protegerse del clima' },
			{ level: 'three', label: 'ir a la playa' },
			{ level: 'three', label: 'ir al gym' },
			{ level: 'two', label: 'creer en el multinivel' },
			{ level: 'three', label: 'invertir en herbalife' },
			{ level: 'four', label: 'creer en NTFS' },
			{ level: 'five', label: 'el back es mi pasion' },
		],
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 2,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 3,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 4,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 5,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 6,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 7,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 8,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 9,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 10,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 11,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 12,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 13,
	},
	{
		url: temp,
		name: 'my collect pic',
		description: 'Colección 123456789',
		id: 14,
	},
];

const tagBarSections = ['Fotografía', 'Dibujo', 'Video', 'Sonido'];
const tagBarSectionsEn = ['Photo', 'Sketch', 'Video', 'Sound'];

const filterTagsInitial = {
	Año: 'A',
	Pais: 'P',
	Ubicación: 'U',
	Espacio: 'E',
	'Tipo de Ocupación': 'O',
	Permanencia: 'P',
	Agentes: 'AG',
	Actividad: 'AC',
	'Atributos Físicos': 'AF',
	'Objetos de Ocupación': 'OO',
	'Conceptos Clave': 'CC',
	Temporalidad: 'T',
};

const aboutText =
	'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla fermentum, sagittis convallis purus tortor massa. Laoreet tincidunt cursus in dolor in. Nisl, dolor, ut posuere consequat. Dignissim vivamus nisi integer felis pellentesque quisque quis justo pellentesque. Ultricies mattis purus tortor pretium ac neque. ';
const aboutTextTwo =
	'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla fermentum, sagittis convallis purus tortor massa. Laoreet tincidunt cursus in dolor in. Nisl, dolor, ut posuere consequat. ';
const aboutTextDown = 'Descarga todos los dibujos arquitectónicos en DWG';

export {
	galerydata,
	tagBarSections,
	filterTagsInitial,
	aboutText,
	aboutTextTwo,
	aboutTextDown,
	tagBarSectionsEn,
};
