import React from 'react';
import { ReactComponent as Check } from '../../assets/icon/Check.svg';
import { ReactComponent as Cross } from '../../assets/icon/X.svg';
import B1Text from '../../atoms/text/B1Text';
import { useDispatch, useSelector } from 'react-redux';
import {
	clearImagesList,
	clearCounter,
	changeCollectionState,
} from '../../../business_logic/redux/reducers/collection';
import { useNavigate } from 'react-router-dom';

export default function NavbarSelectImage({ navBarFiles, section }) {
	const dispatch = useDispatch();
	const { countSelect } = useSelector((state) => state.mycollection);
	const navigate = useNavigate();

	return (
		<div
			className={
				section === 'selectionImages'
					? 'flex flex-row justify-around md:justify-center items-center gap-4 md:gap-10 w-full px-4 pb-2 mb-4 fixed bg-white z-[6] mt-12'
					: 'flex flex-row justify-around md:justify-center items-center gap-4 md:gap-10 w-full px-4 mb-4'
			}
		>
			<B1Text design=''>{`${countSelect} ${navBarFiles}`}</B1Text>
			<div className='flex flex-row gap-4'>
				<Cross
					className='cursor-pointer'
					onClick={() => {
						dispatch(clearImagesList());
						dispatch(clearCounter(0));
					}}
				/>
				<Check
					className='cursor-pointer'
					onClick={() => {
						dispatch(changeCollectionState('PROPOSED'));
						setTimeout(() => {
							navigate('/new-collection/edit');
						}, 500);
					}}
				/>
			</div>
		</div>
	);
}
