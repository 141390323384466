import ImageUploader from 'react-images-upload';
import Compressor from 'compressorjs';

const handleCompressedUpload = (file, formProps, dbName) => {
	const image = file;
	new Compressor(image, {
		quality: 0.8,
		success: (compressedResult) => {
			!dbName && formProps.setFieldValue('large_file', compressedResult);
			dbName && formProps.setFieldValue(dbName, compressedResult);
		},
	});
};

export default function ImageUpload({ formProps, buttonText, dbName }) {
	return (
		<ImageUploader
			withIcon={false}
			buttonText={buttonText}
			buttonClassName='addImageButton B1Text'
			imgExtension={['.jpg', '.gif', '.png', 'jpeg']}
			maxFileSize={50242880}
			fileSizeError={' file size is too big'}
			fileTypeError={' is not supported file extension'}
			withPreview={true}
			singleImage={true}
			showLabel={false}
			label={''}
			className='p-4 w-full sm:w-4/5 lg:w-full my-auto'
			onChange={async (event) => {
				const file = event[0];
				try {
					handleCompressedUpload(file, formProps, dbName);
					formProps.setFieldValue('name_image', file.name);
				} catch (error) {
					console.error(error);
					formProps.setFieldValue(dbName, {});
					formProps.setFieldValue('large_file', {});
					formProps.setFieldValue('name_image', '');
				}
			}}
		/>
	);
}
