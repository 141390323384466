// THIRD PARTY
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// BL
import {
	cleanAllFilters,
	filterDisplay,
	setNameFilter,
} from '../../../business_logic/redux/reducers/filter';
import { AllFiltersInElements } from '../../../business_logic/helpers/filterHelpers';

// IU
import { ReactComponent as Cross } from '../../assets/icon/X.svg';
import { ReactComponent as Search } from '../../assets/icon/Search.svg';
import { ReactComponent as RightFilter } from '../../assets/icon/RightFilter.svg';
import B1Text from '../../atoms/text/B1Text';
import { useEffect, useState } from 'react';

const FilterHeader = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { filterArray, nameFilter } = useSelector((state) => state.filter);
	const { elements } = useSelector((state) => state.elements);

	function cleanFilters() {
		dispatch(cleanAllFilters());
	}

	const [currentElements, setCurrentElements] = useState([]);

	useEffect(() => {
		if (elements) setCurrentElements(elements);
	}, [elements]);

	return (
		<div className='w-full flex flex-col gap-4'>
			<Cross
				className='cursor-pointer min-h-full'
				onClick={() => dispatch(filterDisplay(false))}
			/>

			<form className='flex flex-row justify-between w-full relative'>
				<Search className='absolute top-1 left-1' />
				<input
					className='B1Text w-full rounded bg-white border border-azul-50 active:bg-white focus:border-azul-50 py-2 px-8 lg:px-10'
					placeholder={` ${t('Filtro.buscar')}`}
					type='text'
					value={nameFilter}
					onChange={(e) => dispatch(setNameFilter(e.target.value))}
				/>
				<RightFilter className='absolute top-2.5 right-2.5' />
			</form>

			<div className='flex flex-row justify-between'>
				<B1Text design='font-normal'>
					{`${
						AllFiltersInElements(currentElements, filterArray, nameFilter)
							.length ?? '0'
					} ${t('Filtro.ocupaciones')}`}
				</B1Text>
				<p
					className='B1Text font-normal cursor-pointer text-azul-50'
					onClick={() => {
						cleanFilters();
					}}
				>
					{t('Filtro.limpiar')}
				</p>
			</div>
		</div>
	);
};

export default FilterHeader;
