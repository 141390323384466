import { fetchGetData, fetchPostData } from '../../helpers/fetchHooks';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ENDPOINTS_ELEMENT,
	ENDPOINTS_PROPOSED_IMAGE,
} from '../../helpers/routes';

export const getElement = createAsyncThunk(
	'singleElement/fetchElement',
	async (url) => {
		return await fetchGetData(url);
	}
);

export const getElementsThroughPost = createAsyncThunk(
	'Elements/fetchElements',
	async (values) => {
		return await fetchPostData(values.url, values);
	}
);
export const getPublishedElements = createAsyncThunk(
	'Elements/fetchPublishedElements',
	async () => {
		return await fetchPostData(`${ENDPOINTS_ELEMENT.GET_IMAGES_BY_STATUS}`, {
			status: 'PUBLISHED',
		});
	}
);

export const getProposedElements = createAsyncThunk(
	'Elements/fetchProposedElements',
	async () => {
		return await fetchPostData(
			`${ENDPOINTS_PROPOSED_IMAGE.GET_PROPOSED_IMAGES}`,
			{ status: 'SUBMITTED' }
		);
	}
);
export const getDraftElements = createAsyncThunk(
	'Elements/fetchDraftElements',
	async () => {
		return await fetchPostData(`${ENDPOINTS_ELEMENT.GET_IMAGES_BY_STATUS}`, {
			status: 'DRAFT',
		});
	}
);

const initialState = {
	elements: null,
	publishedElements: null,
	proposedElements: null,
	draftElements: null,
	currentTypeOfElements: '',
	singleElement: {},
	elementsIds: [],
	statusElements: null,
	statusElement: null,
	showLoading: false,
	filteredCollection: [],
	sliceFilteredCollection: [],
	counter: 1,
	initialLoadNumber: 10,
	loadNumber: 10,
	lenChanged: 0,
	lastElement: 0,
};

export const elementSlice = createSlice({
	name: 'Elements',
	initialState,
	reducers: {
		getElementFromElements: (state, { payload }) => {
			state.singleElement = payload;
		},
		EditElementsIds: (state, { payload }) => {
			state.elementsIds = payload;
		},
		GetElementFromStorage: (state, { payload }) => {
			state.singleElement = state.elements.find((obj) => obj['id'] === payload);
		},
		setFilteredCollection: (state, { payload }) => {
			state.filteredCollection = payload.multiPropsFilters;
			state.sliceFilteredCollection = payload.multiPropsFilters.slice(
				0,
				state.initialLoadNumber
			);
			if (payload.lenChanged > 36) {
				state.sliceFilteredCollection = payload.multiPropsFilters.slice(
					0,
					payload.lenChanged
				);
			}
		},
		updateSliceFilteredCollection: (state) => {
			let len = state.sliceFilteredCollection.length;
			state.lenChanged = len;
			const newComments = state.filteredCollection.slice(
				len,
				len + state.loadNumber
			);
			state.sliceFilteredCollection =
				state.sliceFilteredCollection.concat(newComments);
		},
		setLastElement: (state, { payload }) => {
			state.lastElement = payload;
		},
		setCurrentElements: (state, { payload }) => {
			state.elements = payload;
		},
	},
	extraReducers: {
		[getElement.pending]: (state) => {
			state.singleElement = {};
			state.statusElement = 'loading';
		},
		[getElement.fulfilled]: (state, { payload }) => {
			state.singleElement = payload;
			state.statusElement = 'success';
		},
		[getElement.rejected]: (state) => {
			state.statusElement = 'failed';
		},
		[getElementsThroughPost.pending]: (state) => {
			state.showLoading = true;
			state.statusElements = 'loading';
		},
		[getElementsThroughPost.fulfilled]: (state, { payload }) => {
			state.elements = payload;
			state.showLoading = false;
			state.statusElements = 'success';
		},
		[getElementsThroughPost.rejected]: (state) => {
			state.showLoading = false;
			state.statusElements = 'failed';
		},
		//get Published Elements
		[getPublishedElements.pending]: (state) => {
			state.showLoading = true;
			state.statusElements = 'loading';
		},
		[getPublishedElements.fulfilled]: (state, { payload }) => {
			state.publishedElements = payload;
			state.elements = payload;
			state.currentTypeOfElements = 'PUBLISHED';
			state.showLoading = false;
			state.statusElements = 'success';
		},
		[getPublishedElements.rejected]: (state) => {
			state.showLoading = false;
			state.statusElements = 'failed';
		},
		//get Proposed Elements
		[getProposedElements.pending]: (state) => {
			state.showLoading = true;
			state.statusElements = 'loading';
		},
		[getProposedElements.fulfilled]: (state, { payload }) => {
			state.proposedElements = payload;
			state.elements = payload;
			state.currentTypeOfElements = 'SUBMITTED';
			state.showLoading = false;
			state.statusElements = 'success';
		},
		[getProposedElements.rejected]: (state) => {
			state.showLoading = false;
			state.statusElements = 'failed';
		},
		//get Draft Elements
		[getDraftElements.pending]: (state) => {
			state.showLoading = true;
			state.statusElements = 'loading';
		},
		[getDraftElements.fulfilled]: (state, { payload }) => {
			state.draftElements = payload;
			state.elements = payload;
			state.currentTypeOfElements = 'DRAFT';
			state.showLoading = false;
			state.statusElements = 'success';
		},
		[getDraftElements.rejected]: (state) => {
			state.showLoading = false;
			state.statusElements = 'failed';
		},
	},
});

export const {
	getElementFromElements,
	EditElementsIds,
	GetElementFromStorage,
	setFilteredCollection,
	updateSliceFilteredCollection,
	addElement,
	setLastElement,
	singleElement,
	setCurrentElements,
} = elementSlice.actions;

export default elementSlice.reducer;
