import AdminTemplate from '../../../templates/AdminTemplate';
import { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setAppUser } from '../../../../business_logic/redux/reducers/profileSlice';
import { getProfile } from '../../../../business_logic/redux/reducers/profileSlice';

import ProfileView from './ProfileView';
import ProfileEditForm from './ProfileEditForm';
import NavbarSecondaryPerfil from '../../../organisms/Navbar/NavbarSecondaryPerfil';
import B2LightText from '../../../atoms/text/B2LightText';
import { useTranslation } from 'react-i18next';

export default function Profile() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { app_user, profile } = useSelector((state) => state.profile);
	const [editView, setEditView] = useState(false);
	const [user, setUser] = useState(null);

	useEffect(() => {
		dispatch(setAppUser(localStorage.getItem('app_user')));
	}, [dispatch]);

	useEffect(() => {
		if (!profile) {
			dispatch(getProfile());
		}
		setUser(profile);
	}, [dispatch, profile]);

	return (
		<AdminTemplate showNavBarSecondary={false}>
			{user && (
				<>
					<NavbarSecondaryPerfil
						navBarTitle={editView ? 'Perfil' : 'Editar perfil'}
						editView={editView}
						setEditView={setEditView}
					/>
					{editView ? (
						<ProfileEditForm
							initialValues={profile}
							editView={editView}
							setEditView={setEditView}
						/>
					) : (
						<ProfileView profile={profile} />
					)}
				</>
			)}
			<div className='absolute bottom-5 left-5 '>
				<a
					href='https://www.itlookssimple.com/'
					target='_blank'
					rel='noopener noreferrer'
				>
					<B2LightText design='font-normal'>
						{t('Footer.DesarrolladoPorIT')}
					</B2LightText>
				</a>
			</div>
		</AdminTemplate>
	);
}
