import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './i18n'; // to manage the languanges
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import SEO from './SEO/SEO';

const tagManagerArgs = {
	gtmId: 'GTM-5QPDBJQ',
};

ReactGA.initialize('UA-219858548-1');
TagManager.initialize(tagManagerArgs);

ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
	<React.StrictMode>
		<HelmetProvider>
			<SEO />
			<App />
		</HelmetProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
