import React from 'react';
import TextValues from '../../molecules/Text/TextValues';
import { useTranslation } from 'react-i18next';

export default function CategoryNameProposed({
	data,
	categoryData = ['negro'],
}) {
	const { t, i18n } = useTranslation();

	return (
		<div>
			<div className='w-full flex flex-col md:hidden '>
				<TextValues
					label={t('Ficha.ubicacion')}
					information={data.location}
					letter={categoryData[2].ubicacion}
				/>
			</div>
			<div className='hidden w-full md:flex flex-row justify-between pl-11 3xl:pl-0'>
				<div className='flex flex-col w-2/4 '>
					<TextValues
						label={t('Ficha.ubicacion')}
						information={data.location}
						letter={categoryData[2].ubicacion}
					/>
				</div>
			</div>
		</div>
	);
}
