import { ReactComponent as LogoGrande } from '../../assets/LogoGrande.svg';
import { ReactComponent as LogoMediano } from '../../assets/LogoMediano.svg';
import { ReactComponent as LogoPequeño } from '../../assets/LogoPequeño.svg';
import { Link } from 'react-router-dom';

export default function Logo({ type = 'pequeño', design, menuAdmins }) {
	let logoType = <LogoPequeño />;
	if (type === 'mediano') {
		logoType = <LogoMediano />;
	} else if (type === 'grande') {
		logoType = <LogoGrande />;
	}
	return (
		<Link className={`${design}`} to={menuAdmins ? '/admin/published' : '/'}>
			{logoType}
		</Link>
	);
}
