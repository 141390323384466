import React, { useState, useEffect } from 'react';
import GeneralTemplate from '../../templates/GeneralTemplate';
import SendPhotoForm from './SendPhotoForm';
import { useTranslation } from 'react-i18next';
import ModalConfirmationPhotoSend from '../../molecules/Modals/ModalConfirmationPhotoSend';
import { useNavigate } from 'react-router-dom';

export default function SendPhoto({ menuVisitor = true }) {
	const { t } = useTranslation();
	const [showConfirmationModal, setShowConfirmationModal] = useState(false);
	const redirect = useNavigate();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [showConfirmationModal]);

	return (
		<GeneralTemplate
			menuVisitor={menuVisitor}
			showSecondNavbar={true}
			addImageIcon={false}
			navBarTitle={t('ProponerFoto.enviarOtra')}
			showFilter={false}
		>
			<SendPhotoForm
				className='w-full px-4'
				setShowConfirmationModal={setShowConfirmationModal}
			/>

			{showConfirmationModal && (
				<div className='fixed bg-white flex left-0 right-0 top-0 bottom-0 justify-center items-center'>
					<ModalConfirmationPhotoSend
						title={t('ProponerFoto.ImagenEnviada')}
						text={t('ProponerFoto.QueDeseasHacerAhora')}
						textSeeImages={t('ProponerFoto.VerImágenes')}
						textSendOther={t('ProponerFoto.enviarOtra')}
						redirect={redirect}
						setShowConfirmationModal={setShowConfirmationModal}
					/>
				</div>
			)}
		</GeneralTemplate>
	);
}
