import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// BL
import { getCollection } from '../../../business_logic/redux/reducers/collection';
// UI
import GeneralTemplate from '../../templates/GeneralTemplate';
import GalleryCollection from '../../organisms/Gallery/GalleryCollection';
import Loading from '../../organisms/Loading/Loading';

export default function MyCollection() {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { collection, showLoading } = useSelector(
		(state) => state.mycollection
	);

	useEffect(() => {
		if (collection.length < 1) {
			try {
				dispatch(getCollection());
			} catch (error) {
				console.error(error);
			}
		}
	}, [dispatch, collection]);

	return (
		<GeneralTemplate
			section='my-collection'
			navBarTitle={t('MiColeccion.miColeccion')}
			redirectIcon='/new-collection'
			showSecondNavbar={true}
			menuVisitor={true}
			showFilter={false}
		>
			{showLoading ? (
				<Loading />
			) : (
				<GalleryCollection
					imageCollection={collection}
					redirect='/collection/'
					showDescription={true}
				/>
			)}
		</GeneralTemplate>
	);
}
