// THIRD PARTY
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
	getCategories,
	getColors,
	getKeyConcepts,
	getMaterials,
	getOccupationObjects,
	getSpaces,
} from '../../../business_logic/redux/reducers/staticData';

// Bl

// UI
import B1Text from '../../atoms/text/B1Text';
import FilterChipLevels from './filterComponents.js/FilterChipLevels';
import FilterChip from './filterComponents.js/FilterChip';
import FilterChipStaticTiles from './filterComponents.js/FilterChipStaticTiles';
import FilterChipStaticData from './filterComponents.js/FilterChipStaticData';

const FilterMain = () => {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const { elements } = useSelector((state) => state.elements);
	const {
		spaces,
		categories,
		colors,
		materials,
		keyConcepts,
		occupationObjects,
	} = useSelector((state) => state.staticData);
	const [allSpaces, setAllSpaces] = useState([]);
	const [allCategories, setAllCategories] = useState([]);
	const [allColors, setAllColors] = useState([]);
	const [allMaterials, setAllMaterials] = useState([]);
	const [allKeyConcepts, setAllKeyConcepts] = useState([]);
	const [allOccupations, setAllOccupations] = useState([]);

	const [currentElements, setCurrentElements] = useState([]);

	useEffect(() => {
		if (elements) setCurrentElements(elements);
	}, [elements]);

	useEffect(() => {
		if (!categories) {
			dispatch(getCategories()).then((res) => setAllCategories(res.payload));
		} else {
			setAllCategories(categories);
		}
		if (!spaces) {
			dispatch(getSpaces()).then((res) => setAllSpaces(res.payload));
		} else {
			setAllSpaces(spaces);
		}
		if (!colors) {
			dispatch(getColors()).then((res) => setAllColors(res.payload));
		} else {
			setAllColors(colors);
		}
		if (!materials) {
			dispatch(getMaterials()).then((res) => setAllMaterials(res.payload));
		} else {
			setAllMaterials(materials);
		}
		if (!keyConcepts) {
			dispatch(getKeyConcepts()).then((res) => setAllKeyConcepts(res.payload));
		} else {
			setAllKeyConcepts(keyConcepts);
		}
		if (!occupationObjects) {
			dispatch(getOccupationObjects()).then((res) =>
				setAllOccupations(res.payload)
			);
		} else {
			setAllOccupations(occupationObjects);
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className='overflow-y-auto py-4' style={{ maxHeight: '77vh' }}>
			<B1Text design='font-normal my-4 underline'>
				{t('Filtro.categoria')}
			</B1Text>
			<div className='flex flex-col pl-2 gap-y-2'>
				<FilterChip
					filterLetter={i18n.language === 'es' ? 'A' : 'Y'}
					filterLabel={i18n.language === 'es' ? 'AÑO' : 'YEAR'}
					filterVariable='year'
					allElements={currentElements.filter(
						(element) => element['year'] > 2019
					)}
				/>
				<FilterChip
					filterLetter={i18n.language === 'es' ? 'P' : 'C'}
					filterLabel={i18n.language === 'es' ? 'PAÍS' : 'COUNTRY'}
					filterVariable={
						i18n.language === 'es' ? 'country_name' : 'country_name_en'
					}
					allElements={currentElements}
				/>
				<FilterChip
					filterLetter={i18n.language === 'es' ? 'U' : 'L'}
					filterLabel={i18n.language === 'es' ? 'UBICACIÓN' : 'LOCATION'}
					filterVariable={i18n.language === 'es' ? 'location' : 'location_en'}
					allElements={currentElements}
				/>
				<FilterChipLevels
					filterLetter={i18n.language === 'es' ? 'E' : 'S'}
					filterLabel={i18n.language === 'es' ? 'ESPACIO' : 'SPACE'}
					filterVariable={
						i18n.language === 'es' ? 'space_relation' : 'space_relation_en'
					}
					chipValues={allSpaces}
				/>
				<FilterChip
					filterLetter={i18n.language === 'es' ? 'O' : 'O'}
					filterLabel={i18n.language === 'es' ? 'OCUPACIÓN' : 'OCCUPATION'}
					filterVariable={
						i18n.language === 'es' ? 'occupation_type' : 'occupation_type_en'
					}
					allElements={currentElements}
				/>
				<FilterChip
					filterLetter={i18n.language === 'es' ? 'T' : 'T'}
					filterLabel={i18n.language === 'es' ? 'TEMPORALIDAD' : 'TEMPORALITY'}
					filterVariable={
						i18n.language === 'es' ? 'temporality' : 'temporality_en'
					}
					allElements={currentElements}
					sortingOrder={
						i18n.language === 'es'
							? [
									'Vez única',
									'Ocasional',
									'Recurrente',
									'Por temporada',
									'Permanente',
							  ]
							: ['Only time', 'Frequent', 'Occasional', 'Seasonal', 'Permanent']
					}
				/>
				<FilterChip
					filterLetter={i18n.language === 'es' ? 'P' : 'P'}
					filterLabel={i18n.language === 'es' ? 'PERMANENCIA' : 'PERMANENCE'}
					filterVariable={
						i18n.language === 'es' ? 'permanence' : 'permanence_en'
					}
					allElements={currentElements}
					sortingOrder={
						i18n.language === 'es'
							? ['Movil', 'Semi-fijo', 'Fijo']
							: ['Mobile', 'Semi-fixed', 'Fixed']
					}
				/>
				<FilterChip
					filterLetter={i18n.language === 'es' ? 'AG' : 'AG'}
					filterLabel={i18n.language === 'es' ? 'AGENTE' : 'AGENT'}
					filterVariable={i18n.language === 'es' ? 'agent' : 'agent_en'}
					allElements={currentElements}
					sortingOrder={
						i18n.language === 'es'
							? ['Individual', 'Grupal o colectivo', 'Indefinido']
							: ['Individual', 'Group or collective', 'Undefined']
					}
				/>
				<FilterChipLevels
					filterLetter={i18n.language === 'es' ? 'AC' : 'AC'}
					filterLabel={i18n.language === 'es' ? 'ACTIVIDAD' : 'ACTIVITY'}
					filterVariable={
						i18n.language === 'es' ? 'categories' : 'categories_en'
					}
					chipValues={allCategories}
				/>
				<FilterChipStaticTiles
					filterLetter={i18n.language === 'es' ? 'AF' : 'PA'}
					filterLabel={
						i18n.language === 'es' ? 'ATRIBUTOS FÍSICOS' : 'PHYSICAL ATTRIBUTES'
					}
					filterVariableOne={i18n.language === 'es' ? 'color' : 'color_en'}
					filterVariableTwo={
						i18n.language === 'es' ? 'material' : 'material_en'
					}
					labelOne='Color'
					labelTwo='Material'
					allColors={allColors}
					allMaterials={allMaterials}
				/>
				<FilterChipStaticData
					filterLetter={'OO'}
					filterLabel={
						i18n.language === 'es'
							? 'OBJETOS DE OCUPACIÓN'
							: 'OCCUPATION OBJECTS'
					}
					filterVariable={
						i18n.language === 'es'
							? 'occupation_objects'
							: 'occupation_objects_en'
					}
					allElements={allOccupations}
				/>
				<FilterChipStaticData
					filterLetter={i18n.language === 'es' ? 'CC' : 'KC'}
					filterLabel={
						i18n.language === 'es' ? 'CONCEPTOS CLAVE' : 'KEY CONCEPTS'
					}
					filterVariable={
						i18n.language === 'es' ? 'key_concepts' : 'key_concepts_en'
					}
					allElements={allKeyConcepts}
				/>
			</div>
		</div>
	);
};

export default FilterMain;
