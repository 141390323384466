// BL
// UI
import H3TextWeb from '../../atoms/text/H3TextWeb';
import B2LightText from '../../atoms/text/B2LightText';
import B1Text from '../../atoms/text/B1Text';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import { ReactComponent as LinkShare } from '../../assets/icon/Link.svg';
import { ShareSection } from '../../organisms/Share/ShareSection';

export default function ShareCollection({ showShareModal, setShowShareModal }) {
	return (
		<div
			class='fixed z-10 inset-0 overflow-y-auto'
			aria-labelledby='modal-title'
			role='dialog'
			aria-modal='true'
		>
			<div class='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
				<div
					class='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
					aria-hidden='true'
				></div>

				<span
					class='hidden sm:inline-block sm:align-middle sm:h-screen'
					aria-hidden='true'
				>
					&#8203;
				</span>

				<div class='absolute top-36 lg:static lg:top-0 inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-80	'>
					<div class='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
						<div class='sm:flex sm:items-start'>
							<div className='w-80 sm:w-80 h-64 flex flex-col justify-between py-2'>
								<H3TextWeb design='self-center text-azul-50 mb-4'>
									Compartir colección
								</H3TextWeb>
								<B2LightText design='font-normal self-start'>
									Clickea para copiar
								</B2LightText>
								<div className='flex w-full px-4 rounded border border-azul-50 mb-4'>
									<input
										id='copyText'
										className='w-full border border-transparent B1Text  bg-white active:bg-white focus:border-azul-50 pl-1'
										type='text'
										value={window.location.href}
									/>
									<div
										onClick={() => {
											let textToBeCopied = document.getElementById('copyText');
											textToBeCopied.select();
											textToBeCopied.setSelectionRange(0, 99999);
											document.execCommand('copy');
										}}
									>
										<LinkShare />
									</div>
								</div>
								<B1Text design='font-normal self-start mb-4'>
									Compartir en:
								</B1Text>
								<ShareSection
									url={window.location.href}
									title={'Te invito a ver mi colección aquí!!!'}
									source={'Te invito a ver mi colección aquí!!!'}
									summary={'Te invito a ver mi colección aquí!!!'}
									hashtags={'Te invito a ver mi colección aquí!!!'}
									quote={'Te invito a ver mi colección aquí!!!'}
								/>
								<div
									className='flex justify-center'
									onClick={() => setShowShareModal(!showShareModal)}
								>
									<ButtonPrincipal type='Outline' text='Cancelar' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
