import { configureStore } from '@reduxjs/toolkit';
import elementSlice from './reducers/elementsSlice';
import filterSlice from './reducers/filter';
import singleAttributeSlice from './reducers/singleAttributeSlice';
import ProfileSlice from './reducers/profileSlice';
import activitySlice from './reducers/activitySlice';
import collectionSlice from './reducers/collection';
import spaceSlice from './reducers/spaceSlice';
import staticDataSlice from './reducers/staticData';
import PageSlice from './reducers/page';

export const store = configureStore({
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: { warnAfter: 128 },
			serializableCheck: { warnAfter: 128 },
		}),
	reducer: {
		singleAttribute: singleAttributeSlice,
		elements: elementSlice,
		filter: filterSlice,
		profile: ProfileSlice,
		activity: activitySlice,
		space: spaceSlice,
		mycollection: collectionSlice,
		staticData: staticDataSlice,
		page: PageSlice,
	},
});
