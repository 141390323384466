import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_SINGLEATTRIBUTE } from '../../helpers/routes';
import {
	fetchGetData,
	fetchPostData,
	fetchPutData,
} from '../../helpers/fetchHooks';

export const getSingleAttributes = createAsyncThunk(
	'singleAttribute/fetchSingleAttribute',
	async (attribute) => {
		return await fetchGetData(
			ENDPOINTS_SINGLEATTRIBUTE.GET_SINGLE_ATTRIBUTES + attribute
		);
	}
);

export const createSingleAttribute = createAsyncThunk(
	'singleAttribute/createSingleAttribute',
	async (values) => {
		return await fetchPostData(
			ENDPOINTS_SINGLEATTRIBUTE.POST_SINGLE_ATTRIBUTE,
			values
		);
	}
);

export const editSingleAttribute = createAsyncThunk(
	'singleAttribute/editSingleAttribute',
	async (values) => {
		return await fetchPutData(
			ENDPOINTS_SINGLEATTRIBUTE.PUT_SINGLE_ATTRIBUTE,
			values
		);
	}
);

export const deleteSingleAttribute = createAsyncThunk(
	'singleAttribute/deleteSingleAttribute',
	async (values) => {
		return await fetchPutData(
			ENDPOINTS_SINGLEATTRIBUTE.DELETE_SINGLE_ATTRIBUTE,
			values
		);
	}
);

const initialState = {
	attributeName: '',
	singleAttributes: [],
	singleAttributeChange: 1,
};

export const singleAttributeSlice = createSlice({
	name: 'singleAttribute',
	initialState,
	reducers: {
		setAttributeName: (state, { payload }) => {
			state.attributeName = payload;
		},
	},
	extraReducers: {
		/* get single attributes */
		[getSingleAttributes.pending]: (state) => {
			state.status = 'loading';
		},
		[getSingleAttributes.fulfilled]: (state, { payload }) => {
			state.singleAttributes = payload;
			state.status = 'success';
		},
		[getSingleAttributes.rejected]: (state) => {
			state.status = 'failed';
		},

		/*Create Single Attribute*/
		[createSingleAttribute.pending]: (state) => {
			state.status = 'loading';
		},
		[createSingleAttribute.fulfilled]: (state) => {
			state.status = 'success';
			state.singleAttributeChange += 1;
		},
		[createSingleAttribute.rejected]: (state) => {
			state.status = 'failed';
		},

		/*Edit Single Attribute*/
		[editSingleAttribute.pending]: (state) => {
			state.status = 'loading';
		},
		[editSingleAttribute.fulfilled]: (state) => {
			state.status = 'success';
			state.singleAttributeChange += 1;
		},
		[editSingleAttribute.rejected]: (state) => {
			state.status = 'failed';
		},

		/*Delete Single Attribute*/
		[deleteSingleAttribute.pending]: (state) => {
			state.status = 'loading';
		},
		[deleteSingleAttribute.fulfilled]: (state) => {
			state.status = 'success';
			state.singleAttributeChange += 1;
		},
		[deleteSingleAttribute.rejected]: (state) => {
			state.status = 'failed';
		},
	},
});
export const { setAttributeName } = singleAttributeSlice.actions;

export default singleAttributeSlice.reducer;
