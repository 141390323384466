import { useState } from 'react';
import CategoriasLabel from '../../molecules/Categorias/CategoriasLabel';
import CategoriasLineEditForm from '../../molecules/Categorias/CategoriasLineEditForm';
import { useDispatch } from 'react-redux';
import {
	editSingleAttribute,
	deleteSingleAttribute,
} from '../../../business_logic/redux/reducers/singleAttributeSlice';
import CategoriasDelete from '../../molecules/Categorias/CategoriasDelete';
import { fetchAttribute } from '../../../business_logic/helpers/fetchHooks';

export default function CategoriasEditar({
	title,
	url,
	type = 'Arrow',
	values,
}) {
	const dispatch = useDispatch();
	const [action, setAction] = useState('read');

	const renderByAction = (action) => {
		switch (action) {
			case 'read':
				return (
					<CategoriasLabel
						title={title}
						url={url}
						type={type}
						setAction={setAction}
					/>
				);
			case 'edit':
				return (
					<CategoriasLineEditForm
						title={title}
						setAction={setAction}
						values={values}
						submitAction={(values) => {
							dispatch(editSingleAttribute(values)).then((res) => {
								fetchAttribute(values.attribute, dispatch);
								setAction('read');
							});
						}}
					/>
				);

			case 'delete':
				return (
					<CategoriasDelete
						title={title}
						values={values}
						setAction={setAction}
						submitAction={(values) => {
							dispatch(deleteSingleAttribute(values)).then((res) => {
								fetchAttribute(values.attribute, dispatch);
								setAction('read');
							});
						}}
					/>
				);
			default:
				return null;
		}
	};
	return <>{renderByAction(action)}</>;
}
