//UI
import React from 'react';
import B2LightText from '../../atoms/text/B2LightText';
import LinkText from '../../molecules/specialText/LinkText';
import { useTranslation } from 'react-i18next';
import AbreviacionesFooter from '../../atoms/text/AbreviacionesFooter';
import { useLocation } from 'react-router-dom';

export default function Footer() {
	const { t } = useTranslation();
	const { pathname } = useLocation();

	const pathIsAdmin = pathname.match(/^\/admin/);

	return (
		<div className='w-full h-56 flex flex-col pt-20 3xl:pt-16 mb-10 items-center px-4 md:h-16 md:flex-row md:px-8 md:justify-between md:absolute md:mb-10'>
			<div className='w-full grid grid-cols-2 text-center gap-4 mb-2 md:mb-0 md:text-left md:flex md:justify-between md:w-[55%] md:gap-2'>
				<div className='h-8 col-span-2 md:flex md:items-center'>
					<a
						href='https://www.itlookssimple.com/'
						target='_blank'
						rel='noopener noreferrer'
					>
						<B2LightText design='font-normal'>
							{t('Footer.DesarrolladoPorIT')}
						</B2LightText>
					</a>
				</div>
				<LinkText
					path='/legal'
					design={`h-8 col-span-2 md:flex md:items-center`}
				>
					<B2LightText design={`font-normal ${pathIsAdmin && 'hidden'}`}>
						{t('Footer.TérminosLegales')}
					</B2LightText>
				</LinkText>
			</div>

			<a
				href='https://www.instagram.com/otrasmanerasdeocuparelespacio/'
				target='_blank'
				rel='noopener noreferrer'
				className={`${pathIsAdmin && 'hidden'}`}
			>
				<div className='flex flex-row'>
					<div className='w-6 h-8 flex items-center justify-between'>
						<B2LightText design='font-normal'>[</B2LightText>
						<AbreviacionesFooter design='font-normal text-azul-50'>
							IG
						</AbreviacionesFooter>
						<B2LightText design='font-normal'>]</B2LightText>
					</div>
					<B2LightText design='font-medium h-8 text-azul-50 flex items-center ml-1'>
						otrasmanerasdeocuparelespacio
					</B2LightText>
				</div>
			</a>
		</div>
	);
}
