const ones = [
	'',
	'one',
	'two',
	'three',
	'four',
	'five',
	'six',
	'seven',
	'eight',
	'nine',
];

export const DivideByLevels = (category) => {
	//array of levels
	let levelsArray = {};
	// fill the object of Levels with keys that represents the levels.
	category.map((element) => {
		if (!element.nivel) {
			return null;
		}
		element = {
			...element,
			nivel: ones.indexOf(element.nivel),
		};

		if (
			Object.keys(levelsArray).find(
				(level) => `${level}` === `${element.nivel}`
			)
		) {
			levelsArray[element.nivel].push(element);
			return null;
		}

		levelsArray = { ...levelsArray, [element.nivel]: [] };
		levelsArray[element.nivel].push(element);
		return null;
	});
	return levelsArray;
};
