// UI
import FilterHeader from './FilterHeader';
import ActiveFilterTag from './filterComponents.js/ActiveFilterTag';
import FilterMain from './FilterMain';

const FilterMenu = () => {
	return (
		<div className='fixed right-0 top-0 z-40 w-full md:w-3/8 p-4 h-screen bg-white filterMain'>
			<FilterHeader />
			<ActiveFilterTag />
			<FilterMain />
		</div>
	);
};

export default FilterMenu;
