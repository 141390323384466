import { fetchGetData, fetchPostData } from '../../helpers/fetchHooks';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS_COLLECTIONS } from '../../helpers/routes';

export const getCollection = createAsyncThunk(
	'collections/fetchCollections',
	async () => {
		return await fetchGetData(`${ENDPOINTS_COLLECTIONS.GET_ALL_COLLECTIONS}`);
	}
);

export const getSingleCollection = createAsyncThunk(
	'collection/fetchCollectionSingle',
	async (id) => {
		return await fetchPostData(
			`${ENDPOINTS_COLLECTIONS.GET_SINGLE_COLLECTION}${id}`,
			id
		);
	}
);

export const getSingleCollectionInfo = createAsyncThunk(
	'collection/fetchCollectionSingleInfo',
	async (id) => {
		return await fetchPostData(
			`${ENDPOINTS_COLLECTIONS.GET_SINGLE_COLLECTION_INFO}${id}`,
			id
		);
	}
);

const initialState = {
	collection: [],
	statusCollection: null,
	newCollectionName: '',
	countSelect: 0,
	imagesList: [],
	imagesStatus: null,
	newCollectionState: '',
	editSelectedImage: [],
	tempImageList: [],
	collectionName: [],
	singleCollection: [],
	statusSingleCollection: null,
	statusSingleCollectionInfo: null,
	resetStateCollection: false,
	collectionImgIds: [],
	collectionSection: '',
	showLoading: false,
};

export const collectionSlice = createSlice({
	name: 'collections',
	initialState,
	reducers: {
		setNewCollectionName: (state, action) => {
			state.newCollectionName = action.payload;
		},
		clearCounter: (state, action) => {
			state.countSelect = action.payload;
		},
		clearImagesList: (state) => {
			state.imagesList = [];
		},
		counterFiles: (state) => {
			state.countSelect = state.imagesList.length;
		},
		changeCollectionState: (state, action) => {
			state.newCollectionState = action.payload;
		},
		tempEditImage: (state, action) => {
			state.editSelectedImage = action.payload;
		},
		createTempImageList: (state, action) => {
			state.tempImageList = action.payload;
		},
		ResetImageList: (state) => {
			state.imagesList = state.tempImageList;
		},
		resetStatusCollection: (state) => {
			state.statusSingleCollection = null;
		},
		resetStateCollection: (state, action) => {
			state.resetStateCollection = action.payload;
		},
		BuildCollectImgIds: (state, action) => {
			state.collectionImgIds = action.payload;
		},
		changeSection: (state, action) => {
			state.collectionSection = action.payload;
		},
		selectionFiles: (state, action) => {
			const { id } = action.payload;
			const { list } = action.payload;
			const { name } = action.payload;
			const { imagen } = action.payload;
			const imagesArray = state.imagesList;
			switch (list) {
				case 'Images add':
					imagesArray.push({ id: id, name: name, main_image: imagen });
					state.imagesList = [...state.imagesList];
					break;

				case 'Images delete':
					let deleteImages = imagesArray.map((objId) => objId.id).indexOf(id);
					imagesArray.splice(deleteImages, 1);
					state.imagesList = [...state.imagesList];
					break;

				default:
					break;
			}
		},
	},
	extraReducers: {
		[getCollection.pending]: (state) => {
			state.showLoading = true;
			state.statusCollection = 'loading';
		},
		[getCollection.fulfilled]: (state, { payload }) => {
			state.collection = payload;
			state.showLoading = false;
			state.statusCollection = 'success';
		},
		[getCollection.rejected]: (state) => {
			state.showLoading = false;
			state.statusCollection = 'failed';
		},
		[getSingleCollection.pending]: (state, action) => {
			state.statusSingleCollection = 'loading';
		},
		[getSingleCollection.fulfilled]: (state, { payload }) => {
			state.singleCollection = payload;
			state.statusSingleCollection = 'success';
		},
		[getSingleCollection.rejected]: (state, action) => {
			state.statusSingleCollection = 'failed';
		},
		[getSingleCollectionInfo.pending]: (state, action) => {
			state.statusSingleCollectionInfo = 'loading';
		},
		[getSingleCollectionInfo.fulfilled]: (state, { payload }) => {
			state.collectionName = payload;
			state.statusSingleCollectionInfo = 'success';
		},
		[getSingleCollectionInfo.rejected]: (state, action) => {
			state.statusSingleCollectionInfo = 'failed';
		},
	},
});

export const {
	setNewCollectionName,
	clearCounter,
	clearImagesList,
	selectionFiles,
	counterFiles,
	changeCollectionState,
	tempEditImage,
	createTempImageList,
	ResetImageList,
	resetStatusCollection,
	resetStateCollection,
	BuildCollectImgIds,
	changeSection,
} = collectionSlice.actions;

export default collectionSlice.reducer;
