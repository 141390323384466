import { useState } from 'react';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import CategoriasLineEditForm from '../../molecules/Categorias/CategoriasLineEditForm';
import { useDispatch } from 'react-redux';
import { createSingleAttribute } from '../../../business_logic/redux/reducers/singleAttributeSlice';
import { fetchAttribute } from '../../../business_logic/helpers/fetchHooks';

export default function CategoriasAgregar({ parentId }) {
	const dispatch = useDispatch();
	const [action, setAction] = useState('read');
	let values = { id: '', name: '', name_en: '', parent_id: parentId || '' };

	return (
		<div>
			{
				{
					edit: (
						<CategoriasLineEditForm
							title={'Nuevo Valor'}
							values={values}
							setAction={setAction}
							submitAction={(values) => {
								dispatch(createSingleAttribute(values)).then((res) => {
									fetchAttribute(values.attribute, dispatch);
									setAction('read');
								});
							}}
						/>
					),
					read: (
						<div className='md:w-2/3 md:mx-auto flex flex-row justify-end'>
							<ButtonPrincipal
								text='Agregar nuevo valor'
								style={{ width: 'auto' }}
								type=''
								design='text-azul-50 border-b-2 border-azul-50 mb-10'
								action={() => setAction('edit')}
								textClasses='w-1/2'
							/>
						</div>
					),
				}[action]
			}
		</div>
	);
}
