import { useDispatch, useSelector } from 'react-redux';
// BL
import { removeValueFromFilter } from '../../../../business_logic/helpers/filterHelpers';

// UI
import B1Text from '../../../atoms/text/B1Text';
import { ReactComponent as CircleCross } from '../../../assets/icon/CircleCross.svg';

export default function ActiveFilterTag() {
	const dispatch = useDispatch();
	const { filterArray, arrayVariables } = useSelector((state) => state.filter);

	if (arrayVariables.length < 1) return null;

	return (
		<div
			className='flex flex-wrap my-4 gap-1 gap-x-2 overflow-y-auto'
			style={{ maxHeight: '8vh' }}
		>
			{arrayVariables.map((value, index) => {
				let keyToFilter = Object.keys(filterArray).find((key) => {
					return filterArray[key].find((element) => element === value);
				});

				return (
					<div
						key={index}
						className='flex flex-row gap-1 items-center py-1 px-3
					bg-white border border-azul-50 rounded-full text-azul-50'
					>
						<B1Text design='font-normal'>{value}</B1Text>
						<CircleCross
							className='cursor-pointer'
							onClick={() => {
								removeValueFromFilter(
									filterArray,
									arrayVariables,
									keyToFilter,
									value,
									dispatch
								);
							}}
						/>
					</div>
				);
			})}
		</div>
	);
}
