export const TRANSLATIONS_ES = {
	NavBar: {
		inicio: 'Inicio',
		acercaDe: 'Acerca de',
		miColeccion: 'Mi colección',
		enviar: 'Enviar [otra]',
	},
	Footer: {
		PropiedadIntelectual: 'Propiedad intelectual',
		condicionesDeUso: 'Condiciones de uso',
		politicaDePrivacidad: 'Política de privacidad',
		todosLosDerechosReservados: 'Todos los derechos reservados',
		DesarrolladoPorIT: 'Desarrollado por it looks simple',
		TérminosLegales: 'Términos legales',
	},
	Ficha: {
		descripcion: 'Descripción',
		año: 'Año',
		ubicacion: 'Ubicación',
		espacio: 'Espacio',
		tipoDeOcupacion: 'Tipo de ocupación',
		temporalidad: 'Temporalidad',
		permanencia: 'Permanencia',
		agentes: 'Agente',
		actividad: 'Actividad',
		atributosFisicos: 'Atributos físicos',
		objetosDeOcupacion: 'Objetos de ocupación',
		conceptosClave: 'Conceptos clave',
		pais: 'País',
		ninguno: 'Ninguno',
	},
	FichaHover: {
		agregar: 'Agregar imagen a mi álbum',
		ver: 'Ver grupo de imágenes relacionadas',
		anterior: 'Anterior',
		siguiente: 'Siguiente',
		descargarDibujo: 'Descargar dibujo',
	},
	Filtro: {
		buscar: 'Buscar',
		objetos: 'Objetos',
		categoria: 'Categoría',
		ocupaciones: 'ocupaciones',
		limpiar: 'Limpiar',
	},
	ProponerFoto: {
		enviarOtra: 'Enviar [otra]',
		agregarFoto: 'Agregar foto',
		nombreDeOcupacion: 'Nombre de ocupación',
		ubicacion: 'Ubicación<1>*</1>',
		descripción: 'Descripción',
		datosPersonales: 'Datos personales',
		nombreOSeudonimo: 'Nombre o seudónimo',
		email: 'E-mail<1>*</1>',
		ImagenEnviada: '¡Imagen enviada!',
		QueDeseasHacerAhora: '¿Qué deseas hacer ahora?',
		VerImágenes: 'Ver imágenes',
		autorización:
			'Al enviar esta fotografía autorizo los <1>términos de uso *</1>',
		enviar: 'Enviar',
		fotoEnviada: 'Hemos recibido tu fotografía. Gracias!',
		agregaEmail: 'Por favor agrega un email.',
		emailCorrecto: 'Por favor agregar un email correcto.',
		agregaImagen: '*Por favor agrega una imagen.',
		MínimoCaracteres: 'Mínimo más de dos caracteres',
		UbicaciónRequerida: 'Por favor agrega una ubicación',
		TérminosCondicionesUso: 'Los términos y condiciones deben ser aceptados.',
		CamposObligatorios: '*Por favor completar campos obligatorios',
	},
	AcercaDe: {
		acercaDe: 'ACERCA DE',
		s1p1: 'El proyecto [otras] maneras de ocupar el espacio público es un catálogo de usos, objetos, elementos y acciones de ocupación y aprovechamiento del espacio público; una relación detallada, casi un inventario, de usos actuales que incluyen aquellos no intencionados, planeados o diseñados. El objetivo es analizar, rescatar y reivindicar, de manera crítica, las formas y las acciones cotidianas que dan vida a nuestras ciudades y configuran la esfera de lo público.',
		s1p2: 'Este archivo digital del proyecto, compuesto por fotografías, dibujos arquitectónicos, videos y sonidos, así como títulos y descripciones, ha sido sistematizado utilizando una clasificación facetada -y no jerárquica-, la cual está formada por un conjunto de categorías y subcategorías, mediante las cuales es posible unir elementos (maneras de ocupación) que comparten alguna característica común, pero que no definen la categoría como único elemento determinante dentro de la clasificación.',
		s1p3: 'La utilización de este método de organización, además de producir una forma dinámica de presentación de las distintas maneras de ocupación, implica un proceso analítico-sintético que consta de la identificación de los conceptos que constituyen cada categoría y, posteriormente, la creación de relaciones o uniones entre cada una de ellas<1>1</1>. Además, presenta una oportunidad dinámica de continuidad, en la que es posible aumentar indefinidamente los conceptos y relaciones, en caso de que existan maneras de ocupación que no hayan sido identificadas hasta el momento.',
		t2: 'CÓMO LEER LAS CATEGORÍAS',
		s2p1: 'Las categorías propuestas intentan abarcar características de todos los modos de ocupación, que se resumen en:',
		s2p1a: '1) la personalidad de los sujetos u objetos.',
		s2p1b:
			'2) la energía, es decir cualquier acción que ocurre respecto a ellos.',
		s2p1c:
			'3) el espacio, el componente geográfico de la ubicación del objeto o sujeto.',
		s2p1d:
			'4) el tiempo, periodo asociado con el sujeto u objeto, y son las siguientes:',
		t3: 'Ubicación geográfica [U]',
		s3p1: 'Esta categoría describe la ubicación de cada ocupación, es decir, la ciudad donde fue identificada. La categoría, además de describir geográficamente las maneras de ocupación, permite identificar (mapear) las similitudes y diferencias entre estas: por ejemplo, a pesar de que el uso de bicicletas de carga es muy común a lo largo y ancho del país, a partir de este análisis geográfico es posible visualizar que, mientras que en ciudades como Guadalajara, estos son utilizados para vender tejuinos, en Playa del Carmen se usan para vender marquesitas, flores y plantas. Un mismo objeto reproduce diferentes valores culturales y/o económicos, que se presentan como subjetivos frente al análisis desarrollado.',
		t4: 'Temporalidad [T]',
		s4p1: 'Describe la frecuencia con la que una manera de ocupación es llevada a cabo. Puede referir a una única vez, forma ocasional, por temporada, recurrente o permanente. Aunque algunas veces fue difícil definir con exactitud esta característica, se puede entender desde la pura observación e intuición o, en algunas ocasiones, consultándolo con las o los agentes que la generan. La temporalidad tiene que ver una vez más con características económicas, sociales y culturales, las cuales definen si una fiesta se realiza de manera anual o por temporada, si la venta de productos se lleva a cabo permanentemente o de forma ocasional, o si una persona visita a diario o esporádicamente una esquina particular de la ciudad.',
		t5: 'Permanencia [P]',
		s5p1: 'Se puede considerar una característica espacio-temporal de las maneras de ocupación que permite saber si tal ocupación se genera a través de un objeto o sujeto que está en movimiento, que está fijo pero podría moverse o, finalmente, si está fijo sin posibilidad de cambio. Esta característica define de manera representativa la flexibilidad o capacidad de cambio y uso en el espacio público de los modos de ocupación observados. ',
		t6: 'Actividad [AC]',
		s6p1: 'Esta categoría habla de la diversidad de razones por las cuales las personas ocupan el espacio público; pueden ir desde esperar el transporte público o cambiarle el pañal a un bebé, hasta cerrar la calle para jugar o protestar. Se trata, además, de una categoría que describe aquellas prácticas que, aunque informales y cotidianas, le dan valor y heterogeneidad al espacio público.',
		t7: 'Tipo de ocupación [O]',
		s7p1: 'Se refiere al modo o medio por el cual una persona ocupa o se reapropia del espacio. Va desde la ocupación por medio del propio cuerpo -el cuerpo como artefacto o instrumento, que permite a la persona habitar, utilizar, llenar, apropiar(se) de dicho espacio-, hasta la ocupación haciendo uso de otros elementos -objetos-, que pueden ser encontrados (readymade) o fabricados para un uso específico. Finalmente se encuentra el “hack” de infraestructura que implica implementar soluciones para cualquier sistema, de manera que este pueda emplearse de formas no pensadas por quienes crearon dichos sistemas; esta manera de ocupación no implica necesariamente la presencia del cuerpo, sino la utilización de la infraestructura pública para llevar a cabo alguna actividad en específico, la cual no ha sido diseñada o planeada previamente.',
		t8: 'Atributos físicos [AF]',
		s8p1: 'Se definen en esta categoría aquellas determinantes que dan calidad a los objetos, artefactos y elementos que consolidan una ocupación particular. Resaltar la naturaleza de un elemento, su composición, aspecto y apariencia, material del que está hecho y/o color predominante, sencillamente permite delimitar aquellos atributos que dan valor subjetivo e identidad a las múltiples maneras de ocupación del espacio público.',
		t9: 'Agente [AG]',
		s9p1: 'Esta categoría describe si la manera de ocupación es individual, grupal o indefinida, en caso de que  no sea claro o no sea de importancia en ciertas maneras de ocupación. Definirla es importante para entender la influencia que un modo particular de ocupación puede tener sobre el espacio definido.',
		t10: 'Espacio [E]',
		s10p1:
			'Esta categoría define el tipo de espacio en el que las distintas maneras de ocupación se desarrollan. Conocer esta información brinda la posibilidad de analizar si existen ciertos lugares donde algunas actividades ocurren con mayor frecuencia.',
		t11: 'Objetos [OB]',
		s11p1:
			'Esta categoría incluye todos los elementos u objetos utilizados al ocupar el espacio. Es una categoría exhaustiva y no sintética. Nombra, por ejemplo, objetos como: silla o banco de plástico, micrófono, sombrero, reja de refrescos, maniquí, cartulina, etc. Desarrollar esta categoría permite abonar a un inventario de todos aquellos objetos que se utilizan para ocupar de maneras alternativas el espacio público y conocer si son objetos que se encuentran en todas las ciudades o si estos varían, dependiendo de la ubicación.',
		t12: '-',
		s12p1:
			'La ciudad hoy en día parece que está pensada para ser únicamente limpia, segura, cordial y ordenada<1>2</1>. Es en este mismo sentido que se pretende que los espacios públicos que la conforman sean concebidos como eficientes, transitorios, y que cumplan funciones específicas, convirtiendo a la ciudad “en un espacio comercializable en el que se pretende instaurar una visión hegemónica y libre de conflictos<3>3</3>”.',
		s12p2:
			'Para garantizar la existencia de estos espacios, supuestamente libres de conflicto, la ciudad se apoya en la creación de reglamentos de uso de los espacios, instalación de señales y elementos de organización, integración de cámaras de vigilancia, la instrumentalización de la policía y, en algunas ocasiones, en la observación cómplice de algunos ciudadanos, quienes validan qué actividades deberían estar permitidas y cuáles quedan rotundamente prohibidas.',
		s12p3:
			'Frente a esto, ocupar el espacio público de otras maneras -maneras distintas a las establecidas y sobre las cuales se ha regido el diseño del espacio-, constituye una actitud de resistencia, que trae consigo la posibilidad de rupturas y emancipaciones, de espontaneismo incierto y liberador<1>4</1>.',
		s12p4:
			'Es precisamente allí donde yace el valor de realizar un inventario de estas [otras] maneras de ocupación del espacio público; representar esta gran variedad de actividades, actores y objetos que las hacen posibles, permite construir una radiografía de la vida urbana cotidiana actual que persiste, a pesar de un sinfín de mecanismos para controlarla e intentos por desaparecerla.',
		t13: '-',
		s13p1:
			'El proyecto [otras] maneras de ocupar el espacio público ha sido desarrollado por Ximena Ocampo Aguilar gracias a la Beca Jóvenes Creadores otorgada por el Sistema de Apoyos a la Creación a Proyectos Culturales (FONCA) en su edición 2020-2021, y al apoyo del equipo de dérive LAB (Francisco Paillie, Jesús Ocampo, Jesús Méndez, Sofía Ávila, Woroud Ahdali, Angelica García y Sophie Zurhaar), Wendy Sánchez y Karla Velarde.',
		s13p2:
			'Ximena es arquitecta graduada del Tec de Monterrey, tiene una maestría en diseño de ciudad y ciencias sociales por la <1>London School of Economics and Political Science</1>, donde desarrolló diferentes trabajos de investigación sobre lo público y el rol de la sociedad civil en la transformación de la ciudad. Trabajó como diseñadora urbana en WRI México, y en 2013 fundó <3>dérive LAB</3>, un laboratorio que busca explorar, comprender e inspirar otras (nuevas) maneras de vivir y pensar la vida en la Ciudad, donde trabaja hasta la fecha liderando distintos proyectos con enfoque en espacio público, movilidad activa y, en general, la relación entre las personas y el espacio.',
		s13p3:
			'Si tienes alguna pregunta o comentario, no dudes en ponerte en contacto con ella al correo <1>ximena@derivelab.org</1> o a través de la <3>cuenta de Instagram del proyecto.</3>',
		descargaTodos: 'Descarga todos los dibujos arquitectónicos en DWG',
		descargar: 'Descargar',
		titleFooter: '-',
		footert1:
			'<0>1</0> Hjørland, Birger. <2>Facet analysis.</2> (International Society for Knowledge Organization).',
		footert2:
			'<0>2</0>  Sennett, Richard. <2>The open city.</2> (Urban Age, 2006).',
		footert3:
			'<0>3</0> Hernández, Adrián. <2>El Forat de la Vergonya: el conflicto entre la ciudad planificada y la ciudad habitada.</2> (Hábitat y sociedad, 2016), 37-53.',
		footert4:
			'<0>4</0> Martínez, Emilio. <2>Configuración urbana, habitar y apropiación del espacio.</2>(XIII Coloquio Internacional de Geocrítica. El control del espacio y los espacios de control: Universidad de Barcelona, 2014).',
	},
	MiColeccion: {
		miColeccion: 'Colecciones',
	},
	NuevaColecionModal: {
		nuevaColeccion: 'Nueva colección',
		ingresaUnNombre: 'Ingresa un nombre para el álbum',
		cancelar: 'Cancelar',
		crearAlbum: 'Crear álbum',
	},
	NuevaColeccionOtro: {
		añadirImagenes: 'Añadir imágenes',
		archivosSelecionados: 'archivos seleccionados',
		editar: 'Editar',
		publicar: 'Publicar',
		guardar: 'Guardar',
		publicarColeccion: 'Publicar colección',
		publicarTexto: 'Al publicar tu colección pierdes las opciones de edición',
		cancelar: 'Cancelar',
		eliminar: 'Eliminar',
		eliminarImagen: 'Eliminar Imagen',
		eliminarTexto: 'Esta imagen se eliminará de su colección.',
		alerta: 'Para publicar debe de haber al menos una imagen seleccionada',
		crearColección: 'Crear colección',
		agregarImágenes: 'Agregar imágenes',
	},
	Abreviaciones: {
		Año: 'A',
		País: 'P',
		Ubicación: 'U',
		Espacio: 'E',
		'Tipo de ocupación': 'O',
		Temporalidad: 'T',
		Permanencia: 'P',
		Agente: 'AG',
		Actividad: 'AC',
		'Atributos físicos': 'AF',
		'Objetos de ocupación': 'OO',
		'Conceptos clave': 'CC',
	},
	AvisoLegal: {
		t1: 'Aviso Legal',
		p1: 'Lorep Ipsum',
	},
	TérminosCondiciones: {
		t1: 'Condiciones de Uso',
		p1: 'Al enviar material fotográfico a través de este sitio, usted acepta que:',
		p2: 'Tiene por lo menos 18 años de edad y plena capacidad de ejercicio. Si no cumple con esta condición, solamente podrá participar bajo la supervisión de una persona mayor que actué en su representación.',
		p3: 'Concede gratuitamente a dérive lab el derecho a utilizar el material de cualquier manera y en cualquier medio, incluyendo, sin limitación, el derecho a publicar, adaptar, distribuir, copiar, mostrarlo en medios impresos o electrónicos. En este caso, dérive lab se compromete a mencionar el nombre o seudónimo del participante si su fotografía es publicada en este sitio web o utilizada en cualquier material relacionado con este proyecto.',
		p4: 'El material fotográfico presentado no viola los derechos de un tercero o cualquier derecho de autor, por lo que dérive lab no se hace responsable de las violaciones de la propiedad intelectual que puedan derivarse de la presentación de las fotografías.',
		p5: 'Ha leído y entiende los presentes Términos y Condiciones y se compromete a cumplir con los mismos.',
	},
	PolíticaDePrivacidad: {
		t1: 'Política de Privacidad',
		p1: 'Dérive lab, con domicilio en Privada Revillagigedo 10, Col. San Francisquito, en Querétaro, código postal 76058, es la organización responsable del tratamiento de los datos personales que usted proporcione, los cuales serán protegidos conforme a lo dispuesto por la Ley de Protección de Datos Personales, y demás normatividad que resulte aplicable.',
	},
	PropiedadIntelectual: {
		t1: 'Propiedad intelectual',
		p1: '<0></0>[otras] maneras de ocupar el espacio público por dérive lab se distribuye bajo una Licencia Creative Commons Atribución-NoComercial-CompartirIgual 4.0 Internacional. Basada en una obra en <1>https://otrasmanerasdeocuparelespaciopublico.com</1>',
		p2: 'Los contenidos que no estén bajo esta licencia estarán debidamente señalados con la leyenda Derechos Reservados o la imagen ©.',
	},
	Cargando: {
		Cargando: 'Cargando',
		CargarMas: 'Cargar Más',
	},
};
