import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// BL
import { getElement } from '../../../../business_logic/redux/reducers/elementsSlice';
import {
	getAgents,
	getCategories,
	getColors,
	getCountries,
	getKeyConcepts,
	getLocations,
	getMaterials,
	getOccupationObjects,
	getOccupationTypes,
	getPermanence,
	getSpaces,
	getTemporalities,
} from '../../../../business_logic/redux/reducers/staticData';
// UI
import AdminTemplate from '../../../templates/AdminTemplate';
import FichaEditForm from './FichaEditForm';
import Footer from '../../../organisms/Footer/Footer';
import Loading from '../../../organisms/Loading/Loading';

export default function FichaEdit({ url, typeImage }) {
	const dispatch = useDispatch();
	const { elements } = useSelector((state) => state.elements);
	const {
		countries,
		locations,
		spaces,
		occupationTypes,
		temporalities,
		permanence,
		agents,
		categories,
		colors,
		materials,
		keyConcepts,
		occupationObjects,
	} = useSelector((state) => state.staticData);

	const { id } = useParams();
	const [singleElement, setSingleElement] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (elements) {
			setSingleElement(elements.find((element) => parseInt(id) === element.id));
		} else {
			setIsLoading(true);
			dispatch(getElement(`${url}${id}/`)).then((res) => {
				setSingleElement(res.payload);
				setIsLoading(false);
			});
		}
	}, [elements, dispatch, url, id]);

	useEffect(() => {
		if (!categories) {
			dispatch(getCategories());
		}
		if (!spaces) {
			dispatch(getSpaces());
		}
		if (!colors) {
			dispatch(getColors());
		}
		if (!materials) {
			dispatch(getMaterials());
		}
		if (!keyConcepts) {
			dispatch(getKeyConcepts());
		}
		if (!occupationTypes) {
			dispatch(getOccupationTypes());
		}
		if (!agents) {
			dispatch(getAgents());
		}
		if (!countries) {
			dispatch(getCountries());
		}
		if (!locations) {
			dispatch(getLocations());
		}
		if (!permanence) {
			dispatch(getPermanence());
		}
		if (!temporalities) {
			dispatch(getTemporalities());
		}
		if (!occupationObjects) {
			dispatch(getOccupationObjects());
		}
		// eslint-disable-next-line
	}, []);

	return (
		<AdminTemplate navBarTitle={'Editar Foto'} addImageIcon={false}>
			{isLoading ? (
				<Loading />
			) : (
				<>
					{singleElement && (
						<FichaEditForm
							typeImage={typeImage}
							singleElement={singleElement}
							countries={countries ?? []}
							locations={locations ?? []}
							spaces={spaces ?? []}
							occupationTypes={occupationTypes ?? []}
							temporalities={temporalities ?? []}
							permanence={permanence ?? []}
							agents={agents ?? []}
							categories={categories ?? []}
							colors={colors ?? []}
							material={materials ?? []}
							keyConcepts={keyConcepts ?? []}
							occupationObjects={occupationObjects ?? []}
						/>
					)}
				</>
			)}
			<div className='flex flex-col items-center'>
				<Footer />
			</div>
		</AdminTemplate>
	);
}
