import { setFilterArray, setArrayVariables } from '../redux/reducers/filter';

const specialFilterVariables = [
	'categories',
	'categories_en',
	'color',
	'color_en',
	'space_relation',
	'space_relation_en',
	'material',
	'material_en',
	'key_concepts',
	'key_concepts_en',
	'occupation_objects',
	'occupation_objects_en',
];

const addValueToFilters = (
	filterArray,
	arrayVariables,
	keyToFilter,
	labelValue,
	dispatch
) => {
	const valueToFilterIsInFilterArray = Object.keys(filterArray).find(
		(key) => key === keyToFilter
	);
	let newFilterArray = {};
	if (valueToFilterIsInFilterArray) {
		newFilterArray = {
			...filterArray,
			[keyToFilter]: [...filterArray[keyToFilter], labelValue],
		};
	} else {
		newFilterArray = {
			...filterArray,
			[keyToFilter]: [labelValue],
		};
	}
	dispatch(setFilterArray(newFilterArray)); //add key and value of filterArray:{}

	let newArrayVariables = [...arrayVariables, labelValue];
	dispatch(setArrayVariables(newArrayVariables)); //add value of arrayVariables:string[]
};

const removeValueFromFilter = (
	filterArray,
	arrayVariables,
	keyToFilter,
	labelValue,
	dispatch
) => {
	let newFilterArray = {};
	newFilterArray = {
		...filterArray,
		[keyToFilter]: filterArray[keyToFilter].filter(
			(element) => element !== labelValue
		),
	};
	if (newFilterArray[keyToFilter].length === 0) {
		delete newFilterArray[keyToFilter];
	}
	dispatch(setFilterArray(newFilterArray)); //remove key and value of filterArray:{}

	let newArrayVariables = arrayVariables.filter(
		(element) => element !== labelValue
	);
	dispatch(setArrayVariables(newArrayVariables)); //remove value of arrayVariables:string[]
};

function multiPropsFilters(array, filterArray) {
	return array.filter((element) => {
		if (
			Object.keys(filterArray).every((key) => {
				if (!element[key]) return false;

				if (specialFilterVariables.indexOf(key) === -1) {
					if (
						filterArray[key].some((value) => {
							if (element[key].includes(value)) return true;
							return null;
						})
					) {
						return true;
					}

					return null;
				} else {
					if (
						filterArray[key].every((value) => {
							if (element[key].includes(value)) return true;
							return null;
						})
					) {
						return true;
					}
					return null;
				}
			})
		) {
			return element;
		}
		return null;
	});
}

const AllFiltersInElements = (elements, filterArray, nameFilter) => {
	const elementsFilteredByName = elements.filter(
		(element) =>
			element.name &&
			!element.name.toLowerCase().indexOf(nameFilter.toLowerCase())
	);
	return multiPropsFilters(elementsFilteredByName, filterArray);
};

export {
	addValueToFilters,
	removeValueFromFilter,
	multiPropsFilters,
	AllFiltersInElements,
};
