import { Field } from 'formik';
import { useTranslation } from 'react-i18next';

import B1Text from '../../atoms/text/B1Text';
import B2LightText from '../../atoms/text/B2LightText';

export default function InputBox({
	label,
	dbName,
	fieldType = 'Text',
	errors,
	design,
}) {
	const { t } = useTranslation();

	return (
		<div className={`flex flex-col gap-2`}>
			<div
				className={
					label
						? `flex flex-row gap-2 w-full justify-between md:justify-start items-center px-4 ${design}`
						: 'w-full'
				}
			>
				{fieldType === 'checkbox' ? (
					<B2LightText design={'order-2 my-auto'}>{label}</B2LightText>
				) : (
					<B1Text design={label ? `w-1/2 max-w-none sm:w-1/3` : 'w-full'}>
						{label}
					</B1Text>
				)}

				<Field
					name={dbName}
					type={fieldType}
					className={`p-2 B1Text rounded bg-white border border-azul-50 active:bg-white focus:border-azul-50 self-center  ${
						fieldType === 'checkbox'
							? 'justify-start w-4 h-4 order-2 md:order-1'
							: 'w-full px-2'
					}`}
				/>
			</div>
			{errors && <B1Text design={`text-azul-50`}>{t(errors)}</B1Text>}
		</div>
	);
}
