// 3rs
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// BL
import { MenuAdminData } from '../../../business_logic/data/menuAdminData';
import { removeUserSession } from '../../../business_logic/authentication/servicesAuthentication';
import {
	cleanAllFilters,
	filterDisplay,
} from '../../../business_logic/redux/reducers/filter';

// UI
import Logo from '../../atoms/menu/Logo';
import MenuAdmin from '../../molecules/Menu/MenuAdmin';
import LinkText from '../../molecules/specialText/LinkText';
import Menu from '../../pages/Menu/Menu';
import B1Text from '../../atoms/text/B1Text';
import { ReactComponent as FilterIcon } from '../../assets/icon/Filtro.svg';
import FilterMenu from '../Filter/FilterMenu';
import { ReactComponent as IconHamburguer } from '../../assets/icon/Hamburger.svg';
import { useLocation } from 'react-router-dom';

export default function NavbarPrimary({
	menuVisitor = false,
	menuAdmins = false,
	section,
	hasFilter = false,
}) {
	const dispatch = useDispatch();
	const location = useLocation();
	const { t, i18n } = useTranslation();
	const { showFilter } = useSelector((state) => state.filter);
	const [showMenu, setShowMenu] = useState(false);

	useEffect(() => {
		if (showMenu) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [showMenu]);

	const changeLanguageHandler = (lgn) => {
		dispatch(cleanAllFilters());
		i18n.changeLanguage(lgn);
	};

	const url = document.URL.split('/');

	const stylesMenu =
		'hidden xl:flex text-sm w-3/5 flex-row justify-between relative';

	return (
		<div className='flex flex-row justify-between w-full p-4 md:pt-8'>
			<div className='flex flex-col gap-10'>
				<div className='logos'>
					<div className='block md:hidden'>
						<Logo type='pequeño' design='' menuAdmins={menuAdmins} />
					</div>
					<div className='hidden md:block lg:hidden'>
						<Logo type='mediano' design='' menuAdmins={menuAdmins} />
					</div>
					<div className='hidden lg:block'>
						<Logo type='grande' design='' menuAdmins={menuAdmins} />
					</div>
				</div>
			</div>
			{menuAdmins ? (
				<div className={`${hasFilter ? `left-14` : `left-8`}  ${stylesMenu}`}>
					{MenuAdminData.map((field, i) => {
						let textStyle = {};
						if (location.pathname === field.url) textStyle = { color: 'blue' };
						return (
							<LinkText key={i} path={field.url}>
								<B1Text
									key={i}
									design={`font-normal cursor-pointer`}
									style={textStyle}
								>
									{field.label}
								</B1Text>
							</LinkText>
						);
					})}
					<div onClick={() => removeUserSession()}>
						<B1Text design='pb-8 cursor-pointer'>Cerrar sesión</B1Text>
					</div>
				</div>
			) : (
				<div className='flex-row justify-between hidden w-2/5 xl:flex 3xl:ml-80'>
					<LinkText
						action={() =>
							url[url.length - 1] === '' ? window.location.reload() : ''
						}
						path='/'
					>
						<div
							className={`${
								url[url.length - 1] === '' ? 'text-azul-50' : ''
							} font-normal cursor-pointer B1TextMenu`}
						>
							{t('NavBar.inicio')}
						</div>
					</LinkText>
					<LinkText path='/about'>
						<div
							className={`${
								url[url.length - 1] === 'about' ? 'text-azul-50' : ''
							} font-normal cursor-pointer B1TextMenu`}
						>
							{t('NavBar.acercaDe')}
						</div>
					</LinkText>
					<LinkText path='/my-collection'>
						<div
							className={`${
								url[url.length - 1] === 'my-collection' && 'text-azul-50'
							} font-normal cursor-pointer B1TextMenu`}
						>
							{t('NavBar.miColeccion')}
						</div>
					</LinkText>
					<LinkText path='/send-photo'>
						<div
							className={`${
								url[url.length - 1] === 'send-photo' ? 'text-azul-50' : ''
							} font-normal cursor-pointer B1TextMenu`}
						>
							{t('NavBar.enviar')}
						</div>
					</LinkText>
				</div>
			)}

			<div className='flex-col justify-end hidden gap-10 xl:flex'>
				{!menuAdmins && (
					<div
						onClick={() =>
							changeLanguageHandler(i18n.language === 'es' ? 'en' : 'es')
						}
					>
						<B1Text design='font-normal cursor-pointer'>
							{i18n.language === 'es' ? 'English' : 'Español'}
						</B1Text>
					</div>
				)}

				{section !== 'singlecollection' && hasFilter && (
					<div className='z-10 mx-auto'>
						<FilterIcon
							className='cursor-pointer'
							data-test='filter-icon'
							onClick={() => dispatch(filterDisplay(true))}
						/>
						{showFilter && <FilterMenu />}
					</div>
				)}
			</div>

			<div className='flex flex-col gap-10 xl:hidden'>
				<IconHamburguer
					className='mx-auto'
					onClick={() => setShowMenu(!showMenu)}
				/>
				{section !== 'singlecollection' && hasFilter && (
					<div className='mx-auto'>
						<FilterIcon
							className='cursor-pointer'
							onClick={() => dispatch(filterDisplay(true))}
						/>
						{showFilter && <FilterMenu />}
					</div>
				)}
			</div>

			{showMenu && menuAdmins && <MenuAdmin setShowMenu={setShowMenu} />}
			{showMenu && menuVisitor && <Menu setShowMenu={setShowMenu} />}
		</div>
	);
}
