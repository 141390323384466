import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
// BL
import { selectionFiles } from '../../../business_logic/redux/reducers/collection';

export default function ImageSelection({ id, imagen, name }) {
	const dispatch = useDispatch();

	const { imagesList } = useSelector((state) => state.mycollection);

	let actualArray = imagesList.map((objId) => objId.id).indexOf(id);
	let actionList = '';
	if (actualArray === -1) actionList = `Images add`;
	else actionList = `Images delete`;

	return (
		<div id={id} className={`${actualArray >= 0 && 'bg-azul-50'}`}>
			<div
				className='flex flex-col opacity-60'
				onClick={(e) => {
					e.preventDefault();
					dispatch(
						selectionFiles({
							id: id,
							list: actionList,
							imagen: imagen,
							name: name,
						})
					);
				}}
			>
				<LazyLoadImage
					src={`${imagen}_medium_compressed`}
					alt={name}
					wrapperClassName={`h-22 overflow-hidden flex items-center lg:h-40`}
					style={{ objectFit: 'cover', aspectRatio: '1/1' }}
				/>
			</div>
		</div>
	);
}
