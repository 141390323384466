import { Link, useLocation } from 'react-router-dom';
import { removeUserSession } from '../../../business_logic/authentication/servicesAuthentication';
import { MenuAdminData } from '../../../business_logic/data/menuAdminData';
import { ReactComponent as Close } from '../../assets/icon/X.svg';
import B1Text from '../../atoms/text/B1Text';

export default function MenuAdmin({ setShowMenu }) {
	const location = useLocation();
	return (
		<div className='absolute bg-white w-full h-screen z-20 top-4 left-4 md:px-4'>
			<Close onClick={() => setShowMenu(false)} className='mt-8 mb-16 ' />
			<ul>
				{MenuAdminData.map((field, i) => {
					let textStyle = {};
					if (location.pathname === field.url) textStyle = { color: 'blue' };

					return (
						<Link onClick={() => setShowMenu(false)} key={i} to={field.url}>
							<B1Text key={i} design={`mb-8`} style={textStyle}>
								{field.label}
							</B1Text>
						</Link>
					);
				})}
				<div onClick={() => removeUserSession()}>
					<B1Text design='mb-8 cursor-pointer'>Cerrar sesión</B1Text>
				</div>
			</ul>
		</div>
	);
}
