import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

// BL
import {
	galerydata,
	tagBarSections,
	tagBarSectionsEn,
} from '../../../business_logic/data/dumyData';
import {
	fetchPostData,
	fetchPutData,
} from '../../../business_logic/helpers/fetchHooks';

import { ENDPOINTS_PROPOSED_IMAGE } from '../../../business_logic/helpers/routes';
import {
	getDraftElements,
	getElement,
	getElementFromElements,
	getProposedElements,
	getPublishedElements,
} from '../../../business_logic/redux/reducers/elementsSlice';

//UI
import GeneralTemplate from '../../templates/GeneralTemplate';
import TitleNumberCollection from '../../molecules/Title/TitleNumberCollection';
import TagBar from '../../molecules/Bar/TagBar';
import ButtonAction from '../../molecules/Buttons/ButtonAction';
import InputTextArea from '../../molecules/Input/InputTextArea';
import CategoryName from '../../organisms/SectionCategory/CategoryName';
import HighText from '../../atoms/text/HighText';
import H3TextWeb from '../../atoms/text/H3TextWeb';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import ImageModal from '../../molecules/Image/ImageModal';
import CategoryNameProposed from '../../organisms/SectionCategory/CategoryNameProposed';
import { ReactComponent as LeftArrowSmall } from '../../assets/icon/LeftArrowSmall.svg';
import SEO from '../../../SEO/SEO';

export default function SingleImage({
	url,
	typeImage,
	menuVisitor = true,
	menuAdmins,
}) {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	const { singleElement, elements } = useSelector((state) => state.elements);

	const [singleImage, setSingleImage] = useState(singleElement);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [sectionCollection, setSectionCollection] = useState('Fotografía');
	const [sectionIndex, setSectionIndex] = useState(0);
	const [dataColors, setDataColors] = useState([]);
	const [dataMaterials, setDataMaterials] = useState([]);
	const [dataColorsEn, setDataColorsEn] = useState([]);
	const [dataMaterialsEn, setDataMaterialsEn] = useState([]);
	const [dataKeyConcepts, setKeyConcepts] = useState([]);
	const [dataOccupations, setDataOccupations] = useState([]);
	const [dataKeyConceptsEn, setKeyConceptsEn] = useState([]);
	const [dataOccupationsEn, setDataOccupationsEn] = useState([]);
	const [levelData, setLevelData] = useState(false);
	const [spaceData, setSpaceData] = useState(false);

	const [ipad, setIpad] = useState({});
	const [desktop, setDesktop] = useState({});
	const [mobile, setMobile] = useState({});
	const [modalFullImage, setModalFullImage] = useState(false);

	useEffect(() => {
		if (elements && elements.length > 0 && typeImage === 'PUBLISHED') {
			let selectedElement = elements.find(
				(element) => parseInt(id) === element.id
			);
			dispatch(getElementFromElements(selectedElement));
			setSingleImage(selectedElement);
		} else {
			dispatch(getElement(`${url}${id}/`)).then((res) => {
				setSingleImage(res.payload);
			});
		}
	}, [id, dispatch, elements, url, typeImage]);
	useEffect(() => {
		setDataColors(singleImage.color);
		setDataMaterials(singleImage.material);
		setLevelData(singleImage.categories_by_level);
		setDataColorsEn(singleImage.color_en);
		setDataMaterialsEn(singleImage.material_en);
		setKeyConcepts(singleImage.key_concepts);
		setDataOccupations(singleImage.occupation_objects);
		setKeyConceptsEn(singleImage.key_concepts_en);
		setDataOccupationsEn(singleImage.occupation_objects_en);
		setSpaceData(singleImage.spaces_by_level);
		setIpad({ objectFit: 'cover', maxHeight: '400px' });
		setDesktop({ objectFit: 'cover', maxHeight: '500px' });
		setMobile({ maxHeight: '230px' });
	}, [singleImage]);

	useEffect(() => {
		if (modalFullImage === false) document.body.style.overflow = 'unset';
	}, [modalFullImage]);

	const rejectElement = async () => {
		setIsSubmitting(true);
		try {
			await fetchPutData(`${ENDPOINTS_PROPOSED_IMAGE.REJECT_IMAGE}${id}/`)
				.then(async () => {
					await dispatch(getPublishedElements());
					await dispatch(getDraftElements());
					await dispatch(getProposedElements());
				})
				.finally(() => {
					navigate('/admin/proposed');
				});
		} catch (error) {
			console.error(error);
		}
		setIsSubmitting(false);
	};

	const acceptElement = async () => {
		setIsSubmitting(true);
		let mexicoAsDefault = 135;
		let values = { ...singleImage, country_id: mexicoAsDefault };
		let jsonData = JSON.stringify(values);
		try {
			await fetchPostData(
				`${ENDPOINTS_PROPOSED_IMAGE.ACCEPT_IMAGE}${id}/`,
				jsonData
			)
				.then(async () => {
					await dispatch(getPublishedElements());
					await dispatch(getDraftElements());
					await dispatch(getProposedElements());
				})
				.finally(() => {
					navigate('/admin/proposed');
				});
		} catch (error) {
			console.error(error);
		}
		setIsSubmitting(false);
	};

	function imageModalRender(sectionCollection) {
		switch (sectionCollection) {
			case 'Fotografía':
			case 'Photo':
				return singleImage.image_url + '_compressed';
			case 'Dibujo':
			case 'Sketch':
				return singleImage.architectural_image_url;
			default:
				break;
		}
	}

	function renderSwitch(sectionCollection, styleOn) {
		switch (sectionCollection) {
			case 'Fotografía':
			case 'Photo':
				return (
					<div
						className='cursor-pointer'
						onClick={() => setModalFullImage(true)}
					>
						<LazyLoadImage
							key={singleImage.id}
							alt={singleImage.name}
							src={`${singleImage.image_url}_medium_compressed`}
							effect='blur'
							style={styleOn}
						/>
					</div>
				);
			case 'Dibujo':
			case 'Sketch':
				return (
					<div
						className='cursor-pointer'
						onClick={() => setModalFullImage(true)}
					>
						<LazyLoadImage
							key={singleImage.id}
							alt={singleImage.name}
							src={singleImage.architectural_image_url}
							style={styleOn}
						/>
					</div>
				);
			default:
				return null;
		}
	}

	let imageAuthor =
		typeImage === 'PROPOSED'
			? singleImage?.creator_name
			: singleImage?.updated_by;

	return (
		<GeneralTemplate
			section='singlecollection'
			menuVisitor={menuVisitor}
			menuAdmins={menuAdmins}
			designNavbarExtContainer='pt-28 md:pt-24'
		>
			<SEO
				title={singleImage?.name}
				description={singleImage?.description}
				ogTitle={singleImage?.name}
				ogDescription={singleImage?.description}
				ogImage={singleImage?.image_url}
			/>
			<div className='w-full px-4 md:pb-12 lg:px-8'>
				<div className='flex flex-col'>
					{typeImage === 'draft' && (
						<button onClick={() => navigate(`/admin/published/${id}`)}>
							editar draft image
						</button>
					)}
					<div className='md:hidden'>
						<LeftArrowSmall
							className='cursor-pointer mb-4'
							onClick={() => {
								typeImage === 'PROPOSED'
									? navigate('/admin/proposed')
									: navigate('/');
							}}
						/>
						<TitleNumberCollection
							title={singleImage?.code ?? 'Código desconocido'}
							number={singleImage?.consecutive ?? '0000'}
							mainDescription={
								i18n.language === 'es' ? singleImage.name : singleImage.name_en
							}
						/>
						<div className='pb-4 w-full h-full flex justify-center'>
							{renderSwitch(sectionCollection, mobile)}
						</div>
						<TagBar
							sections={
								i18n.language === 'es' ? tagBarSections : tagBarSectionsEn
							}
							section={sectionCollection}
							setSection={setSectionCollection}
							design='w-full flex flex-row  pb-4'
							photo={singleImage.image_url ? true : false}
							sketch={
								singleImage.architectural_image_url !== 'No_url' ? true : false
							}
							sectionIndex={sectionIndex}
							setSectionIndex={setSectionIndex}
							typeImage={typeImage}
						/>
						{
							//mobile
							typeImage === 'PUBLISHED' && elements && elements.length > 0 && (
								<ButtonAction
									sketchOn={
										sectionCollection === 'Sketch' ||
										sectionCollection === 'Dibujo'
											? true
											: false
									}
									imgToDownload={singleImage.image_url}
									sketchToDownload={singleImage.architectural_image_url}
									typeImage={typeImage}
									setSection={setSectionCollection}
									setSectionIndex={setSectionIndex}
									singleImage={singleImage}
								/>
							)
						}
						<InputTextArea
							title={t('Ficha.descripcion')}
							textInfo={
								i18n.language === 'es'
									? singleImage.description
									: singleImage.description_en
							}
						/>
						{imageAuthor?.toLowerCase() !== 'ximena ocampo' && (
							<p
								className='Abreviaciones mb-4 font-medium leading-[1.125rem]'
								style={{ fontSize: '15px' }}
							>
								{imageAuthor && imageAuthor !== ''
									? `Fotografía enviada por: ${imageAuthor}`
									: 'Fotografía anónima'}
							</p>
						)}
					</div>

					<div className='hidden md:block 3xl:hidden'>
						<LeftArrowSmall
							className='cursor-pointer mb-4'
							onClick={() => {
								typeImage === 'PROPOSED'
									? navigate('/admin/proposed')
									: navigate('/');
							}}
						/>
						<H3TextWeb design='font-normal mb-4 ml-10 text-azul-50'>
							{i18n.language === 'es' ? singleImage?.name : singleImage.name_en}
						</H3TextWeb>
						<div className='w-full flex flex-row h-100 xl:h-125 mb-4'>
							<div className='flex flex-col justify-between mr-4 w-6'>
								<HighText
									design='font-normal text-azul-50 transform rotate-180'
									style={{
										writingMode: 'vertical-rl',
										textOrientation: 'mixed',
									}}
								>
									{singleImage?.consecutive ?? '0000'}
								</HighText>
								<HighText
									design='font-normal text-azul-50 transform rotate-180'
									style={{
										writingMode: 'vertical-rl',
										textOrientation: 'mixed',
									}}
								>
									{singleImage.code ?? 'Código desconocido'}
								</HighText>
							</div>
							<div className='w-full lg:w-160 xl:w-205 h-full flex flex-col justify-center items-center md:justify-end'>
								<div className='flex mx-auto xl:hidden'>
									{renderSwitch(sectionCollection, ipad)}
								</div>
								<div className='hidden mx-auto xl:flex'>
									{renderSwitch(sectionCollection, desktop)}
								</div>
							</div>
							<div className='w-2/4 lg:w-1/2 ml-4 flex flex-col self-end'>
								<InputTextArea
									title={t('Ficha.descripcion')}
									textInfo={
										i18n.language === 'es'
											? singleImage.description
											: singleImage.description_en
									}
								/>

								{imageAuthor?.toLowerCase() !== 'ximena ocampo' && (
									<p
										className='Abreviaciones mb-4 font-medium leading-[1.125rem]'
										style={{ fontSize: '15px' }}
									>
										{imageAuthor && imageAuthor !== ''
											? `Fotografía enviada por: ${imageAuthor}`
											: 'Fotografía anónima'}
									</p>
								)}
								{singleImage.id && (
									<ButtonAction
										sketchOn={
											sectionCollection === 'Sketch' ||
											sectionCollection === 'Dibujo'
												? true
												: false
										}
										imgToDownload={singleImage.image_url}
										sketchToDownload={singleImage.architectural_image_url}
										typeImage={typeImage}
										setSection={setSectionCollection}
										setSectionIndex={setSectionIndex}
										singleImage={singleImage}
									/>
								)}
							</div>
						</div>
						<TagBar
							sections={
								i18n.language === 'es' ? tagBarSections : tagBarSectionsEn
							}
							section={sectionCollection}
							setSection={setSectionCollection}
							design='w-2/5 md:w-2/4 lg:w-2/5 xl:w-3/8 2xl:w-2/5 flex flex-row  pb-4 ml-11'
							photo={singleImage.image_url ? true : false}
							sketch={
								singleImage.architectural_image_url !== 'No_url' ? true : false
							}
							sectionIndex={sectionIndex}
							setSectionIndex={setSectionIndex}
							typeImage={typeImage}
						/>
					</div>

					<div className='hidden 3xl:block'>
						<LeftArrowSmall
							className='cursor-pointer mb-4'
							onClick={() => {
								typeImage === 'PROPOSED'
									? navigate('/admin/proposed')
									: navigate('/');
							}}
						/>
						<H3TextWeb design='font-normal mb-4 ml-10 text-azul-50' style={{}}>
							{i18n.language === 'es' ? singleImage.name : singleImage.name_en}
						</H3TextWeb>
						<div className='w-full flex flex-row h-188 mb-4'>
							<div className='w-2/4 flex flex-row'>
								<div className='flex flex-col justify-between mr-4 w-6'>
									<HighText
										design='font-normal text-azul-50 transform rotate-180'
										style={{
											writingMode: 'vertical-rl',
											textOrientation: 'mixed',
										}}
									>
										{singleImage.consecutive ? singleImage.consecutive : '0000'}
									</HighText>
									<HighText
										design='font-normal text-azul-50 transform rotate-180'
										style={{
											writingMode: 'vertical-rl',
											textOrientation: 'mixed',
										}}
									>
										{singleImage.code ? singleImage.code : 'Código desconocido'}
									</HighText>
								</div>
								<div className='w-236 h-full flex flex-col justify-center items-center'>
									<div className='flex mx-auto xl:hidden'>
										{renderSwitch(sectionCollection, ipad)}
									</div>
									<div className='hidden mx-auto xl:flex'>
										{renderSwitch(sectionCollection, desktop)}
									</div>
								</div>
							</div>
							<div className='w-2/4 relative ml-4'>
								<InputTextArea
									title={t('Ficha.descripcion')}
									textInfo={
										i18n.language === 'es'
											? singleImage.description
											: singleImage.description_en
									}
									design='h-1/4'
								/>

								{imageAuthor?.toLowerCase() !== 'ximena ocampo' && (
									<p
										className='Abreviaciones mb-4 font-medium leading-[1.125rem]'
										style={{ fontSize: '15px' }}
									>
										{imageAuthor && imageAuthor !== ''
											? `Fotografía enviada por: ${imageAuthor}`
											: 'Fotografía anónima'}
									</p>
								)}
								{singleImage.id && (
									<ButtonAction
										sketchOn={
											sectionCollection === 'Sketch' ||
											sectionCollection === 'Dibujo'
												? true
												: false
										}
										imgToDownload={singleImage.image_url}
										sketchToDownload={singleImage.architectural_image_url}
										typeImage={typeImage}
										setSection={setSectionCollection}
										setSectionIndex={setSectionIndex}
										singleImage={singleImage}
									/>
								)}

								{typeImage === 'PUBLISHED' && (
									<CategoryName
										data={singleImage}
										atributesOne={
											i18n.language === 'es' ? dataColors : dataColorsEn
										}
										atributesTwo={
											i18n.language === 'es' ? dataMaterials : dataMaterialsEn
										}
										categoryData={galerydata[0].filterTags}
										keyConceptsAttribute={
											i18n.language === 'es'
												? dataKeyConcepts
												: dataKeyConceptsEn
										}
										OccupationAttribute={
											i18n.language === 'es'
												? dataOccupations
												: dataOccupationsEn
										}
										levelData={levelData}
										spaceLevelData={spaceData}
									/>
								)}
								{typeImage === 'PROPOSED' && (
									<CategoryNameProposed
										data={singleImage}
										categoryData={galerydata[0].filterTags}
									/>
								)}
								{typeImage === 'PROPOSED' && (
									<div className='flex flex-row justify-between md:w-80'>
										<ButtonPrincipal
											text='Rechazar'
											type='Outline'
											action={() => rejectElement()}
											disabled={isSubmitting}
										/>
										<ButtonPrincipal
											text='Aceptar'
											action={() => acceptElement()}
											disabled={isSubmitting}
										/>
									</div>
								)}
							</div>
						</div>
						<TagBar
							sections={
								i18n.language === 'es' ? tagBarSections : tagBarSectionsEn
							}
							section={sectionCollection}
							setSection={setSectionCollection}
							design='w-full flex flex-row pb-4 pl-11'
							photo={singleImage.image_url ? true : false}
							sketch={
								singleImage.architectural_image_url !== 'No_url' ? true : false
							}
							sectionIndex={sectionIndex}
							setSectionIndex={setSectionIndex}
							typeImage={typeImage}
						/>
					</div>

					<div className='3xl:hidden'>
						{typeImage === 'PUBLISHED' && (
							<CategoryName
								data={singleImage}
								atributesOne={
									i18n.language === 'es' ? dataColors : dataColorsEn
								}
								atributesTwo={
									i18n.language === 'es' ? dataMaterials : dataMaterialsEn
								}
								categoryData={galerydata[0].filterTags}
								levelData={levelData}
								spaceLevelData={spaceData}
								keyConceptsAttribute={
									i18n.language === 'es' ? dataKeyConcepts : dataKeyConceptsEn
								}
								OccupationAttribute={
									i18n.language === 'es' ? dataOccupations : dataOccupationsEn
								}
							/>
						)}
						{typeImage === 'PROPOSED' && (
							<CategoryNameProposed
								data={singleImage}
								categoryData={galerydata[0].filterTags}
							/>
						)}
						{typeImage === 'PROPOSED' && (
							<div className='flex flex-row justify-between md:w-80'>
								<ButtonPrincipal
									text='Rechazar'
									type='Outline'
									action={() => rejectElement()}
									disabled={isSubmitting}
								/>
								<ButtonPrincipal
									text='Aceptar'
									action={() => acceptElement()}
									disabled={isSubmitting}
								/>
							</div>
						)}
					</div>
				</div>
				{modalFullImage && (
					<ImageModal
						imgrender={imageModalRender(sectionCollection)}
						name={singleImage.name}
						actionClose={() => setModalFullImage(false)}
						modalFullImage={modalFullImage}
					/>
				)}
			</div>
		</GeneralTemplate>
	);
}
