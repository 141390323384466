import { ErrorMessage, Field } from 'formik';

import B1Text from '../../atoms/text/B1Text';

export default function InputBoxDropdown({
	label,
	dbName,
	fieldType = 'Text',
	design,
	selectValues,
	multiple = false,
	formProps,
	allLevelsObject,
	parentLevel,
	parentValue,
}) {
	let finalValues = selectValues;

	if (parentLevel && allLevelsObject[parentLevel].length > 1) {
		let parentId = allLevelsObject[parentLevel].find(
			(element) =>
				element.label.replace(/\s/g, '') ===
				formProps.values[parentValue].replace(/\s/g, '')
		);
		finalValues = selectValues
			.filter((element) => element.parent_id === parentId.id)
			.sort((a, b) => a.label.localeCompare(b.label));

		if (finalValues.length < 1) return null;
	}

	return (
		<div className={`${design} w-full`}>
			<div>
				<B1Text design={`w-full mb-1`}>{label}</B1Text>
				<Field
					name={dbName}
					type={fieldType}
					multiple={multiple}
					className={`w-full p-2 B1Text rounded bg-white border border-azul-50 active:bg-white focus:border-azul-50 pl-1 ${
						multiple && 'h-30'
					}`}
					as={'select'}
				>
					<option key={0} value={''}>
						{''}
					</option>
					{finalValues?.map((selectValue, index) => (
						<option key={index + 1} value={selectValue.value}>
							{selectValue.label}
						</option>
					))}
				</Field>
			</div>
			<B1Text design={`w-full mt-1 text-azul-50`}>
				<ErrorMessage name={dbName} />
			</B1Text>
		</div>
	);
}
