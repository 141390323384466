import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
	ENDPOINTS_AGENTS,
	ENDPOINTS_CATEGORIES,
	ENDPOINTS_COLORS,
	ENDPOINTS_COUNTRIES,
	ENDPOINTS_LOCATIONS,
	ENDPOINTS_MATERIALS,
	ENDPOINTS_OCCUPATIONS,
	ENDPOINTS_KEY_CONCEPTS,
	ENDPOINTS_PERMANENCES,
	ENDPOINTS_SPACES,
	ENDPOINTS_TEMPORALITIES,
	ENDPOINTS_OCCUPATION_OBJECTS,
} from '../../helpers/routes';
import { fetchGetData } from '../../helpers/fetchHooks';

export const getCountries = createAsyncThunk(
	'staticCountries/fetchStaticCountries',
	async () => {
		return await fetchGetData(ENDPOINTS_COUNTRIES.GET_COUNTRIES);
	}
);

export const getLocations = createAsyncThunk(
	'staticLocations/fetchStaticLocations',
	async () => {
		return await fetchGetData(ENDPOINTS_LOCATIONS.GET_LOCATIONS);
	}
);

export const getSpaces = createAsyncThunk(
	'staticSpaces/fetchStaticSpaces',
	async () => {
		return await fetchGetData(ENDPOINTS_SPACES.GET_ALL_SPACES);
	}
);

export const getOccupationTypes = createAsyncThunk(
	'staticOccupations/fetchStaticOccupationTypes',
	async () => {
		return await fetchGetData(ENDPOINTS_OCCUPATIONS.GET_OCCUPATIONS);
	}
);
export const getKeyConcepts = createAsyncThunk(
	'staticOcupations/fetchStaticKeyConcepts',
	async () => {
		return await fetchGetData(ENDPOINTS_KEY_CONCEPTS.GET_KEY_CONCEPTS);
	}
);
export const getOccupationObjects = createAsyncThunk(
	'staticOcupations/fetchStaticOcupations',
	async () => {
		return await fetchGetData(
			ENDPOINTS_OCCUPATION_OBJECTS.GET_OCCUPATION_OBJECTS
		);
	}
);

export const getTemporalities = createAsyncThunk(
	'staticTemporalities/fetchStaticTemporalities',
	async () => {
		return await fetchGetData(ENDPOINTS_TEMPORALITIES.GET_TEMPORALITIES);
	}
);

export const getPermanence = createAsyncThunk(
	'staticPermanences/fetchStaticPermanences',
	async () => {
		return await fetchGetData(ENDPOINTS_PERMANENCES.GET_PERMANENCES);
	}
);

export const getAgents = createAsyncThunk(
	'staticAgents/fetchStaticAgents',
	async () => {
		return await fetchGetData(ENDPOINTS_AGENTS.GET_AGENTS);
	}
);

export const getCategories = createAsyncThunk(
	'staticCategories/fetchStaticCategories',
	async () => {
		return await fetchGetData(ENDPOINTS_CATEGORIES.GET_ALL_CATEGORIES);
	}
);

export const getColors = createAsyncThunk(
	'staticColors/fetchStaticColors',
	async () => {
		return await fetchGetData(ENDPOINTS_COLORS.GET_COLORS);
	}
);

export const getMaterials = createAsyncThunk(
	'staticMaterials/fetchStaticMaterials',
	async () => {
		return await fetchGetData(ENDPOINTS_MATERIALS.GET_MATERIAL);
	}
);

const initialState = {
	countries: null,
	locations: null,
	spaces: null,
	occupationTypes: null,
	keyConcepts: null,
	temporalities: null,
	permanence: null,
	agents: null,
	categories: null,
	colors: null,
	materials: null,
	occupationObjects: null,
};

export const staticDataSlice = createSlice({
	name: 'staticData',
	initialState,
	reducers: {
		cleanData: (state, { payload }) => {
			state.countries = [];
			state.locations = [];
			state.spaces = [];
			state.occupationTypes = [];
			state.temporalities = [];
			state.permanence = [];
			state.agents = [];
			state.categories = [];
			state.colors = [];
			state.materials = [];
			state.keyConcepts = [];
		},
	},
	extraReducers: {
		[getCountries.pending]: (state) => {
			state.status = 'loading';
		},
		[getCountries.fulfilled]: (state, { payload }) => {
			state.countries = payload.sort((a, b) =>
				a['label'].localeCompare(b['label'])
			);
			state.status = 'success';
		},
		[getCountries.rejected]: (state) => {
			state.status = 'failed';
		},

		[getLocations.pending]: (state) => {
			state.status = 'loading';
		},
		[getLocations.fulfilled]: (state, { payload }) => {
			state.locations = payload.sort((a, b) =>
				a['label'].localeCompare(b['label'])
			);
			state.status = 'success';
		},
		[getLocations.rejected]: (state) => {
			state.status = 'failed';
		},

		[getSpaces.pending]: (state) => {
			state.status = 'loading';
		},
		[getSpaces.fulfilled]: (state, { payload }) => {
			state.spaces = payload;
			state.status = 'success';
		},
		[getSpaces.rejected]: (state) => {
			state.status = 'failed';
		},

		[getOccupationTypes.pending]: (state) => {
			state.status = 'loading';
		},
		[getOccupationTypes.fulfilled]: (state, { payload }) => {
			state.occupationTypes = payload;
			state.status = 'success';
		},
		[getOccupationTypes.rejected]: (state) => {
			state.status = 'failed';
		},
		[getKeyConcepts.pending]: (state) => {
			state.status = 'loading';
		},
		[getKeyConcepts.fulfilled]: (state, { payload }) => {
			state.keyConcepts = payload.sort((a, b) =>
				a['label'].localeCompare(b['label'])
			);
			state.status = 'success';
		},
		[getKeyConcepts.rejected]: (state) => {
			state.status = 'failed';
		},

		[getTemporalities.pending]: (state) => {
			state.status = 'loading';
		},
		[getTemporalities.fulfilled]: (state, { payload }) => {
			state.temporalities = payload;
			state.status = 'success';
		},
		[getTemporalities.rejected]: (state) => {
			state.status = 'failed';
		},

		[getPermanence.pending]: (state) => {
			state.status = 'loading';
		},
		[getPermanence.fulfilled]: (state, { payload }) => {
			state.permanence = payload;
			state.status = 'success';
		},
		[getPermanence.rejected]: (state) => {
			state.status = 'failed';
		},

		[getAgents.pending]: (state) => {
			state.status = 'loading';
		},
		[getAgents.fulfilled]: (state, { payload }) => {
			state.agents = payload;
			state.status = 'success';
		},
		[getAgents.rejected]: (state) => {
			state.status = 'failed';
		},

		[getCategories.pending]: (state) => {
			state.status = 'loading';
		},
		[getCategories.fulfilled]: (state, { payload }) => {
			state.categories = payload;
			state.status = 'success';
		},
		[getCategories.rejected]: (state) => {
			state.status = 'failed';
		},

		[getColors.pending]: (state) => {
			state.status = 'loading';
		},
		[getColors.fulfilled]: (state, { payload }) => {
			state.colors = payload.sort((a, b) =>
				a['label'].localeCompare(b['label'])
			);
			state.status = 'success';
		},
		[getColors.rejected]: (state) => {
			state.status = 'failed';
		},

		[getMaterials.pending]: (state) => {
			state.status = 'loading';
		},
		[getMaterials.fulfilled]: (state, { payload }) => {
			state.materials = payload.sort((a, b) =>
				a['label'].localeCompare(b['label'])
			);
			state.status = 'success';
		},
		[getMaterials.rejected]: (state, action) => {
			state.status = 'failed';
		},
		[getOccupationObjects.pending]: (state) => {
			state.status = 'loading';
		},
		[getOccupationObjects.fulfilled]: (state, { payload }) => {
			state.occupationObjects = payload.sort((a, b) =>
				a['label'].localeCompare(b['label'])
			);
			state.status = 'success';
		},
		[getOccupationObjects.rejected]: (state, action) => {
			state.status = 'failed';
		},
	},
});
export const { cleanData } = staticDataSlice.actions;

export default staticDataSlice.reducer;
