import { useTranslation } from 'react-i18next';
// BL
// UI
import B1Text from '../../atoms/text/B1Text';
import ImageDescription from '../../molecules/Image/ImageDescription';
import ImageSelection from '../../molecules/Image/ImageSelection';

export default function Gallery({
	filteredElements,
	pageTitle,
	redirect,
	showFilter,
}) {
	const { i18n } = useTranslation();

	return (
		<>
			{filteredElements?.length > 0 ? (
				<div
					className={`min-h-screen md:min-h-0 px-4 md:px-8 grid gap-3  ${
						pageTitle === 'selectionImages' ? 'grid-cols-3' : 'grid-cols-1'
					} ${
						showFilter && pageTitle === 'home'
							? 'md:w-2/3 md:grid-cols-3 lg:grid-cols-4'
							: 'md:grid-cols-5 lg:grid-cols-6'
					}   `}
					data-cy='main-image-grid'
				>
					{filteredElements.map(({ image_url, name, description, id }, index) =>
						pageTitle === 'selectionImages' ? (
							<ImageSelection
								key={index}
								id={id}
								imagen={image_url}
								name={name}
							/>
						) : (
							<ImageDescription
								key={index}
								id={id}
								imagen={image_url}
								description={description}
								name={name}
								section={pageTitle}
								redirect={redirect}
							/>
						)
					)}
				</div>
			) : (
				<div className='flex justify-center'>
					<B1Text design='font-normal group-hover:text-azul-50 cursor-pointer'>
						{i18n.language === 'es'
							? 'No hemos encontrado imágenes con esas características.'
							: "We haven't found any image with those characteristics."}
					</B1Text>
				</div>
			)}
		</>
	);
}
