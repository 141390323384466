import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// BL
import {
	changeCollectionState,
	selectionFiles,
	createTempImageList,
	ResetImageList,
	clearCounter,
	clearImagesList,
} from '../../../business_logic/redux/reducers/collection';
import {
	fetchGetData,
	fetchPostData,
} from '../../../business_logic/helpers/fetchHooks';
import { ENDPOINTS_COLLECTIONS } from '../../../business_logic/helpers/routes';
import { getCollection } from '../../../business_logic/redux/reducers/collection';
// UI
import GeneralTemplate from '../../templates/GeneralTemplate';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import { ReactComponent as EmptyCollectionIcon } from '../../assets/EmptyCollec.svg';
import PopUp from '../../organisms/PopUp/PopUp';
import GalleryCollection from '../../organisms/Gallery/GalleryCollection';
import ButtonSecondary from '../../atoms/button/ButtonSecondary';

export default function NewCollectionEdit() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const {
		newCollectionName,
		newCollectionState,
		imagesList,
		editSelectedImage,
	} = useSelector((state) => state.mycollection);

	const [showPopUpPublish, setShowPopUpPublish] = useState(false);
	const [showPopUpEdit, setShowPopUpEdit] = useState(false);

	return (
		<GeneralTemplate
			section='new-collection'
			navBarTitle={newCollectionName}
			showSecondNavbar={true}
			redirectIcon='/new-collection/edit/selection_images'
			showLeftArrow={newCollectionState === 'EDIT' ? false : true}
			showFilter={false}
			menuVisitor={true}
		>
			<div className='w-full px-4 flex flex-col justify-center items-center mt-4'>
				{imagesList.length > 0 ? (
					<GalleryCollection
						imageCollection={imagesList}
						showDescription={false}
						newCollectionState={newCollectionState}
						setShowPopUpEdit={setShowPopUpEdit}
					/>
				) : (
					<Link to='/new-collection/edit/selection_images'>
						<EmptyCollectionIcon />
					</Link>
				)}

				{newCollectionState === 'PROPOSED' && imagesList.length > 0 && (
					<div className='flex flex-row justify-between w-full md:w-80'>
						<ButtonPrincipal
							text={t('NuevaColeccionOtro.editar')}
							type='Outline'
							action={() => {
								dispatch(changeCollectionState('EDIT'));
								dispatch(createTempImageList(imagesList));
							}}
						/>
						<ButtonPrincipal
							text={t('NuevaColeccionOtro.publicar')}
							action={() => setShowPopUpPublish(true)}
						/>
					</div>
				)}

				{newCollectionState === 'EDIT' && (
					<div className='w-full flex flex-row justify-between md:w-80'>
						<ButtonPrincipal
							text={t('NuevaColeccionOtro.cancelar')}
							type='Outline'
							action={() => {
								dispatch(changeCollectionState('PROPOSED'));
								dispatch(ResetImageList());
							}}
						/>
						<ButtonSecondary
							text={t('NuevaColeccionOtro.guardar')}
							defaultIcon={false}
							action={() => dispatch(changeCollectionState('PROPOSED'))}
						/>
					</div>
				)}

				{showPopUpPublish && (
					<PopUp
						setShowPopUp={setShowPopUpPublish}
						defaultIcon={false}
						title={t('NuevaColeccionOtro.publicarColeccion')}
						text={t('NuevaColeccionOtro.publicarTexto')}
						botonTextOne={t('NuevaColeccionOtro.cancelar')}
						botonTextTwo={t('NuevaColeccionOtro.publicar')}
						action={() => {
							const values = {
								collection_name: newCollectionName,
								element: imagesList,
							};
							if (imagesList.length > 0) {
								fetchPostData(
									ENDPOINTS_COLLECTIONS.POST_COLLECTION,
									values
								).then(() => {
									setTimeout(() => {
										fetchGetData(
											ENDPOINTS_COLLECTIONS.GET_CREATED_SINGLE_COLLECTION
										).then((res) => {
											const id = res;
											setShowPopUpPublish(false);
											dispatch(clearCounter(0));
											dispatch(clearImagesList());
											dispatch(getCollection());
											navigate(`/collection/${id}`);
										});
									}, 500);
								});
							} else alert(t('NuevaColeccionOtro.alerta'));
						}}
					/>
				)}

				{showPopUpEdit && (
					<PopUp
						setShowPopUp={setShowPopUpEdit}
						customIcon={true}
						defaultIcon={false}
						title={t('NuevaColeccionOtro.eliminarImagen')}
						text={t('NuevaColeccionOtro.eliminarTexto')}
						botonTextOne={t('NuevaColeccionOtro.cancelar')}
						botonTextTwo={t('NuevaColeccionOtro.eliminar')}
						action={() => {
							dispatch(selectionFiles(editSelectedImage));
							setTimeout(() => {
								setShowPopUpEdit(false);
							}, 500);
						}}
					></PopUp>
				)}
			</div>
		</GeneralTemplate>
	);
}
