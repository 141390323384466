export default function B2LightText({ children, design = '', style }) {
	return (
		<p
			className={`${design} tracking-wide text-xs lg:text-xs B2LightText`}
			style={style}
		>
			{children}
		</p>
	);
}
