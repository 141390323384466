import React from 'react';
import B1Text from '../../atoms/text/B1Text';

export default function InputChip({
	sectionName,
	setSection,
	photo,
	sketch,
	video,
	sound,
	sectionIndex,
	setSectionIndex,
	index,
}) {
	let hideButton = '';
	switch (sectionName) {
		case 'Fotografía':
		case 'Photo':
			if (!photo) hideButton = 'hidden';
			break;
		case 'Dibujo':
		case 'Sketch':
			if (!sketch) hideButton = 'hidden';
			break;
		case 'Video':
			if (!video) hideButton = 'hidden';
			break;
		case 'Sonido':
		case 'Sound':
			if (!sound) hideButton = 'hidden';
			break;
		default:
			return null;
	}

	return (
		<div
			className={`${hideButton} mr-4`}
			onClick={() => {
				setSection(sectionName);
				setSectionIndex(index);
			}}
		>
			<B1Text
				design={
					index === sectionIndex
						? 'text-azul-50 cursor-pointer'
						: 'text-black cursor-pointer'
				}
			>
				{sectionName}
			</B1Text>
		</div>
	);
}
