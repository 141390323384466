import React from 'react';
import ButtonPrincipal from '../../atoms/button/ButtonPrincipal';
import H1Principal from '../../atoms/text/H1Principal';
import B2LightText from '../../atoms/text/B2LightText';

const ModalConfirmationPhotoSend = ({
	design,
	title,
	text,
	textSeeImages,
	textSendOther,
	redirect,
	setShowConfirmationModal,
}) => {
	return (
		<div
			className={` bg-white border  flex flex-col justify-center w-[348px] h-44 shadow-lg rounded-md ${design}`}
		>
			<div className='flex flex-col text-center gap-2 py-3 w-full'>
				<H1Principal>{title}</H1Principal>
				<B2LightText>{text}</B2LightText>
			</div>
			<div className='flex flex-row justify-around md:justify-between py-4 px-5 w-full'>
				<ButtonPrincipal
					design='text-sm'
					text={textSeeImages}
					type='Outline'
					action={() => redirect('/')}
				/>
				<ButtonPrincipal
					design='text-sm'
					text={textSendOther}
					type='Filled'
					action={() => {
						window.location.reload();
					}}
				/>
			</div>
		</div>
	);
};

export default ModalConfirmationPhotoSend;
