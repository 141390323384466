import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	filterArray: {},
	arrayVariables: [],
	showFilter: false,
	nameFilter: '',
};

export const filterSlice = createSlice({
	name: 'FilterConfiguration',
	initialState,
	reducers: {
		setFilterArray: (state, { payload }) => {
			state.filterArray = payload;
		},
		setArrayVariables: (state, { payload }) => {
			state.arrayVariables = payload;
		},
		filterDisplay: (state, action) => {
			state.showFilter = action.payload;
		},
		cleanAllFilters: (state) => {
			state.filterArray = {};
			state.nameFilter = '';
			state.arrayVariables = [];
		},
		setNewVariableToArray: (state, { payload }) => {
			let auxArray = state.arrayVariables;
			auxArray.push(payload);
			state.arrayVariables = auxArray;
		},
		setNameFilter: (state, { payload }) => {
			state.nameFilter = payload;
		},
	},
});

export const {
	setFilterArray,
	setArrayVariables,
	filterDisplay,
	cleanAllFilters,

	setNewVariableToArray,
	setNameFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
