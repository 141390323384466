import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
// BL
import { addValueToFilters } from '../../../business_logic/helpers/filterHelpers';

export default function B1Text({
	children,
	design = '',
	style,
	labelFilter,
	valueToFilter,
	needsRedirection,
}) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { filterArray, arrayVariables } = useSelector((state) => state.filter);

	const handleClick = () => {
		if (needsRedirection && labelFilter) {
			addValueToFilters({}, [], labelFilter, valueToFilter, dispatch);
			navigate('/');
		}
		if (!needsRedirection && labelFilter) {
			addValueToFilters(
				filterArray,
				arrayVariables,
				labelFilter,
				valueToFilter,
				dispatch
			);
		}
	};

	return (
		<div
			className={`B1Text max-w-max ${design} `}
			onClick={handleClick}
			style={style}
		>
			{children}
		</div>
	);
}
