import React from 'react';
import B1Text from '../../atoms/text/B1Text';

export default function InputTextArea({ title, textInfo, design = '' }) {
	return (
		<div className={`w-full flex flex-row justify-between ${design}`}>
			<B1Text design='w-5/12 mb-1'>{title}</B1Text>
			<B1Text design='w-full font-normal '>{textInfo}</B1Text>
		</div>
	);
}
