import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	status2: '',
	typePage: '',
};

export const PageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		setStatus: (state, { payload }) => {
			state.status2 = payload;
		},
		setTypePage: (state, { payload }) => {
			state.typePage = payload;
		},
	},
});
export const { setStatus, setTypePage } = PageSlice.actions;

export default PageSlice.reducer;
