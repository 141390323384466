import React from 'react';
import { Link } from 'react-router-dom';

export default function LinkText({ children, path, design, action }) {
	return (
		<div className={design} onClick={action}>
			<Link to={path}>{children}</Link>
		</div>
	);
}
