export const TRANSLATIONS_EN = {
	NavBar: {
		inicio: 'Home',
		acercaDe: 'About Us',
		miColeccion: 'My collection',
		enviar: 'Send [another]',
	},
	Footer: {
		PropiedadIntelectual: 'Intellectual property',
		condicionesDeUso: 'Terms of use',
		politicaDePrivacidad: 'Privacy policy ',
		todosLosDerechosReservados: 'All rights reserved',
		DesarrolladoPorIT: 'Developed by it looks simple',
		TérminosLegales: 'Legal terms',
	},
	Ficha: {
		descripcion: 'Description',
		año: 'Year',
		ubicacion: 'Location',
		espacio: 'Space',
		tipoDeOcupacion: 'Type of Occupation',
		temporalidad: 'Temporality',
		permanencia: 'Permanence',
		agentes: 'Agent',
		actividad: 'Activity',
		atributosFisicos: 'Physical Attributes',
		objetosDeOcupacion: 'Occupation Objects',
		conceptosClave: 'Key concepts',
		pais: 'Country',
		ninguno: 'None',
	},
	FichaHover: {
		agregar: 'Add image to my album',
		ver: 'See group of related images',
		anterior: 'Previous',
		siguiente: 'Next',
		descargarDibujo: 'Download sketch',
	},
	Filtro: {
		buscar: 'Search',
		objetos: 'Objects',
		categoria: 'Category',
		ocupaciones: 'occupations',
		limpiar: 'Clear',
	},
	ProponerFoto: {
		enviarOtra: 'Send [other]',
		agregarFoto: 'Add picture',
		nombreDeOcupacion: 'Occupation name',
		ubicacion: 'Location<1>*</1>',
		descripción: 'Description',
		datosPersonales: 'Personal information',
		nombreOSeudonimo: 'Name or pseudonym',
		email: 'E-mail<1>*</1>',
		ImagenEnviada: 'Image sent!',
		QueDeseasHacerAhora: 'What do you want to do now?',
		VerImágenes: 'See images',
		autorización:
			'By submitting this photograph I authorize the <1>terms of use *</1>',
		enviar: 'Send',
		fotoEnviada: 'We have received your picture. Thanks!',
		agregaEmail: 'Please add an email.',
		emailCorrecto: 'Please add a correct email.',
		agregaImagen: '*Please add an image.',
		MínimoCaracteres: 'Minimum more than two characters',
		UbicaciónRequerida: 'Please add a location',
		TérminosCondicionesUso: 'The terms and conditions must be accepted.',
		CamposObligatorios: '*Please complete required fields',
	},
	AcercaDe: {
		acercaDe: 'About',
		s1p1: 'The project [other] ways to occupy public space  is a catalogue of uses, objects, elements and actions of occupation and appropriation of public space; a detailed account, almost an inventory, of current uses including those unintended, spontaneous or unplanned. Its main objective is to critically analyse and vindicate forms and daily actions that give vibrance to cities and configure their public sphere.',
		s1p2: 'The project’s digital archive, composed of photographs, architectural drawings, videos, sounds, as well as short titles and descriptions, has been systematised using a faceted -and non-hierarchical- classification, which is made up of a set of categories and subcategories.',
		s1p3: 'The use of this method of organization, in addition to producing a dynamic form of representation, has led to an analytical-synthetic process that consists of the identification of the concepts that constitute each category, as well as relationships among them<1>1</1>. In addition, it presents a dynamic opportunity for continuity, in which it is possible to add concepts and relationships indefinitely, in case there are ways of occupation that have not been identified so far.',
		t2: 'How to read the categories',
		s2p1: 'The categories proposed here cover different characteristics of all modes of occupation, which are summarised as follows:',
		s2p1a: '1) character of the subjects or objects.',
		s2p1b: '2) energy, or any action that occurs with respect to them.',
		s2p1c:
			'3) space, the geographic component of the location of the subject or object.',
		s2p1d:
			'4) time, period associated with the subject or object. The categories proposed are the following:',
		t3: 'Geographical location [U]',
		s3p1: 'Geographical location describes the location of each occupation, specifically the city where it was identified. In addition to geographically describing the forms of occupation, this category makes it possible to identify (to map) similarities and differences between them: for example, despite the fact that the use of cargo bicycles is very common throughout Mexico, through this geographic analysis, it is understood  that, while in cities like Guadalajara, these are used to sell tejuinos, in Playa del Carmen they are used to sell marquesitas, flowers and plants. The same object reproduces different cultural and / or economic values, which are presented as subjective in the analysis.',
		t4: 'Temporality [T]',
		s4p1: 'Temporality describes the frequency with which a form of occupation is carried out. It can refer to a one-time, occasional, seasonal, recurring, or permanent occupation. Temporality has to do once again with economic, social and cultural characteristics, which define if a festivity is held annually or seasonally, if the sale of products is carried out permanently or occasionally, or if a person visits a particular corner of the city daily or sporadically.',
		t5: 'Permanencia [P]',
		s5p1: 'Permanence can be considered a spatio-temporal characteristic of the forms of occupation that allows to know if such occupation is generated through an object or subject that is in motion, that is fixed but could move or, if it is fixed without the possibility of change. This characteristic defines the ways of occupation in terms of the flexibility or capacity for change.',
		t6: 'Activity [AC]',
		s6p1: "Activity speaks of the diversity of reasons why people occupy public space, which can range from waiting for the bus or changing a baby's diaper, to closing the street to play or protest. It is also a category that describes those practices that, although informal and quotidian, give value and heterogeneity to public space.",
		t7: 'Type de occupation [O]',
		s7p1: 'Type of occupation refers to the way or means by which a person occupies or reappropriates the space. It goes from the body itself -the body as an artifact or instrument, which allows the person to inhabit, use, fill, appropriate that space-, to the use of other elements -objects-, which can be found (readymade) or manufactured for a specific use. Finally, there is the hacking of infrastructure that implies implementing solutions for any system, so that it can be used in ways not intended by those who created the systems. This type of occupation does not necessarily imply the presence of the body, but rather the use of public infrastructure to carry out some specific activity, which has not been previously designed or planned.',
		t8: 'Physical attributes [AF]',
		s8p1: 'Physical attributes define the characteristics of the objects, artifacts and elements that consolidate a particular occupation. Highlighting the nature of an element, its composition and appearance, the material from which it is made and / or the predominant color, simply allows us to define those attributes that give subjective value and identity to the multiple ways of occupying the public space.',
		t9: 'Agents [AG]',
		s9p1: 'Agents describes whether it is an individual, groups or undefined way of occupation, in case it is not clear or is not of importance in certain modes of occupation. Defining it is important to understand the influence that a particular mode of occupation can have on a space.',
		t10: 'Space [E]',
		s10p1:
			'Space specifies the type of space in which the different forms of occupation take place. Knowing this information provides the possibility of analyzing whether there are certain places where some activities occur more frequently.',
		t11: 'Objects [OB]',
		s11p1:
			'Space specifies the type of space in which the different forms of occupation take place. Knowing this information provides the possibility of analyzing whether there are certain places where some activities occur more frequently.',
		t12: '-',
		s12p1:
			'The city today is only meant to be clean, safe, friendly and orderly<1>2</1>. In this sense, public spaces are conceived of as efficient, transitory, and have specific functions, turning the city into a marketable space in which it is intended to establish a hegemonic and conflict-free vision.<3>3</3>.',
		s12p2:
			'To guarantee the existence of these spaces, supposedly free of conflict, the city relies on regulations, signposting, surveillance cameras and the police; sometimes even the complicit observation of some citizens, who validate which activities are allowed and which ones are outright prohibited.',
		s12p3:
			'Thus, occupying public space in other ways -ways different to those established and designed- constitutes an attitude of resistance, which brings the possibility of ruptures and emancipations, of liberating and uncertain spontaneity.<1>4</1>.',
		s12p4:
			'This is precisely where the value of an inventory of [other] ways of occupying public space lies; representing this great variety of activities, actors and objects that make them possible, generates a radiography of current urban daily life; a life that persists despite endless mechanisms to control it and attempts to disappear it.',
		t13: '-',
		s13p1:
			'This project has been developed by Ximena Ocampo Aguilar thanks to the Young Creators Grant (FONCA) in its 2020-2021 edition, and the support of dérive LAB (Francisco Paillie, Jesús Ocampo, Jesús Méndez, Sofía Ávila and Woroud Ahdali), Hannah Yu-Pearson, Wendy Sánchez and Karla Velarde.',
		s13p2:
			"Ximena is an architect graduated from Tec de Monterrey, she holds a master's degree in City Design and Social Science from <1>the London School of Economics and Political Science</1>. She founded <3>Dérive LAB</3> in 2013, a laboratory that seeks to explore, understand and inspire other (new) ways of living and thinking about life in the City, where she works to date leading different projects with a focus on public space, active mobility and the relationship between people and space.",
		s13p3:
			"If you have any questions or comments, do not hesitate to contact her at <1>ximena@derivelab.org</1> or through the <3>project's instagram account.</3>",
		descargaTodos: 'Download all architectural drawings in DWG',
		descargar: 'Download',
		titleFooter: '-',
		footert1:
			'<0>1</0> Hjørland, Birger. <2>Facet analysis.</2> (International Society for Knowledge Organization).',
		footert2:
			'<0>2</0>  Sennett, Richard. <2>The open city.</2> (Urban Age, 2006).',
		footert3:
			'<0>3</0> Hernández, Adrián. <2>El Forat de la Vergonya: el conflicto entre la ciudad planificada y la ciudad habitada.</2> (Hábitat y sociedad, 2016), 37-53.',
		footert4:
			'<0>4</0> Martínez, Emilio. <2>Configuración urbana, habitar y apropiación del espacio.</2>(XIII Coloquio Internacional de Geocrítica. El control del espacio y los espacios de control: Universidad de Barcelona, 2014).',
	},
	MiColeccion: {
		miColeccion: 'Collections',
	},
	NuevaColecionModal: {
		nuevaColeccion: 'New collection',
		ingresaUnNombre: 'Add a name for the album',
		cancelar: 'Cancel',
		crearAlbum: 'Create album',
	},
	NuevaColeccionOtro: {
		añadirImagenes: 'Add images',
		archivosSelecionados: 'selected files',
		editar: 'Edit',
		publicar: 'Publish',
		guardar: 'Save',
		publicarColeccion: 'Publish Collection',
		publicarTexto:
			'When publishing your collection you will lose the editing options',
		cancelar: 'Cancel',
		eliminar: 'Delete',
		eliminarImagen: 'Delete image',
		eliminarTexto: 'this image will delete from the collection.',
		alerta: 'select at least one image',
		crearColección: 'Create collection',
		agregarImágenes: 'Add images',
	},
	Abreviaciones: {
		Year: 'Y',
		Country: 'C',
		Location: 'L',
		Space: 'S',
		'Type of Occupation': 'O',
		Temporality: 'T',
		Permanence: 'P',
		Agent: 'AG',
		Activity: 'AC',
		'Physical Attributes': 'PA',
		'Occupation Objects': 'OO',
		'Key concepts': 'KC',
	},
	AvisoLegal: { t1: '123' },
	TérminosCondiciones: {
		t1: 'Terms and conditions ',
		p1: 'By submitting photographic material through this site, you agree that: ',
		p2: 'You are at least 18 years of age. If you do not meet this condition, you may only participate under the supervision of an adult acting on your behalf. ',
		p3: 'You freely grant to dérive lab the right to use the material in any manner and in any medium, including, without limitation, the right to publish, adapt, distribute, copy, display in print or electronic media. In this case, dérive lab is responsible to mention the name or pseudonym of the participant if their photograph is published on this website or used in any material related to this project. ',
		p4: 'The photographic material submitted does not violate the rights of a third party or any copyright, and dérive lab cannot be held responsible for any intellectual property violations that may arise from the submission of the photographs. ',
		p5: 'You have read and understand these Terms and Conditions and agree to comply with them. ',
	},
	PolíticaDePrivacidad: {
		t1: 'Privacy policy',
		p1: 'dérive lab, with address at Privada Revillagigedo 10, San Francisquito, in Queretaro, postal code 76058, is the organization responsible for the processing of the personal data you provide, which will be protected in accordance to the Personal Data Protection Act in Mexico, and other applicable regulations. ',
	},
	PropiedadIntelectual: {
		t1: 'Intellectual property',
		p1: '<0></0>[other] ways to occupy public space by dérive lab is distributed under a Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International Licence. Based on the work at <1>https://otrasmanerasdeocuparelespaciopublico.com</1>',
		p2: 'The contents that are not under this license will be duly marked with the legend Derechos Reservados or the image ©.',
	},
	Cargando: {
		Cargando: 'Loading',
		CargarMas: 'Load More',
	},
};
