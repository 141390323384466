import NavbarPrimary from '../organisms/Navbar/NavbarPrimary';
import NavbarSecondary from '../organisms/Navbar/NavbarSecondary';

export default function AdminTemplate({
	children,
	navBarTitle,
	addImageIcon,
	redirectIcon,
	editImageIcon,
	showNavBarSecondary = true,
}) {
	return (
		<div className='flex'>
			<div className='w-full flex flex-col justify-center bg-white'>
				<NavbarPrimary hasFilter={false} menuAdmins={true} />
				{showNavBarSecondary && (
					<div className='pt-10'>
						<NavbarSecondary
							navBarTitle={navBarTitle}
							editImageIcon={editImageIcon}
							addImageIcon={addImageIcon}
							redirectIcon={redirectIcon}
						/>
					</div>
				)}
				<div
					className={`w-full m-auto px-6 ${
						showNavBarSecondary ? `md:px-14` : ` md:px-6`
					}`}
				>
					{children}
				</div>
			</div>
		</div>
	);
}
