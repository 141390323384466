import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
// BL
import {
	getSingleCollection,
	getSingleCollectionInfo,
} from '../../../business_logic/redux/reducers/collection';

// UI
import SharePrint from '../../molecules/Share/SharePrint';
import GalleryCollection from '../../organisms/Gallery/GalleryCollection';
import Loading from '../../organisms/Loading/Loading';
import GeneralTemplate from '../../templates/GeneralTemplate';
import ShareCollection from './ShareCollection';

export default function SingleCollectionView({ redirect, menuVisitor = true }) {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [showShareModal, setShowShareModal] = useState(false);

	const {
		singleCollection,
		collectionName,
		statusSingleCollectionInfo,
		statusSingleCollection,
	} = useSelector((state) => state.mycollection);

	useEffect(() => {
		try {
			dispatch(getSingleCollection(id));
			dispatch(getSingleCollectionInfo(id));
		} catch (error) {
			console.error(error);
		}
	}, [dispatch, id]);

	return (
		<GeneralTemplate
			section={'singleCollection'}
			menuVisitor={menuVisitor}
			showFilter={false}
			navBarTitle={
				statusSingleCollectionInfo === 'success'
					? collectionName[0].collection_name
					: 'Cargando'
			}
			showSecondNavbar={true}
			addImageIcon={false}
		>
			{statusSingleCollection === 'success' ? (
				<div className='flex flex-col gap-10'>
					<GalleryCollection
						imageCollection={singleCollection}
						redirect={redirect}
						showFilter={false}
						section='collection'
					/>
					<SharePrint
						showShareModal={showShareModal}
						setShowShareModal={setShowShareModal}
					/>
					{showShareModal && (
						<ShareCollection
							showShareModal={showShareModal}
							setShowShareModal={setShowShareModal}
						/>
					)}
				</div>
			) : (
				<Loading />
			)}
		</GeneralTemplate>
	);
}
