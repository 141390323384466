import B1Text from '../text/B1Text';

export default function ButtonPrincipal({
	text,
	typeButton = 'submit',
	type = 'Filled',
	action,
	style,
	design = 'mt-5',
	disabled,
}) {
	let buttonClasses = '';
	let textClasses = '';
	if (type === 'Filled') {
		buttonClasses = 'bg-azul-50';
		textClasses = 'text-white';
	} else if (type === 'Outline') {
		buttonClasses = 'bg-white border border-azul-50 active:bg-blue-200';
		textClasses = 'text-azul-50';
	} else if (type === 'Link') {
		buttonClasses = '';
		textClasses = 'text-azul-50 underline';
	} else if (type === 'Delete') {
		buttonClasses = 'bg-red-500';
		textClasses = 'text-white';
	}
	return (
		<button
			className={`${buttonClasses} rounded shadow_box w-32 md:w-36 h-10 ${design}`}
			type={typeButton}
			onClick={action}
			style={style}
			disabled={disabled}
		>
			<div className={'flex flex-row justify-center p-1 items-center'}>
				<B1Text design={textClasses}>{text}</B1Text>
			</div>
		</button>
	);
}
