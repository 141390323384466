// THIRD PARTY
import { useState } from 'react';

// BL

// UI
import B1Text from '../../../atoms/text/B1Text';
import ClickableChip from './ClickableChip';
import Abreviaciones from '../../../atoms/text/Abreviaciones';

export default function FilterChip({
	filterLetter,
	filterLabel,
	filterVariable,
	allElements,
	sortingOrder,
}) {
	const [showDropdown, setShowDropdown] = useState(false);

	let chipValues = [
		...new Set(allElements.map((item) => item[filterVariable])),
	].filter((value) => value);

	if (sortingOrder) {
		chipValues.sort(
			(a, b) => sortingOrder.indexOf(a) - sortingOrder.indexOf(b)
		);
	} else {
		chipValues.sort((a, b) => a.localeCompare(b));
	}

	if (chipValues.length < 1) return null;

	return (
		<div className={`flex flex-col gap-y-2`}>
			<div
				id={filterLetter}
				className='flex flex-row cursor-pointer hover:text-azul-50  max-w-max'
				onClick={() => setShowDropdown(!showDropdown)}
			>
				<B1Text design='flex flex-row gap-1'>
					{
						<Abreviaciones design='font-medium italic '>
							{filterLetter}
						</Abreviaciones>
					}
					{filterLabel}
				</B1Text>
			</div>
			{showDropdown && (
				<div className='flex flex-col gap-1 overflow-y-auto'>
					{chipValues.map((value, index) => (
						<ClickableChip
							key={index}
							label={value}
							valueToFilter={filterVariable}
						/>
					))}
				</div>
			)}
		</div>
	);
}
