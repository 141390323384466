import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// BL
import { counterFiles } from '../../../business_logic/redux/reducers/collection';
import { getElementsThroughPost } from '../../../business_logic/redux/reducers/elementsSlice';
import { multiPropsFilters } from '../../../business_logic/helpers/filterHelpers';

// UI
import Loading from '../../organisms/Loading/Loading';
import Gallery from '../../organisms/Gallery/Gallery';
import GeneralTemplate from '../../templates/GeneralTemplate';

export default function SelectionImages({
	url,
	status = false,
	menuVisitor = true,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const { imagesList } = useSelector((state) => state.mycollection);
	const { elements, showLoading } = useSelector((state) => state.elements);

	const { filterArray, nameFilter, showFilter } = useSelector(
		(state) => state.filter
	);

	useEffect(() => {
		if (elements.length < 1) {
			try {
				dispatch(
					getElementsThroughPost({
						url: url,
						status: status,
					})
				);
			} catch (error) {
				console.error(error);
			}
		}
	}, [elements, dispatch, url, status]);

	useEffect(() => {
		dispatch(counterFiles());
	}, [dispatch, imagesList]);

	const elementsFilteredByName = elements.filter(
		(element) =>
			element.name &&
			!element.name.toLowerCase().indexOf(nameFilter.toLowerCase())
	);
	const filteredElements = multiPropsFilters(
		elementsFilteredByName,
		filterArray
	);

	return (
		<GeneralTemplate
			section='selectionImages'
			navBarTitle={t('NuevaColeccionOtro.añadirImagenes')}
			showSecondNavbar={true}
			showThirdNavbar={true}
			navBarFiles={t('NuevaColeccionOtro.archivosSelecionados')}
			menuVisitor={menuVisitor}
			addImageIcon={false}
			showFilter={true}
		>
			{showLoading ? (
				<Loading />
			) : (
				<div className='pt-28 z-[2]'>
					<Gallery
						filteredElements={filteredElements}
						redirect
						showFilter={showFilter}
						pageTitle='selectionImages'
					/>
				</div>
			)}
		</GeneralTemplate>
	);
}
