import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// BL
import { changeSection } from '../../../business_logic/redux/reducers/collection';
import { cleanAllFilters } from '../../../business_logic/redux/reducers/filter';

// UI
import { ReactComponent as Cross } from '../../assets/icon/X.svg';
import B1Text from '../../atoms/text/B1Text';
import LinkText from '../../molecules/specialText/LinkText';

export default function Menu({ setShowMenu }) {
	const dispatch = useDispatch();
	const { t, i18n } = useTranslation();
	const { pathname } = useLocation();
	const url = document.URL.split('/');

	const changeLanguageHandler = (lgn) => {
		dispatch(cleanAllFilters());
		i18n.changeLanguage(lgn);
	};
	return (
		<div className='fixed bg-white w-full h-screen z-20 top-0 left-0 p-4 md:p-8'>
			<Cross onClick={() => setShowMenu(false)} className='mt-8 mb-16' />
			<div className='w-36 h-56 flex flex-col justify-between'>
				<LinkText
					path='/'
					action={() => {
						dispatch(changeSection('homeimages'));
						if (url[url.length - 1] === '') window.location.reload();
					}}
				>
					<B1Text
						style={{ color: ` ${pathname === '/' && 'blue'}` }}
						design='font-normal cursor-pointer'
					>
						{t('NavBar.inicio')}
					</B1Text>
				</LinkText>
				<LinkText path='/about'>
					<B1Text
						style={{ color: ` ${pathname === '/about' && 'blue'}` }}
						design='font-normal cursor-pointer'
					>
						{t('NavBar.acercaDe')}
					</B1Text>
				</LinkText>
				<LinkText path='/my-collection'>
					<B1Text
						style={{ color: ` ${pathname === '/my-collection' && 'blue'}` }}
						design='font-normal cursor-pointer '
					>
						{t('NavBar.miColeccion')}
					</B1Text>
				</LinkText>
				<LinkText path='/send-photo'>
					<B1Text
						style={{ color: ` ${pathname === '/send-photo' && 'blue'}` }}
						design='font-normal cursor-pointer'
					>
						{t('NavBar.enviar')}
					</B1Text>
				</LinkText>
				<div
					onClick={() =>
						changeLanguageHandler(i18n.language === 'es' ? 'en' : 'es')
					}
				>
					{' '}
					<B1Text design='font-normal cursor-pointer'>
						{i18n.language === 'es' ? 'English' : 'Español'}
					</B1Text>{' '}
				</div>
			</div>
		</div>
	);
}
