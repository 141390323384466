/*export const MenuAdminData = [
    {
        'label':'Inicio',
        'url':"/admin"
    },
    {
        'label':'Fotos en borrador',
        'url':"/admin/published/edit"
    },
    {
        'label':'Fotos propuestas',
        'url':"/admin"
    },
    {
        'label':'Perfil',
        'url':"/admin/profile/edit"
    },
    {
        'label':'Configuración',
        'url':"/admin/configuration"
    },
    {
        'label':'Cerrar sesión',
        'url':"/admin"
    },
]
*/

export const MenuAdminData = [
	{
		label: 'Fotos publicadas',
		url: '/admin/published',
	},
	{
		label: 'Fotos propuestas',
		url: '/admin/proposed',
	},

	{
		label: 'Fotos en borrador',
		url: '/admin/draft',
	},

	{
		label: 'Perfil',
		url: '/admin/profile',
	},
	{
		label: 'Categorías',
		url: '/admin/categories',
	},
];
