import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

// BL
import {
	clearCounter,
	clearImagesList,
} from '../../../business_logic/redux/reducers/collection';
// UI
import H1Principal from '../../atoms/text/H1Principal';
import { ReactComponent as AddCollection } from '../../assets/icon/AddCollection.svg';
import { ReactComponent as AddImage } from '../../assets/icon/AddImage.svg';
import { ReactComponent as EditImage } from '../../assets/icon/Edit.svg';
import { ReactComponent as LeftArrowSmall } from '../../assets/icon/LeftArrowSmall.svg';
import { cleanAllFilters } from '../../../business_logic/redux/reducers/filter';

export default function NavbarSecondary({
	showLeftArrow = true,
	navBarTitle,
	addImageIcon = true,
	redirectIcon,
	editImageIcon = false,
	section,
}) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<div
			className={
				section === 'selectionImages'
					? 'w-full flex flex-row justify-between p-4 fixed z-[6] bg-white'
					: 'w-full flex flex-row justify-between p-4'
			}
		>
			{showLeftArrow ? (
				<LeftArrowSmall
					className='cursor-pointer'
					onClick={() => {
						if (
							section === 'collection' ||
							section === 'about' ||
							section === 'legal'
						)
							navigate('/');
						else if (section === 'singleCollection') navigate('/my-collection');
						else navigate(-1);
					}}
				/>
			) : (
				<div className='h-4 w-7'></div>
			)}
			<H1Principal design='self-center'>{navBarTitle}</H1Principal>
			{addImageIcon && (
				<Link
					to={redirectIcon}
					className='tooltipParent relative'
					onClick={() => dispatch(cleanAllFilters())}
				>
					{pathname === '/new-collection/edit' ? (
						<>
							<AddImage />
							<p
								className='tooltipChildren absolute z-10 invisible text-azul-50 bg-white p-1 px-2 drop-shadow-md w-max rounded-md
								top-0 right-[120%]'
							>
								{t('NuevaColeccionOtro.agregarImágenes')}
							</p>
						</>
					) : (
						<>
							<AddCollection
								onClick={() => {
									dispatch(clearCounter(0));
									dispatch(clearImagesList());
								}}
							/>
							<p
								className='tooltipChildren absolute z-10 invisible text-azul-50 bg-white p-1 px-2 drop-shadow-md w-max rounded-md
								top-0 right-[120%]'
							>
								{t('NuevaColeccionOtro.crearColección')}
							</p>
						</>
					)}
				</Link>
			)}
			{editImageIcon && (
				<Link to={redirectIcon}>
					<EditImage />
				</Link>
			)}
			{!addImageIcon && !editImageIcon && <div className='h-4 w-7'></div>}
		</div>
	);
}
