// UI
import ImageDescription from '../../molecules/Image/ImageDescription';

export default function GalleryCollection({
	imageCollection,
	section,
	redirect,
	newCollectionState,
	setShowPopUpEdit,
	showDescription,
}) {
	return (
		<div
			className={`w-full px-4 grid grid-cols-1 gap-1 md:w-full md:grid-cols-5 lg:grid-cols-6
			 md:gap-3 md:px-8`}
			data-cy='collection-grid'
		>
			{imageCollection.map(({ main_image, name, id }) => (
				<ImageDescription
					key={id}
					id={id}
					imagen={main_image}
					name={name}
					section={section}
					redirect={redirect}
					newCollectionState={newCollectionState}
					setShowPopUpEdit={setShowPopUpEdit}
					showDescription={showDescription}
				/>
			))}
		</div>
	);
}
