import { Field } from 'formik';
import B2LightText from '../../atoms/text/B2LightText';

export default function InputBoxCheckbox({ label, dbName, design }) {
	return (
		<div className={`flex flex-row  ${design}`}>
			<B2LightText design={'my-auto w-full'}>{label}</B2LightText>
			<Field
				type='checkbox'
				name={dbName}
				className={`h-6 w-6 B1Text rounded bg-white border border-azul-50 active:bg-white focus:border-azul-50 pl-1 self-center `}
			/>
		</div>
	);
}
